export const PrimeXMServicesConstants = [
  { colorName: '--color-surface-100', color: '246 246 249' },
  { colorName: '--color-brand-250', color: '178 215 241' },
  { colorName: '--color-brand-300', color: '125 190 235' },
  { colorName: '--color-brand-500', color: '1 95 144' },
  { colorName: '--color-brand-550', color: '27 73 101' },
  { colorName: '--color-accent-500', color: '247 237 237' },
  { colorName: '--color-accent-550', color: '237 247 255' },
  { colorName: '--color-accent-800', color: '250 243 243' },
  { colorName: '--color-accent-850', color: '230 230 230' },
];
