import clsx from 'clsx';
import React from 'react';

import { RichText } from '../RichText';

export type TFaqProps = {
  faqTitle?: string;
  isOpenInNewTab?: boolean;
  items?: {
    title: string;
    _description?: {
      [k: string]: unknown;
    }[];
  }[];
};

export const Faq: React.FC<TFaqProps> = props => {
  const { faqTitle, items, isOpenInNewTab } = props;

  return (
    <div className='mt-10'>
      {faqTitle && (
        <h3 className='text-xl font-semibold leading-normal text-brand-500'>
          {faqTitle}
        </h3>
      )}
      <div className='mb-2'>
        {items &&
          items?.map((item, i) => (
            <dl key={i} className='mt-5'>
              <dt>
                <h4 className='mb-2 text-base font-semibold leading-normal text-interface-1000'>
                  {item.title}
                </h4>
              </dt>
              <dd
                className={clsx(
                  'text-base font-normal leading-extra-loose text-interface-800',
                  'prose-h3:text-base prose-h3:font-semibold prose-h3:text-interface-1000',
                  'prose-ul:ps-[1.5rem] before:prose-li:size-[0.45rem]',
                  'prose-ul:grid prose-ul:max-w-lg prose-ul:gap-2 prose-ul:py-2 prose-ul:ps-5 first:prose-ul:pt-0 last:prose-ul:pb-0',
                  'prose-ol:grid prose-ol:max-w-lg prose-ol:list-decimal prose-ol:gap-2 prose-ol:py-2 prose-ol:ps-5 first:prose-ol:pt-0 last:prose-ol:pb-0',
                )}
              >
                <RichText
                  isOpenInNewTab={isOpenInNewTab}
                  content={item._description}
                />
              </dd>
            </dl>
          ))}
      </div>
    </div>
  );
};
