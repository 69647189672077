import { useMemo, JSX } from 'react';

import { countryCodesWithEmoji } from './contants';
import { useEmojiSupported } from './useEmojiSupported';
import { COUNTRY_CODES } from '../../../constants/countryCodes.constants';
import { ESelectVariant, Option } from '../Select';

export const usePhoneCodesOptions = (): {
  Options: JSX.Element[];
  valueOptions: Array<{ label: string; value: string }>;
} => {
  const isEmojiSupported = useEmojiSupported();

  const Options = useMemo(
    () =>
      countryCodesWithEmoji.map((c, i) => {
        return (
          <Option
            key={i}
            label={c.dialCode}
            variant={ESelectVariant.PhoneInput}
          >
            <div className='flex w-16 justify-center px-3 tablet:w-18 tablet:px-4 desktop:w-[5.5rem] desktop:px-6'>
              {isEmojiSupported ? (
                <span className='text-3xl tablet:text-4xl'>{c.emoji}</span>
              ) : (
                <svg
                  height='14'
                  width='24'
                  className='object-contain object-center'
                >
                  <use
                    xlinkHref={`/icons/flags/flags-sprite.svg#${c.iso2}`}
                  ></use>
                </svg>
              )}
            </div>
            <span className='whitespace-nowrap text-lg leading-loose text-interface-1000 tablet:text-xl desktop:text-3xl'>
              +{c.dialCode}
            </span>
          </Option>
        );
      }),
    [isEmojiSupported],
  );

  const valueOptions = useMemo(
    () =>
      COUNTRY_CODES.map(c => ({ label: `+${c.dialCode}`, value: c.dialCode })),
    [],
  );

  return { Options, valueOptions };
};
