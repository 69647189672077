import { consoleLogAppVersion } from '@front/shared/utils';
import React from 'react';
import { Provider } from 'react-redux';
import 'swiper/css/bundle';

import { App, TAppProps } from './App';
import wrapper from '../../../store';
import { isMobile } from '../../../utils';

consoleLogAppVersion();

export type TBaseAppProps = TAppProps;

export const BaseApp: React.FC<TBaseAppProps> = ({
  Component,
  className,
  ...rest
}) => {
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <App Component={Component} className={className} {...props} />
    </Provider>
  );
};

export function reportWebVitals(metric: { [key: string]: unknown }): void {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const body = JSON.stringify({
    ...metric,
    pathname: location.pathname,
    isMobile: isMobile(),
  });
  const url = `${window.location.origin}/api/register-webvitals-metrics`;

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true }).catch(console.error);
  }
}
