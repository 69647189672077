import React from 'react';

import { Container } from '../../sections/Container';

export type TStatsProps = {
  stats: { label: string; value: string }[];
};

const Stats: React.FC<TStatsProps> = props => {
  const { stats } = props;

  const fontSize = 17 / stats.length;

  return (
    <Container>
      <div
        className='flex flex-col gap-x-2 sm:gap-8 md:grid xl:px-8'
        style={{
          gridTemplateColumns: `repeat(${stats.length}, 1fr)`,
        }}
      >
        {stats.map((stat, index) => (
          <div className='md:min-h-[6.25rem]' key={index}>
            <p className='flex flex-col items-center gap-4 font-semibold leading-snug text-interface-1000 lg:gap-4 lg:leading-tight'>
              <span className='sm:block sm:text-7xl md:hidden'>
                {stat.value}
              </span>
              <span
                className='sm:hidden md:block'
                style={{
                  fontSize: `min(${fontSize}vw, 3.75rem)`,
                }}
              >
                {stat.value}
              </span>
              <span className='font-medium leading-relaxed text-interface-800 sm:block sm:text-xs md:hidden'>
                {stat.label}
              </span>
              <span
                className='font-medium leading-relaxed text-interface-800 sm:hidden md:block'
                style={{
                  fontSize: `min(${fontSize / 3}vw, 1.125rem)`,
                }}
              >
                {stat.label}
              </span>
            </p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Stats;
