'use client';

function getCDNHost() {
  if (typeof window !== 'undefined') {
    return window.CDN_BASE_URL;
  }
  return process.env.CDN_BASE_URL;
}

function isCropEnabled() {
  const normalizeValue = value => {
    return +value === 1 || value?.toLowerCase() === 'true';
  };

  if (typeof window !== 'undefined') {
    return normalizeValue(window.NEXT_IMAGE_CROP_ENABLED);
  }
  return normalizeValue(process.env.NEXT_IMAGE_CROP_ENABLED);
}

function hasValidProtocol(urlString) {
  const protocolPattern = new RegExp('^(https?:\\/\\/)', 'i');
  return protocolPattern.test(urlString);
}

function modifyPath(originalPath, width, quality) {
  const parts = originalPath.split('.');
  if (parts.length < 2) {
    return '';
  }

  const extension = parts.pop();

  // For svg images NEXT.JS send full path with protocol
  if (hasValidProtocol(originalPath)) {
    return new URL(originalPath).pathname;
  }

  if (!isCropEnabled()) {
    return originalPath;
  }

  return `${parts.join('.')}__${width}__${quality}.${extension}`;
}

export default function myImageLoader({ src, width, quality = 75 }) {
  const imageCNDHost = getCDNHost();

  if (!imageCNDHost || process.env.NODE_ENV !== 'production') {
    // Default behavior
    return `${src}?w=${width}&q=${quality}`;
  }

  return `${imageCNDHost}${modifyPath(src, width, quality)}`;
}
