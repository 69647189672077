import { cva, cx } from 'cva';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React, { DetailedHTMLProps, FunctionComponent, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { EButtonSize, EButtonTheme, EButtonVariant } from './Button.constants';

type TButtonCommonProps = {
  /**
   * Content to render inside button tag.
   */
  children?: ReactNode;
  /**
   * Click callback.
   */
  onClick?:
    | React.MouseEventHandler<HTMLAnchorElement>
    | ((e: React.MouseEvent<HTMLButtonElement>) => void);
  /**
   * Button type.
   */
  variant?: EButtonVariant;
  /**
   * Regulates button size using paddings.
   */
  size?: EButtonSize;
  /**
   * Color Theme.
   */
  theme?: EButtonTheme;
  /**
   * Adding rounding.
   */
  rounded?: boolean;
  disabled?: boolean;
  leadingIcon?: ImageProps;
  trailingIcon?: ImageProps;
  'data-ga-id'?: string;
  trailingIconComponent?: FunctionComponent;
};
export type TButtonProps = (
  | DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  | React.ComponentProps<typeof Link>
) &
  TButtonCommonProps;
export const buttonCVA = cva(
  'box-border flex items-center justify-center text-center text-base font-medium leading-tight outline-none transition',
  {
    variants: {
      variant: {
        [EButtonVariant.Contained]: '',
        [EButtonVariant.Outlined]: '',
        [EButtonVariant.Text]: '',
        [EButtonVariant.Image]: '',
        [EButtonVariant.OutlineRounded]: '',
        [EButtonVariant.ContainedRounded]: '',
      },
      theme: {
        [EButtonTheme.Primary]: '',
        [EButtonTheme.PrimaryOnColour]: '',
        [EButtonTheme.Secondary]: '',
        [EButtonTheme.SecondaryOnColour]: '',
        [EButtonTheme.Tertiary]: '',
        [EButtonTheme.Control]: '',
      },
      size: {
        [EButtonSize.Small]: 'h-10 min-w-10 gap-2 px-4 py-2.5',
        [EButtonSize.Medium]: 'h-14 min-w-14 gap-2.5 px-6 py-4',
        [EButtonSize.Large]: 'h-16 min-w-[6.5rem] gap-2.5 px-10 py-5',
        [EButtonSize.ExtraLarge]: '',
        [EButtonSize.Float]:
          'gap-2 p-5 text-base 2xl:px-4 2xl:py-2.5 2xl:text-sm',
      },
      rounded: {
        true: 'rounded-xl',
      },
      disabled: {
        true: 'pointer-events-none cursor-not-allowed opacity-40',
      },
    },
    compoundVariants: [
      {
        rounded: true,
        size: EButtonSize.Small,
        className: 'rounded-xl',
      },
      {
        variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
        theme: EButtonTheme.Primary,
        className: 'bg-brand-500 text-brand-50/90 hover:opacity-80',
      },
      {
        variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
        theme: EButtonTheme.Primary,
        className: 'border border-brand-500 text-brand-500 hover:opacity-80',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.Primary,
        className: 'text-brand-500 hover:opacity-70',
      },
      {
        variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
        theme: EButtonTheme.Secondary,
        className: 'bg-control-100 text-control-950 hover:opacity-80',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.Secondary,
        className: 'text-control-950 hover:opacity-70',
      },
      {
        variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
        theme: EButtonTheme.Secondary,
        className:
          'border border-control-950 text-control-950 hover:opacity-80',
      },
      {
        variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
        theme: EButtonTheme.Tertiary,
        className: 'bg-control-950 text-control-50/90 hover:opacity-80',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.Tertiary,
        className: 'text-control-600 hover:opacity-70',
      },
      {
        variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
        theme: EButtonTheme.PrimaryOnColour,
        className: 'bg-brand-1000/80 text-brand-50/90 hover:opacity-80',
      },
      {
        variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
        theme: EButtonTheme.PrimaryOnColour,
        className:
          'border border-brand-1000/80 text-brand-1000/80 hover:opacity-80',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.PrimaryOnColour,
        className: 'text-brand-1000/80 hover:opacity-70',
      },

      {
        variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
        theme: EButtonTheme.SecondaryOnColour,
        className: 'bg-brand-50/90 text-brand-1000/80 hover:opacity-80',
      },
      {
        variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
        theme: EButtonTheme.SecondaryOnColour,
        className:
          'border border-brand-50/90 text-brand-50/90 hover:opacity-80',
      },
      {
        variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
        theme: EButtonTheme.Control,
        className:
          'border border-control-600 text-control-950 hover:border-control-650 hover:text-control-1000',
      },
      {
        variant: EButtonVariant.Text,
        theme: EButtonTheme.SecondaryOnColour,
        className: 'text-brand-50/90 hover:opacity-70',
      },
      {
        variant: EButtonVariant.Image,
        className: 'p-0',
      },
      {
        variant: [EButtonVariant.Text],
        size: EButtonSize.ExtraLarge,
        className: 'h-8 min-w-4 gap-2 p-0 text-3xl font-normal leading-loose',
      },
      {
        variant: [EButtonVariant.Text],
        size: EButtonSize.Large,
        className:
          'h-4 min-w-4 gap-2 p-0 text-lg font-medium leading-extra-loose',
      },
      {
        variant: [EButtonVariant.Text],
        size: EButtonSize.Medium,
        className: 'h-6 min-w-4 gap-2 p-0 text-base font-medium leading-loose',
      },
      {
        variant: [EButtonVariant.Text],
        size: EButtonSize.Small,
        className: 'h-4 min-w-4 gap-2 p-0 text-sm font-medium leading-loose',
      },
      {
        variant: [
          EButtonVariant.ContainedRounded,
          EButtonVariant.Contained,
          EButtonVariant.Outlined,
          EButtonVariant.OutlineRounded,
          EButtonVariant.Text,
        ],
        size: EButtonSize.Large,
        className: 'text-lg font-medium leading-extra-loose',
      },
      {
        variant: [
          EButtonVariant.ContainedRounded,
          EButtonVariant.Contained,
          EButtonVariant.Outlined,
          EButtonVariant.OutlineRounded,
        ],
        size: EButtonSize.Medium,
        className: 'text-base font-medium leading-loose',
      },
      {
        variant: [
          EButtonVariant.ContainedRounded,
          EButtonVariant.Contained,
          EButtonVariant.Outlined,
          EButtonVariant.OutlineRounded,
          EButtonVariant.Text,
        ],
        size: EButtonSize.Small,
        className: 'text-sm font-medium leading-loose',
      },
      {
        variant: [
          EButtonVariant.ContainedRounded,
          EButtonVariant.OutlineRounded,
        ],
        size: EButtonSize.Large,
        className: 'rounded-4xl',
      },
      {
        variant: [
          EButtonVariant.ContainedRounded,
          EButtonVariant.OutlineRounded,
        ],
        size: EButtonSize.Medium,
        className: 'rounded-4xl',
      },
      {
        variant: [
          EButtonVariant.ContainedRounded,
          EButtonVariant.OutlineRounded,
        ],
        size: EButtonSize.Small,
        className: 'rounded-2xl',
      },
      {
        variant: [EButtonVariant.Contained, EButtonVariant.Outlined],
        size: [EButtonSize.Large, EButtonSize.Medium],
        className: 'rounded-2xl',
      },
      {
        variant: [EButtonVariant.Contained, EButtonVariant.Outlined],
        size: EButtonSize.Small,
        className: 'rounded-xl',
      },
    ],
    defaultVariants: {
      theme: EButtonTheme.Primary,
      size: EButtonSize.Medium,
    },
  },
);

export const buttonIconCVA = cva('aspect-square rtl:-scale-x-100', {
  variants: {
    size: {
      [EButtonSize.Small]: 'size-4',
      [EButtonSize.Medium]: '',
      [EButtonSize.Large]: '',
      [EButtonSize.ExtraLarge]: '',
      [EButtonSize.Float]: '',
    },
    variant: {
      [EButtonVariant.Contained]: '',
      [EButtonVariant.Outlined]: '',
      [EButtonVariant.Text]: '',
      [EButtonVariant.Image]: '',
      [EButtonVariant.OutlineRounded]: '',
      [EButtonVariant.ContainedRounded]: '',
    },
  },
  compoundVariants: [
    {
      size: EButtonSize.Large,
      variant: [
        EButtonVariant.Contained,
        EButtonVariant.ContainedRounded,
        EButtonVariant.Outlined,
        EButtonVariant.OutlineRounded,
      ],
      className: 'size-6',
    },
    {
      size: EButtonSize.Medium,
      variant: [
        EButtonVariant.Contained,
        EButtonVariant.ContainedRounded,
        EButtonVariant.Outlined,
        EButtonVariant.OutlineRounded,
      ],
      className: 'size-5',
    },
    {
      size: [EButtonSize.Medium, EButtonSize.Large],
      variant: EButtonVariant.Text,
      className: 'size-5',
    },
    {
      size: [EButtonSize.ExtraLarge],
      variant: EButtonVariant.Text,
      className: 'mt-0.5 size-4',
    },
  ],
  defaultVariants: {
    size: EButtonSize.Medium,
  },
});

const Button: React.FC<TButtonProps> = props => {
  const {
    children,
    size = EButtonSize.Medium,
    variant = EButtonVariant.Contained,
    theme = EButtonTheme.Primary,
    rounded = true,
    leadingIcon,
    trailingIcon,
    className = '',
    trailingIconComponent: TrailingIconComponent,
    ...rest
  } = props;
  const { disabled } = rest;
  const contentButton = (
    <>
      {leadingIcon && (
        <Image
          {...leadingIcon}
          className={cx(buttonIconCVA({ size, variant }))}
          aria-hidden={true}
        />
      )}
      {children}
      {TrailingIconComponent && <TrailingIconComponent />}
      {trailingIcon && (
        <Image
          {...trailingIcon}
          className={cx(buttonIconCVA({ size, variant }))}
          aria-hidden={true}
        />
      )}
    </>
  );
  const buttonClassname = twMerge(
    cx(buttonCVA({ variant, theme, rounded, disabled, size }), className),
  );
  return 'href' in rest ? (
    <Link {...rest} className={buttonClassname} replace>
      {contentButton}
    </Link>
  ) : (
    <button className={buttonClassname} {...rest}>
      {contentButton}
    </button>
  );
};

export default Button;
