import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { titlePartsCVA } from '../../TitlePart/TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../../TitlePart/TitlePart.constants';

type TContentSectionTitlePartsProps = {
  children: ReactNode;
  size: ETitlePartsSize;
  position?: ETitlePartsPosition;
  theme?: ETitlePartsTheme;
};

export const ContentSectionTitle: React.FC<
  TContentSectionTitlePartsProps
> = props => {
  const {
    children,
    position = ETitlePartsPosition.Left,
    size,
    theme = ETitlePartsTheme.Dark,
  } = props;

  return (
    <h2
      className={twMerge(
        titlePartsCVA({ position, size, theme }),
        'prose-a:inline-block',
      )}
    >
      {children}
    </h2>
  );
};
