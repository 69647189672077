import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from '@shared/master-types';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    currentModalId: null,
  } as {
    currentModalId: string | null;
  },
  reducers: {
    setCurrentModalId: (
      state,
      action: PayloadAction<{ modalId: Modal['id'] | null }>,
    ) => {
      state.currentModalId = action.payload.modalId;
    },
  },
});

const { actions, reducer } = modalsSlice;

export const { setCurrentModalId } = actions;

export default reducer;
