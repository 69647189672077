import React, { useState } from 'react';

import { ReactComponent as IconVideoPlay } from '../../../../public/icons/video-play.svg';

export type TVideoPlayerProps = {
  embedId: string;
};

const VideoPlayer: React.FC<TVideoPlayerProps> = props => {
  const { embedId } = props;

  const [playing, setPlaying] = useState(false);

  const handlePlayClick = (): void => {
    setPlaying(true);
  };

  return (
    <div className='video-container relative aspect-video overflow-hidden rounded-3xl'>
      {!playing ? (
        <div
          className='video-preview absolute left-0 top-0 z-20 flex size-full cursor-pointer items-center justify-center'
          onClick={handlePlayClick}
        >
          <div className='absolute z-20 flex w-full max-w-[3.125rem] opacity-70 transition hover:opacity-100 lg:max-w-[4.688rem] 2xl:max-w-[6.875rem]'>
            <IconVideoPlay />
          </div>
          <img
            src={`https://img.youtube.com/vi/${embedId}/maxresdefault.jpg`}
            alt='Video Preview'
            className='!m-0 size-full object-cover'
          />
          <div className='absolute z-10 size-full bg-black opacity-20'></div>
        </div>
      ) : null}
      {playing && (
        <iframe
          className='absolute left-0 top-0 z-10 size-full'
          width='100%'
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen={true}
        ></iframe>
      )}
    </div>
  );
};

export default VideoPlayer;
