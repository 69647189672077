import Head from 'next/head';
import React, { FC } from 'react';

import { themeColorsMap } from './Theme.constants';

import type { Page } from '@shared/master-types';

export type TThemeProps = { pageTheme: Required<Page>['parts']['pageTheme'] };

const Theme: FC<TThemeProps> = props => {
  const { pageTheme } = props;

  if (!pageTheme) {
    return null;
  }

  const selectedColors = themeColorsMap[pageTheme];

  const colorsStyle = `#_app, div[data-floating-ui-portal] {
    ${selectedColors
      ?.map(({ colorName, color }) => `${colorName}: ${color};`)
      ?.join('\n')}
  }`;

  return (
    <Head>
      <style>{colorsStyle}</style>
    </Head>
  );
};

export default Theme;
