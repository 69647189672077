import { Header } from '@shared/master-types';

import { useGetHeaderQuery } from '../features/layout/index';

const useActiveHeader = ({
  locale,
  jurisdictionHeader,
  headerDepth,
}: {
  locale: string;
  jurisdictionHeader?: Header | string;
  headerDepth?: number;
}): Header | undefined | string => {
  const { data: headerDefaultObject } = useGetHeaderQuery({
    locale,
    depth: headerDepth,
  });

  if (jurisdictionHeader) {
    return jurisdictionHeader;
  }

  const headerDocs = headerDefaultObject?.docs || [];
  const activeHeader = headerDocs.find(header => header.isActive);

  return activeHeader ?? headerDocs[headerDocs.length - 1];
};

export default useActiveHeader;
