import React from 'react';

import { publicRuntimeConfig } from './config';

export const RuntimeConfigScript: React.FC = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: Object.entries(publicRuntimeConfig).reduce(
        (acc, [key, value]) => acc + `window.${key} = '${value}';\n`,
        '',
      ),
    }}
  />
);
