import {
  ETabSize,
  ETabTheme,
  IconPencil,
  Tab,
  TagInput,
} from '@front/shared/ds';
import { Form } from '@shared/master-types';
import { useCallback, useRef, useState } from 'react';

import {
  getInputValue,
  getMultiselectInputValue,
  getMultiselectValue,
  getSelectValue,
} from './utils/selects';

export type TTagInputWithOptionsProps = {
  theme: ETabTheme;
  field: Extract<
    Required<Form>['fields'][number],
    {
      blockType: 'select';
    }
  >;
  value?: string[];
  error?: string;
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
};

export const TagInputWithOptions: React.FC<TTagInputWithOptionsProps> = ({
  field,
  error,
  onChange,
  onBlur,
  value: initialValue,
}) => {
  const [selected, setSelected] = useState<string[]>(initialValue || []);
  const tagInputRef = useRef<HTMLInputElement>(null);

  const handleTagClick = useCallback(
    (value: string): void => {
      // unselect input
      if (tagInputRef.current && !field.isMultiple) {
        tagInputRef.current.value = '';
      }

      const func = field.isMultiple ? getMultiselectValue : getSelectValue;
      const nextValue = func(selected, value, true);
      setSelected(nextValue);
      onChange?.(nextValue);
      onBlur?.(); // trigger validation
    },
    [field.isMultiple, selected, onChange, onBlur],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (!field.options) {
        return;
      }

      const value = e.target.value;
      const nextValue = field.isMultiple
        ? getMultiselectInputValue(field.options, selected, value)
        : [value];

      setSelected(nextValue);
      onChange?.(nextValue);
    },
    [field.isMultiple, field.options, selected, onChange],
  );

  const inputValue = getInputValue(field.options || [], selected);

  return (
    <div className='flex flex-col items-start gap-4'>
      {field.label && (
        <label className='text-center text-lg font-semibold not-italic leading-relaxed'>
          {field.label}
        </label>
      )}
      <div className='flex max-w-full flex-wrap gap-2'>
        {field.options?.map(option => (
          <Tab
            key={option.value}
            value={option.value}
            label={option.label}
            isActive={selected.includes(option.value)}
            onClick={handleTagClick}
            theme={ETabTheme.Contained}
            size={ETabSize.Small}
          />
        ))}

        {field.hasInput && (
          <TagInput
            ref={tagInputRef}
            onChange={handleInputChange}
            onBlur={onBlur}
            value={inputValue}
            dynamicWidth
            placeholder={field.placeholder}
            IconComponent={IconPencil}
          />
        )}
      </div>
      {error && (
        <label className='px-4 text-xs leading-loose text-error'>{error}</label>
      )}
    </div>
  );
};
