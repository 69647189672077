import clsx from 'clsx';
import React, { ReactNode } from 'react';

export enum EFormSlotBackground {
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
}

export interface IFormSlotProps {
  title: string;
  children: ReactNode;
  description?: React.JSX.Element | null;
  background?: EFormSlotBackground;
}

const FormSlot: React.FC<IFormSlotProps> = props => {
  const {
    description,
    children,
    title,
    background = EFormSlotBackground.Dark,
  } = props;

  return (
    <div
      className={clsx(
        'grid gap-6 rounded-3xl px-4 py-12 lg:px-5 2xl:grid-cols-12 2xl:justify-between 2xl:p-12',
        background === EFormSlotBackground.Light && 'bg-surface-100',
        background === EFormSlotBackground.White && 'bg-surface-50',
        background === EFormSlotBackground.Dark && 'bg-surface-1000',
      )}
    >
      <div className='grid auto-rows-max gap-3 text-center lg:gap-4 2xl:col-span-4 2xl:gap-4 2xl:text-left'>
        <h3
          className={clsx(
            'text-4xl font-semibold leading-relaxed lg:text-7xl lg:leading-snug',
            background === EFormSlotBackground.Light && 'text-interface-1000',

            background === EFormSlotBackground.White && 'text-interface-1000',
            background === EFormSlotBackground.Dark && 'text-interface-50',
          )}
        >
          {title}
        </h3>
        <div
          className={clsx(
            'text-sm leading-loose lg:text-base lg:leading-extra-loose',
            background === EFormSlotBackground.Light && 'text-interface-800',
            background === EFormSlotBackground.White && 'text-interface-800',
            background === EFormSlotBackground.Dark &&
              'text-interface-50 text-opacity-70',
          )}
        >
          {description}
        </div>
      </div>
      <div className='2xl:col-span-7 2xl:col-start-6 2xl:me-0 2xl:ms-auto 2xl:w-full 2xl:max-w-[45.75rem]'>
        {children}
      </div>
    </div>
  );
};

export default FormSlot;
