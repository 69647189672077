import { setCurrentModalId } from '@front/master/features/modals/modals.state';
import { TAppDispatch } from '@front/master/store/store.types';

export const handleOpenModal = (dispatch: TAppDispatch) => {
  return (modalId: string): void => {
    dispatch(
      setCurrentModalId({
        modalId,
      }),
    );
  };
};
