export const enum ECardWrapperLayout {
  DefaultCard = 'DefaultCard',
  DefaultCardWithBg = 'DefaultCardWithBg',
  DefaultCardWithBrandBg = 'DefaultCardWithBrandBg',
  BannerCard = 'BannerCard',
  BannerCardWithBg = 'BannerCardWithBg',
  HorizontalCard = 'HorizontalCard',
  HorizontalCardWithBg = 'HorizontalCardWithBg',
  BrandCard = 'BrandCard',
}

export const enum ECardWrapperBackground {
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
  Brand = 'Brand',
  Theme5 = 'Theme5',
}
