import { useState, useEffect } from 'react';

export const useFixedActionVisibility = (
  distanceToThreshold = 450,
): boolean => {
  const [isFixedActionVisible, setIsFixedActionVisible] = useState(false);

  useEffect(() => {
    const checkVisibility = (): void => {
      const footer = document.querySelector('footer');
      const isScrolledDown = window.scrollY >= distanceToThreshold;
      const isAtTop = window.scrollY === 0;

      if (footer) {
        const rect = footer.getBoundingClientRect();
        setIsFixedActionVisible(
          rect.top >= window.innerHeight + distanceToThreshold &&
            isScrolledDown,
        );
      } else {
        setIsFixedActionVisible(
          !isAtTop || isScrolledDown || !distanceToThreshold,
        );
      }
    };

    window.addEventListener('scroll', checkVisibility);
    // Deferred long-running blocking task, to improve performance
    const timeout = setTimeout(checkVisibility, 2000);
    return () => {
      window.removeEventListener('scroll', checkVisibility);
      clearTimeout(timeout);
    };
  }, []);

  return isFixedActionVisible;
};
