import { EventContent } from '@front/shared/ds';
import React, { useMemo } from 'react';

export type TEventContentConnectedProps = {
  // We can't know content type beforehand because it comes from third party library.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content?: any;
};

const EventContentConnected: React.FC<TEventContentConnectedProps> = props => {
  const { content } = props;

  const items = useMemo<
    React.ComponentProps<typeof EventContent>['items']
  >(() => {
    return content
      .map(
        (node: { type: string; children: { text: string }[] }, i: number) => {
          switch (node.type) {
            case 'h2':
              return { id: i, title: node.children[0].text };
            default:
              return null;
          }
        },
      )
      .filter(Boolean);
  }, [content]);

  return <EventContent items={items} />;
};

export default EventContentConnected;
