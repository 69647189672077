type TWidthHeight = number | string | undefined;

export default function imgPreloader(
  width: TWidthHeight = 200,
  height: TWidthHeight = 100,
  isDark: boolean,
): string {
  const mainColor = isDark ? '#1A1A1D' : '#F6F6F9';
  const middleColor = isDark ? '#121212' : '#FDFDFD';
  const shimmer = (w: TWidthHeight, h: TWidthHeight): string => `
        <svg class="w-full h-full" width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient id="g">
                <stop stop-color="${mainColor}" offset="20%" />
                    <stop stop-color="${middleColor}" offset="50%" />
                    <stop stop-color="${mainColor}" offset="70%" />
                </linearGradient>
            </defs>
            <rect width="${w}" height="${h}" fill="${mainColor}" />
            <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
            <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
        </svg>
    `;

  const processedWidth = width === undefined ? 200 : width;
  const processedHeight = height === undefined ? 100 : height;
  const toBase64 = (str: string): string =>
    typeof window === 'undefined'
      ? Buffer.from(str).toString('base64')
      : window.btoa(str);

  return `svg+xml;base64,${toBase64(shimmer(processedWidth, processedHeight))}`;
}
