import { getMediaSrc, toLinkParam } from '@front/shared/ds';
import { Locale, Media, Meta as IMeta } from '@shared/master-types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { ApplicationSchema } from './components/ApplicationSchema';
import { getAppHost } from '../../../utils/getAppHost';

import type { Meta as PayloadMeta } from '@payloadcms/plugin-seo/types';

export type TMetaProps = Omit<PayloadMeta, 'image'> & {
  image?: string | Media;
  isPageCanonical?: boolean;
  url?: string;
  locales?: Locale[];
} & Omit<IMeta, 'id'>;

const Meta: React.FC<TMetaProps> = props => {
  const {
    description,
    favicon,
    image,
    title,
    isPageCanonical,
    url,
    isNoindex = false,
    locales,
  } = props;
  const router = useRouter();
  const { query } = router;
  const appHost = getAppHost();
  const jurisdiction =
    query?.jurisdiction?.toString() === 'default' ? 'default' : 'mu';
  const linkParam = toLinkParam({
    locale: query?.locale?.toString(),
    jurisdiction,
  });

  const canonical =
    !isPageCanonical &&
    url &&
    appHost &&
    `${appHost}${linkParam && '/'}${linkParam}${url}`;
  const currentPath = url || '';

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title} key='title' />
        <meta property='og:description' content={description} />
        {favicon?.ico && (
          <link rel='icon' href={getMediaSrc(favicon.ico)} sizes='any' />
        )}
        {favicon?.svg && (
          <link
            rel='icon'
            href={getMediaSrc(favicon.svg)}
            type='image/svg+xml'
          />
        )}
        {typeof image === 'string' && (
          <meta property='og:image' content={getMediaSrc(image)} />
        )}
        {typeof image !== 'string' && image && (
          <>
            <meta property='og:image' content={getMediaSrc(image.url)} />
            <meta property='og:image:alt' content={image.alt} />
          </>
        )}
        {canonical && <link rel='canonical' href={canonical} />}
        {isNoindex && <meta name='robots' content='noindex, nofollow' />}
        <link
          rel='alternate'
          hrefLang='x-default'
          href={`${appHost}${currentPath}`}
        />
        {locales?.map(({ locale }) => (
          <link
            key={locale}
            rel='alternate'
            hrefLang={locale}
            href={`${appHost}${locale === 'en' ? currentPath : `/${locale}${currentPath}`}`}
          />
        ))}
      </Head>
      <ApplicationSchema {...props} appHost={appHost} linkParam={linkParam} />
    </>
  );
};

export default Meta;
