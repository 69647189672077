import clsx from 'clsx';
import { cva } from 'cva';
import Link from 'next/link';
import React from 'react';

import { ELibraryCardType } from './LibraryCard.constants';
import Button from '../Button/Button';
import {
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../Button/Button.constants';
import { TNewsCardProps } from '../NewsCard';

export const LibraryCard: React.FC<TNewsCardProps> = props => {
  const { title, description, link, tags, libraryType, className } = props;

  const renderLink = link.href;
  const tag = (tags && tags[0]) || undefined;

  if (!renderLink) {
    return null;
  }

  const cardStyles = cva(
    'relative flex min-h-[296px] min-w-[236px] max-w-[488px] shrink grow basis-[236px] flex-col justify-between rounded-3xl tablet:max-desktop:max-w-[calc(50%-8px)]',
    {
      variants: {
        libraryType: {
          [ELibraryCardType.Basic]: 'bg-[#F1FAF3]',
          [ELibraryCardType.Advanced]: 'bg-[#FFFAEA]',
          [ELibraryCardType.Professional]: 'bg-[#FFF6F6]',
        },
      },
    },
  );

  return (
    <div className={clsx(cardStyles({ libraryType }), className)}>
      <Link {...link} className='absolute start-0 top-0 h-full p-6 tablet:p-8'>
        <div className='block transition-opacity hover:opacity-80'>
          <h3 className='text-lg font-semibold leading-extra-loose text-interface-1000'>
            {title}
          </h3>
          {description ? (
            <p className='mt-3 line-clamp-3 pe-2 text-base font-normal leading-mega-loose text-interface-800'>
              {description}
            </p>
          ) : null}
        </div>
      </Link>
      {tag && tag.href && (
        <div className='absolute bottom-6 flex px-6 pt-2'>
          <Button
            size={EButtonSize.Medium}
            variant={EButtonVariant.Text}
            theme={EButtonTheme.Tertiary}
            {...tag}
            className='text-control-600 hover:text-control-650 hover:opacity-100'
          />
        </div>
      )}
    </div>
  );
};
