import React, { ReactNode } from 'react';

import { ReactComponent as IconCloseThin } from '../../../../public/icons/icon-close-thin.svg';
import { Button, EButtonSize, EButtonVariant } from '../../atoms/Button';

export interface IModalProps {
  onClose: () => void;
  title?: string;
  children?: ReactNode;
  description?: React.JSX.Element | null;
}

const ModalV2: React.FC<IModalProps> = props => {
  const { title, onClose, children, description } = props;

  return (
    <div className='overflow-hidden'>
      <div className='relative inset-x-0 top-full flex max-h-full max-h-none w-full flex-col items-start rounded-3xl bg-white p-6 outline-none tablet:p-8'>
        <div className='flex w-full items-center justify-between'>
          {!!title && (
            <h2 className='text-left text-5xl font-bold leading-relaxed text-interface-1000 tablet:text-8xl'>
              {title}
            </h2>
          )}

          <Button
            onClick={onClose}
            variant={EButtonVariant.Contained}
            size={EButtonSize.Large}
            className='size-[60px] min-w-[60px] rounded-lg bg-[#F5F5F9] p-3 outline-none'
          >
            <IconCloseThin className='aspect-square size-3.5 text-[#BFBFBF]' />
          </Button>
        </div>

        {!!description && (
          <div className='max-w-[25rem] pt-2.5 text-lg font-normal leading-relaxed text-interface-1000 tablet:text-3xl tablet:leading-loose'>
            {description}
          </div>
        )}

        {React.Children.count(children) !== 0 && (
          <div className='scrollbar-hidden w-full overflow-y-auto scroll-smooth pt-8 tablet:pt-16'>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalV2;
