import { Text } from 'slate';

import { TChildren } from '../types';

export const skipEmptyNodesWithLink = (
  children?: TChildren,
  trimLastEmpty?: boolean,
): TChildren | undefined => {
  if (!children) {
    return undefined;
  }

  let result = children;

  const firstNode = children[0];
  const lastNode = children[children.length - 1];

  const hasEmptyNode = firstNode && Text.isText(firstNode) && !firstNode.text;
  const hasLink = children[1] && children[1].type === 'link';
  const hasLastEmptyNode = lastNode && Text.isText(lastNode) && !lastNode.text;

  if (hasEmptyNode && hasLink) {
    result = result.slice(1);
  }

  if (hasLastEmptyNode && trimLastEmpty) {
    result = result.slice(0, -1);
  }

  return result;
};
