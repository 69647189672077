import { checkJurisdictionExist } from '@front/shared/utils';
import { Jurisdiction } from '@shared/master-types';

export interface IGetJurisdictionPathProps {
  jurisdictionList?: Jurisdiction[];
  jurisdiction?: string;
  partOfLink: string;
}

export default function getJurisdictionPath(
  props: IGetJurisdictionPathProps,
): string | void {
  const { jurisdictionList, jurisdiction, partOfLink } = props;

  const isJurisdictionExist = checkJurisdictionExist({
    jurisdictionList,
    jurisdiction,
  });
  const isPartOfLinkExist = checkJurisdictionExist({
    jurisdictionList,
    jurisdiction: partOfLink,
  });

  if (isJurisdictionExist) {
    if (isPartOfLinkExist) {
      return jurisdiction;
    } else {
      return `${jurisdiction}/${partOfLink}`;
    }
  } else {
    return partOfLink;
  }
}
