import { IconCheck, RichText } from '@front/shared/ds';
import React, { useEffect, useState } from 'react';

export type TConfirmationMessageProps = {
  confirmationType?: 'message' | 'redirect';
  confirmationMessage: {
    [k: string]: unknown;
  }[];
  onSubmit?: () => void;
  isSubmitSuccessful: boolean;
};

const ConfirmationMessage: React.FC<TConfirmationMessageProps> = ({
  onSubmit,
  confirmationType,
  confirmationMessage,
  isSubmitSuccessful,
}) => {
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  useEffect(() => {
    if (confirmationType === 'message' && isSubmitSuccessful) {
      setShowConfirmationMessage(true);

      const timeout = setTimeout(() => {
        onSubmit?.();
        setShowConfirmationMessage(false);
      }, 2500);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [confirmationType, isSubmitSuccessful, onSubmit]);

  return (
    <>
      {confirmationType === 'message' && showConfirmationMessage && (
        <div className='fixed inset-x-0 bottom-10 mx-auto flex max-w-md animate-translate-up items-center gap-4 rounded-2xl bg-surface-50/30 px-6 py-5 text-sm leading-loose backdrop-blur-md'>
          <IconCheck className='aspect-square size-5 text-success' />
          <RichText content={confirmationMessage} />
        </div>
      )}
    </>
  );
};

export default ConfirmationMessage;
