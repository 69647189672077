import { DEFAULT_LOCALE, getLanguageDirection, LANG_MAP } from '@front/shared';
import clsx from 'clsx';
import { DocumentProps, Head, Html, Main, NextScript } from 'next/document';
import React from 'react';

import { RuntimeConfigScript } from '../../../config/RuntimeConfigScript';

function getLang(lang?: string | string[]): string {
  const resolvedLang = Array.isArray(lang) ? lang[0] : (lang ?? DEFAULT_LOCALE);
  return LANG_MAP[resolvedLang] || resolvedLang;
}

export type TBaseBaseDocument = DocumentProps & {
  className?: string;
};

export const BaseDocument: React.FC<TBaseBaseDocument> = props => {
  const { className } = props;
  const lang = getLang(props?.__NEXT_DATA__?.query?.locale);
  const normalizeClasses = 'w-full h-hull m-0 p-0';

  const direction = getLanguageDirection(lang);
  const scrollBarClasses =
    'xl:scrollbar xl:scrollbar-w-1 xl:scrollbar-thumb-interface-500 xl:scrollbar-track-control-200 xl:scroll-smooth overscroll-y-none';
  return (
    <Html
      lang={lang}
      className={clsx(className, scrollBarClasses, normalizeClasses)}
      dir={direction}
    >
      <Head />
      <body className={normalizeClasses}>
        <RuntimeConfigScript />
        <Main />
        <NextScript />
      </body>
    </Html>
  );
};
