import { checkLocaleExist, DEFAULT_LOCALE } from '@front/shared';
import { useRouter } from 'next/router';

import { useGetLocalesQuery } from '../features';

export const useLocale = (): { locale: string; isLocaleLoading: boolean } => {
  const router = useRouter();

  // router locale would be empty in case of 404 page,
  // since Next.js middleware doesn't have a page path for error pages
  let locale = String(router.query?.locale || '');

  const { data: localesObject, isLoading } = useGetLocalesQuery(undefined, {
    skip: !!locale,
  });

  if (locale) {
    return { locale, isLocaleLoading: false };
  }

  const locales = localesObject?.docs || [];
  const pathPattern = router.asPath.split('/')[1];

  const isLocale = checkLocaleExist({
    locale: pathPattern,
    localeList: locales,
  });
  locale = isLocale ? pathPattern : DEFAULT_LOCALE;

  return { locale, isLocaleLoading: isLoading };
};
