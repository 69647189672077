import { cva } from 'cva';
import { ImageProps } from 'next/image';
import React from 'react';

import { EIconSize, EIconBottomPadding } from './Icon.constants';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import clsx from 'clsx';

export const iconCVA = cva('object-contain', {
  variants: {
    bottomPadding: {
      [EIconBottomPadding.Yes]: '',
      [EIconBottomPadding.No]: '',
    },
    size: {
      [EIconSize.XxxL]: 'size-18 rounded-[1.25rem]',
      [EIconSize.XxL]: 'size-14 rounded-2xl',
      [EIconSize.OneXl]: 'size-12 rounded-[13.714px]',
      [EIconSize.Xl]: 'size-8 rounded-lg',
      [EIconSize.L]: 'size-6 rounded-lg',
      [EIconSize.M]: 'size-5 rounded-md',
      [EIconSize.S]: 'size-4 rounded',
      [EIconSize.Xs]: 'size-2',
    },
  },
  compoundVariants: [
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.XxxL,
      className: 'mb-5',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.XxL,
      className: 'mb-5',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.OneXl,
      className: 'mb-4',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.Xl,
      className: 'mb-4',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.M,
      className: 'mb-4',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.L,
      className: 'mb-5',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.S,
      className: 'mb-5',
    },
    {
      bottomPadding: EIconBottomPadding.Yes,
      size: EIconSize.Xs,
      className: 'mb-5',
    },
  ],
});

export type TIconProps = {
  size: EIconSize;
  bottomPadding?: EIconBottomPadding;
  icon?: ImageProps | null;
  className?: string;
  isRoundedIcon?: boolean;
};

export const Icon: React.FC<TIconProps> = props => {
  const {
    bottomPadding = EIconBottomPadding.No,
    size,
    icon,
    className,
    isRoundedIcon = false,
  } = props;

  if (!icon) {
    return null;
  }

  return (
    <ImageWithPlaceholder
      {...icon}
      className={clsx(iconCVA({ bottomPadding, size, className }), {
        'rounded-none': !isRoundedIcon,
      })}
    />
  );
};
