import { useMergeRefs } from '@floating-ui/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React, { useState, ChangeEvent, useEffect } from 'react';

import { ETextareaVariant } from './Textarea.constants';

export interface ITextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  variant?: ETextareaVariant;
  softLimit?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  (props, ref) => {
    const {
      variant = ETextareaVariant.Primary,
      placeholder,
      softLimit = 0,
      required = false,
      onChange = () => null,
      ...restProps
    } = props;
    const [parent] = useAutoAnimate<HTMLDivElement>();
    const [wrapper] = useAutoAnimate<HTMLDivElement>();

    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    const mergedRef = useMergeRefs<HTMLTextAreaElement>([ref, textareaRef]);

    const [val, setVal] = useState('');

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
      setVal(e.target.value);
      onChange(e);
    };

    useEffect(() => {
      setVal(textareaRef.current?.value || '');
    }, []);

    return (
      <div ref={parent} className='grid gap-2'>
        <div
          ref={wrapper}
          className={clsx(
            'grid h-35 gap-1 rounded-xl px-4 pb-5 pt-7',
            variant === ETextareaVariant.Primary && 'bg-surface-100-input',
            variant === ETextareaVariant.Secondary && 'bg-surface-50-input',
          )}
        >
          <textarea
            ref={mergedRef}
            {...restProps}
            className={clsx(
              'peer/input peer z-10 resize-none bg-transparent p-0 text-base font-medium leading-relaxed text-interface-800 caret-brand-500 focus:outline-none',
            )}
            onChange={handleChange}
          />
          <span
            className={clsx(
              'absolute start-4 top-5 origin-left leading-loose transition duration-500 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:font-normal peer-focus:text-brand-500 peer-[:not(:focus):hover]:text-interface-800 rtl:origin-right',
              val.length !== 0
                ? '-translate-y-3 scale-75 font-normal !text-brand-500'
                : 'text-base font-medium text-interface-500',
            )}
          >
            {placeholder}
            {required && '*'}
          </span>
        </div>

        {val.length !== 0 && softLimit !== 0 && (
          <span
            className={clsx(
              'px-4 text-xs leading-loose text-brand-500 transition',
              val.length > softLimit && 'text-error',
            )}
          >
            {val.length}
            <span
              className={clsx(
                'transition',
                val.length > softLimit ? 'text-error' : 'text-interface-500',
              )}
            >
              {'/' + softLimit}
            </span>
          </span>
        )}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
