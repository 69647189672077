interface IWindowWithRoistat extends Window {
  roistatProjectId?: string;
  roistatHost?: string;
}

export const roistat_getCookie = (name: string): string | null => {
  const cookie = ` ${document.cookie}`;
  const search = ` ${name}=`;
  let setStr: string | null = null;
  let offset = 0;
  let end = 0;

  if (cookie.length > 0) {
    offset = cookie.indexOf(search);

    if (offset !== -1) {
      offset += search.length;
      end = cookie.indexOf(';', offset);

      if (end === -1) {
        end = cookie.length;
      }

      setStr = decodeURIComponent(cookie.substring(offset, end));
    }
  }

  return setStr;
};

type TRoistatOptions = {
  projectId: string;
};

export const propagateRoistat = ({ projectId }: TRoistatOptions): void => {
  const w: IWindowWithRoistat = window;
  const d: Document = document;

  if (!w || !d) return;

  w.roistatProjectId = projectId;
  w.roistatHost = 'cloud.roistat.com';

  const p = d.location.protocol === 'https:' ? 'https://' : 'http://';
  const u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie)
    ? '/dist/module.js'
    : `/api/site/1.0/${w.roistatProjectId}/init?referrer=${encodeURIComponent(
        d.location.href,
      )}`;
  const js = d.createElement('script');

  js.charset = 'UTF-8';
  js.async = true;
  js.src = p + w.roistatHost + u;
  const js2 = d.querySelector('script');

  if (js2) {
    js2.parentNode?.insertBefore(js, js2);
  }
};
