import { baseApi } from '../../store/store.constants';

import type { PartnersWidgetProduct } from '@shared/master-types';
import type { PaginatedDocs } from 'payload/dist/mongoose/types';
import type { Where } from 'payload/types';

const partnersWidgetProductsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getPartnersWidgetProducts: build.query<
      PaginatedDocs<PartnersWidgetProduct>,
      {
        depth?: number;
        locale?: string;
        page?: number;
        limit?: number;
        draft?: boolean;
        where?: Where;
      } | void
    >({
      query: params => ({
        url: '/partners-widget-products',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getPartnersWidgetProducts },
  useGetPartnersWidgetProductsQuery,
} = partnersWidgetProductsApi;
