import { ArticleThumbnail, EActionType, toImageProps } from '@front/shared/ds';
import { BlogArticle, BlogTag, User } from '@shared/master-types';
import React from 'react';

export type TArticleThumbnailConnectedProps = BlogArticle;

const ArticleThumbnailConnected: React.FC<
  TArticleThumbnailConnectedProps
> = props => {
  const {
    cover,
    title,
    url: href = '',
    authors,
    createdAt,
    publishedAt,
    tags,
  } = props;

  // const url = breadcrumbs[breadcrumbs.length - 1]?.url || '';

  if (typeof tags[0] === 'string' || typeof authors[0] === 'string') {
    throw new Error(
      'Wrong tags or authors provided, expected array of objects but got array of strings',
    );
  }

  return (
    <ArticleThumbnail
      cover={toImageProps(cover)}
      title={title}
      url={{
        type: EActionType.LinkType,
        props: {
          href,
        },
      }}
      publishedAt={publishedAt || createdAt}
      tags={(tags as unknown as { value: BlogTag }[]).map(tag => ({
        id: tag.value.id,
        children: tag.value.name,
      }))}
      authors={(authors as unknown as User[]).map(author => ({
        name: `${author.firstName} ${author.lastName}`,
      }))}
    />
  );
};

export default ArticleThumbnailConnected;
