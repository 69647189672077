import { GtmTagManager } from '@front/shared/ds/GtmTagManager';
import React from 'react';

import { useGetSettingsQuery } from '../../../features/settings/getSettings.query';
import { useLocale } from '../../../hooks';

export const GoogleTagManager: React.FC = () => {
  const { locale } = useLocale();

  const { data: settings } = useGetSettingsQuery({ locale });
  const gtmId = settings?.googleTagManager?.gtmId;

  if (!gtmId) {
    return null;
  }

  return <GtmTagManager gtmId={gtmId} />;
};
