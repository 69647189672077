import clsx from 'clsx';
import React from 'react';

export interface ITableProps {
  tableHead: {
    tableHeadCols: {
      item: string;
      id?: string;
    }[];
    id?: string;
  }[];
  tableRows: {
    tableRowList: {
      item: string;
      id?: string;
    }[];
    id?: string;
  }[];
  id?: string;
}

const Table: React.FC<ITableProps> = props => {
  const { tableHead, tableRows } = props;

  return (
    <div className='scrollbar-hidden overscroll-inline-contain -mx-4 max-w-[100vw] overflow-x-auto overflow-y-hidden scroll-smooth px-4'>
      <table className='flex min-w-[38.75rem] flex-col gap-4 py-4'>
        {tableHead.length && (
          <thead>
            <tr className='flex w-full items-center gap-4 px-4'>
              {tableHead.map(headRow =>
                headRow.tableHeadCols.map((headCol, headIndex) => (
                  <th
                    key={headCol.id}
                    className='flex flex-1 items-center justify-center text-xs font-normal'
                  >
                    <span
                      className={clsx(
                        'w-fit rounded-2xl px-3 py-1.5 text-brand-500',
                        headIndex === 0
                          ? 'bg-brand-500 text-white'
                          : 'bg-brand-500/10',
                      )}
                    >
                      {headCol.item}
                    </span>
                  </th>
                )),
              )}
            </tr>
          </thead>
        )}
        {tableRows.length && (
          <tbody>
            {tableRows.map(tableRow => (
              <tr
                key={tableRow.id}
                className='flex w-full items-center gap-4 rounded-xl px-4 odd:bg-surface-100'
              >
                {tableRow.tableRowList?.map(tableRowCol => (
                  <td
                    key={tableRowCol.id}
                    className='flex-1 py-3 text-center text-sm font-normal text-interface-800 first:font-medium first:text-black'
                  >
                    {tableRowCol.item}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
