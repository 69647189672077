export const publicRuntimeConfig = {
  NEXT_PUBLIC_SENTRY_DSN_URL: process.env.NEXT_PUBLIC_SENTRY_DSN_URL
    ? process.env.NEXT_PUBLIC_SENTRY_DSN_URL.toString()
    : '',
  NEXT_APP_HOST: process.env.PROXY_HOST
    ? String(process.env.PROXY_HOST)
    : process.env.NEXT_APP_HOST
      ? String(process.env.NEXT_APP_HOST)
      : '',
  CDN_BASE_URL: process.env.CDN_BASE_URL
    ? String(process.env.CDN_BASE_URL)
    : '',
  NEXT_IMAGE_CROP_ENABLED: process.env.NEXT_IMAGE_CROP_ENABLED
    ? String(process.env.NEXT_IMAGE_CROP_ENABLED)
    : '',
  NEXT_VIDEO_CROP_ENABLED: process.env.NEXT_VIDEO_CROP_ENABLED
    ? String(process.env.NEXT_VIDEO_CROP_ENABLED)
    : '',
};
