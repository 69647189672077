import React from 'react';

import { useGetSettingsQuery } from '../../../features/settings/getSettings.query';
import { useLocale } from '../../../hooks';
import { usePropagateRoistat } from '../../layout/App/hooks';

export const PropagateRoistat: React.FC = () => {
  const { locale } = useLocale();

  const { data: settings } = useGetSettingsQuery({ locale });
  const roistatId = settings?.roistat?.roistatId;

  usePropagateRoistat(roistatId);

  return null;
};
