import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { TQuoteSelectedProps } from './Quote1';
import { ReactComponent as IconAvatarDefault } from '../../../../public/icons/avatar.svg';
import { ReactComponent as IconTriangle } from '../../../../public/icons/components/quote/quoteTriangle.svg';
import toLinkProps from '../../../utils/toLinkProps';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';
import { RichText } from '../RichText';

export const QuoteNewsWebsite3: React.FC<TQuoteSelectedProps> = props => {
  const {
    slogan,
    quote,
    sourceText,
    sourcePrefix,
    link,
    quoteLinkParam,
    isOpenInNewTab,
    authorIcon,
  } = props;

  return (
    <div className={clsx('relative pb-4')}>
      <div className='flex flex-col gap-3 overflow-y-hidden rounded-[1.25rem] bg-brand-500 p-6 text-white'>
        {slogan && <h4 className={clsx('text-xs font-medium')}>{slogan}</h4>}
        {sourceText && (
          <div className='flex items-center gap-1 text-xs text-brand-50/90'>
            <div className='mr-1'>
              {authorIcon ? (
                <ImageWithPlaceholder
                  {...authorIcon}
                  itemProp='image'
                  width={24}
                  height={24}
                  className='size-6 overflow-hidden rounded-3xl object-cover object-center'
                />
              ) : (
                <IconAvatarDefault />
              )}
            </div>
            {sourcePrefix && <span>{sourcePrefix}</span>}
            <h5>{sourceText}</h5>
          </div>
        )}
        <div
          className={clsx(
            'break-words',
            'prose-a:text-brand-500 prose-a:transition',
            'prose-p:text-base prose-p:font-semibold prose-p:italic prose-p:leading-extra-loose prose-p:text-interface-1000 prose-p:text-white prose-a:text-white prose-a:underline prose-a:underline-offset-2 hover:prose-a:text-white hover:prose-a:underline-offset-4',
          )}
        >
          <RichText isOpenInNewTab={isOpenInNewTab} content={quote} />
        </div>

        {link?.map(linkItem => {
          const linkWithProps = toLinkProps(quoteLinkParam, linkItem);

          return (
            <Link
              key={linkItem.id}
              className={clsx(
                'pt-1 text-xs text-white underline transition hover:text-white',
              )}
              {...linkWithProps}
            >
              {linkItem.text}
            </Link>
          );
        })}
      </div>
      <div className='absolute bottom-[2px] left-auto right-2 top-auto h-4 w-12 text-brand-500 xl:right-4'>
        <IconTriangle />
      </div>
    </div>
  );
};
