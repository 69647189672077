export { ReactComponent as IconArrowRight24 } from '../../../public/icons/arrow-right-24.svg';
export { ReactComponent as IconCheck } from '../../../public/icons/icon-check.svg';
export { ReactComponent as IconCross } from '../../../public/icons/icon-cross.svg';
export { ReactComponent as IconPencil } from '../../../public/icons/pencil.svg';
export { ReactComponent as IconChevronDown } from '../../../public/icons/icon-chevron-down.svg';
export { ReactComponent as IconChevronLeft } from '../../../public/icons/icon-chevron-left.svg';
export { ReactComponent as IconAttention } from '../../../public/icons/icon-attention.svg';
export { ReactComponent as IconQuotationMark } from '../../../public/icons/components/quote/quotationMark.svg';
export { ReactComponent as IconQuote } from '../../../public/icons/icon-quote.svg';
export { ReactComponent as IconGlobe } from '../../../public/icons/icon-globe.svg';
export { ReactComponent as IconLoader } from '../../../public/icons/loader.svg';
