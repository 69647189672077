import { propagateRoistat } from '@front/shared/ds/propagateRoistat';
import { useEffect } from 'react';

const DEFER_TIMEOUT = 5000; // 5 seconds

export const usePropagateRoistat = (roistatId?: string, defer = true): void => {
  useEffect(() => {
    if (!roistatId) {
      return () => ({});
    }

    const timeout = setTimeout(propagateRoistat, defer ? DEFER_TIMEOUT : 0, {
      projectId: roistatId,
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [roistatId, defer]);
};
