export const enum ETitleSize {
  Small = 'Small',
  Medium = 'Medium',
  UnchangingMedium = 'UnchangingMedium',
  Large = 'Large',
}

export const enum ETitleType {
  Text = 'text',
  Email = 'email',
  Phone = 'phone',
}

export const enum ECardTextColor {
  Dark = 'Dark',
  Light = 'Light',
}

export const enum EIconSize {
  XxxL = '3XL',
  XxL = '2XL',
  Xl = 'XL',
  L = 'L',
  M = 'M',
  S = 'S',
  Xs = 'XS',
  None = 'None',
}

export const enum EIconPosition {
  Top = 'Top',
  Right = 'Right',
  RightCenter = 'RightCenter',
}

export const enum EBorderStyle {
  None = 'None',
  Solid = 'Solid',
  Dashed = 'Dashed',
}
