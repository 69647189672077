export const ForexLiquidityConstants = [
  { colorName: '--color-brand-250', color: '195 255 237' },
  { colorName: '--color-brand-300', color: '37 222 183' },
  { colorName: '--color-brand-500', color: '10 190 152' },
  { colorName: '--color-brand-550', color: '0 152 124' },
  { colorName: '--color-accent-500', color: '238 249 246' },
  { colorName: '--color-accent-550', color: '230 230 230' },
  { colorName: '--color-accent-800', color: '167 227 214' },
  { colorName: '--color-accent-850', color: '230 230 230' },
];
