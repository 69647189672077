import { format } from 'date-fns/format';
import { ImageProps } from 'next/image';
import { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

import { ETagSize, ETagTheme, ETagVariant, Tag } from '../../atoms/Tag';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { ReadingTime } from '../ReadingTime';

export type TArticleTagsProps = (LinkProps & {
  children: ReactNode;
  id: string;
})[];
export type TArticleProps = {
  title: string;
  publishedAt?: string;
  updatedAt?: string;
  readingTime?: number;
  cover?: ImageProps | null;
  tags?: TArticleTagsProps;
  readingTimeMinute?: string;
};

const Article: React.FC<TArticleProps> = props => {
  const {
    cover,
    tags = [],
    publishedAt,
    readingTime,
    updatedAt,
    title,
    readingTimeMinute,
  } = props;

  return (
    <article itemScope itemType='http://schema.org/Article'>
      {title && (
        <h1
          itemProp='headline'
          className='text-5xl font-semibold leading-normal text-interface-1000 lg:text-7xl lg:leading-snug'
        >
          {title}
        </h1>
      )}
      <div className='mt-4 grid w-full grid-cols-[repeat(2,_max-content)] gap-x-5 gap-y-4 lg:mt-5 lg:grid-cols-[repeat(3,_max-content)]'>
        {tags.length !== 0 && (
          <div className='col-start-1 col-end-3 flex gap-2 lg:col-end-2'>
            {tags.map(({ id, children, ...tag }) => (
              <Tag
                key={id}
                {...tag}
                variant={ETagVariant.Contained}
                theme={ETagTheme.Secondary}
                size={ETagSize.Small}
                itemProp='about'
                itemScope
                itemType='http://schema.org/Thing'
              >
                <span itemProp='name'>{children}</span>
              </Tag>
            ))}
          </div>
        )}
        <div className='flex items-center gap-3 empty:hidden'>
          {publishedAt && (
            <time
              dateTime={publishedAt}
              className='text-xs font-medium leading-relaxed text-interface-800'
              itemProp='datePublished'
            >
              {format(new Date(publishedAt), 'dd.MM.yyyy')}
            </time>
          )}
          {updatedAt && (
            <time
              dateTime={updatedAt}
              className='inline-block text-xs font-medium leading-relaxed text-interface-500'
              itemProp='dateModified'
            >
              Updated {format(new Date(updatedAt), 'dd.MM.yyyy')}
            </time>
          )}
        </div>
        <div className='col-start-2 col-end-3 flex gap-3 lg:col-start-auto lg:col-end-auto'>
          {readingTime && (
            <ReadingTime
              readingTime={readingTime}
              readingTimeMinute={readingTimeMinute}
            />
          )}
          {/*<div className='flex items-center gap-1'>*/}
          {/*  <IconEyes className='h-3 w-3' />*/}
          {/*  {451}*/}
          {/*</div>*/}
        </div>
      </div>
      {cover && (
        <div className='relative mt-5 h-[12.063rem] overflow-hidden rounded-3xl lg:mt-6 xl:h-72 2xl:h-[21.813rem]'>
          <ImageWithPlaceholder
            {...cover}
            itemProp='image'
            className='object-cover'
          />
        </div>
      )}
    </article>
  );
};

export default Article;
