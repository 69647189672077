import { BlogEvent } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogEvent: build.query<
      BlogEvent,
      {
        id: string;
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ id, ...params }) => ({
        url: `/blog-events/${id}`,
        params,
      }),
    }),
    updateEventRating: build.mutation({
      query: ({ id, body }) => ({
        url: `/blog-events/${id}/rating`,
        method: 'PATCH',
        body: body,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogEvent },
  useGetBlogEventQuery,
  useUpdateEventRatingMutation,
} = blogPostsApi;
