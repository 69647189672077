import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { TQuoteSelectedProps } from './Quote1';
import { ReactComponent as IconAvatarDefault } from '../../../../public/icons/avatar.svg';
import toLinkProps from '../../../utils/toLinkProps';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';
import { RichText } from '../RichText';

export const Quote3: React.FC<TQuoteSelectedProps> = props => {
  const {
    slogan,
    quote,
    sourceText,
    sourcePrefix,
    link,
    quoteLinkParam,
    isOpenInNewTab,
    authorIcon,
  } = props;

  return (
    <div
      className={clsx(
        'flex flex-col gap-3 overflow-y-hidden rounded-[1.25rem] bg-brand-500 p-6 text-white',
      )}
    >
      {slogan && <h4 className={clsx('text-sm font-medium')}>{slogan}</h4>}
      <div
        className={clsx(
          'break-words',
          'prose-a:text-brand-500 prose-a:transition',
          'prose-p:py-2 prose-p:text-base prose-p:font-semibold prose-p:italic prose-p:leading-extra-loose prose-p:text-interface-1000 prose-p:text-white prose-a:text-white prose-a:underline prose-a:underline-offset-2 hover:prose-a:text-white hover:prose-a:underline-offset-4',
        )}
      >
        <RichText isOpenInNewTab={isOpenInNewTab} content={quote} />
      </div>
      {sourceText && (
        <div className='flex items-center gap-1'>
          <div className='mr-1'>
            {authorIcon ? (
              <ImageWithPlaceholder
                {...authorIcon}
                itemProp='image'
                width={24}
                height={24}
                className='size-6 overflow-hidden rounded-3xl object-cover object-center'
              />
            ) : (
              <IconAvatarDefault />
            )}
          </div>
          {sourcePrefix && (
            <span className={clsx('text-xs')}>{sourcePrefix}</span>
          )}
          <h5 className={clsx('text-xs')}>{sourceText}</h5>
        </div>
      )}
      {link?.map(linkItem => {
        const linkWithProps = toLinkProps(quoteLinkParam, linkItem);

        return (
          <div key={linkItem.id}>
            <Link
              className={clsx(
                'pt-1 text-xs text-white underline underline-offset-2 transition hover:text-white hover:underline-offset-4',
              )}
              {...linkWithProps}
            >
              {linkItem.text}
            </Link>
          </div>
        );
      })}
    </div>
  );
};
