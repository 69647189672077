import { baseApi } from '../../store/store.constants';

import type { Modal } from '@shared/master-types';

const modalsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getModal: build.query<
      Modal,
      { id: Modal['id']; depth?: number; locale?: string }
    >({
      query: ({ id, ...params }) => ({
        url: `/modals/${id}`,
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getModal },
  useGetModalQuery,
} = modalsApi;
