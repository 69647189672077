import React, { ReactNode } from 'react';

import { TMetaProps, Meta, Theme } from '../../../components';
import {
  useGetMetaQuery,
  useGetLocalesQuery,
  useGetPageQuery,
} from '../../../features';

type TSalesforceFormLayoutProps = {
  locale: string;
  draft?: boolean;
  children: ReactNode;
  meta?: Partial<TMetaProps>;
  pageId: string;
};

export const SalesforceFormLayout: React.FC<
  TSalesforceFormLayoutProps
> = props => {
  const { draft, locale, children, meta: metaProps, pageId } = props;

  const { data: localesObject } = useGetLocalesQuery({
    locale,
    draft,
  });

  const { data: meta } = useGetMetaQuery({ locale });
  const { data: { parts } = {} } = useGetPageQuery(
    { id: pageId, draft, locale },
    {
      skip: !pageId,
    },
  );

  const locales = localesObject?.docs;

  const pageTheme = parts?.pageTheme;

  return (
    <>
      {!!pageTheme && <Theme key={pageTheme} pageTheme={pageTheme} />}

      {!!meta && <Meta {...meta} {...metaProps} locales={locales} />}

      <main className='flex-1'>{children}</main>
    </>
  );
};
