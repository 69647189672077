import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { EContainerBackground, EContainerVariant } from './Container.constants';

export interface IContainerProps {
  children: ReactNode;
  variant?: EContainerVariant;
  containerBackground?: EContainerBackground;
  isPadding?: boolean;
  isNoPaddingOnMobile?: boolean;
  dataGaId?: string;
  overflowHidden?: boolean;
  className?: string;
}

const Container: React.FC<IContainerProps> = props => {
  const {
    children,
    variant = EContainerVariant.Default,
    containerBackground = EContainerBackground.Transparent,
    isPadding = true,
    isNoPaddingOnMobile,
    dataGaId,
    overflowHidden = false,
    className,
  } = props;

  return (
    <div
      className={twMerge(
        clsx(
          'm-auto w-full',
          isPadding && '2xl:px-4',
          isPadding && !isNoPaddingOnMobile && 'px-4',
          variant === EContainerVariant.Small &&
            'max-w-[28.875rem] tablet:max-w-[53rem]',
          variant === EContainerVariant.Default && 'max-w-[80.5rem]',
          variant === EContainerVariant.Main && 'max-w-[79rem]',
          variant === EContainerVariant.Lead && 'max-w-[86rem]',
          variant === EContainerVariant.DoubleLead && 'max-w-[90rem]',
          variant === EContainerVariant.Full && 'max-w-full',
          overflowHidden && 'overflow-hidden',
          containerBackground === EContainerBackground.White && 'bg-surface-50',
          containerBackground === EContainerBackground.Dark && 'bg-black',
          containerBackground === EContainerBackground.Light &&
            'bg-surface-100',
          className,
        ),
      )}
      data-ga-id={dataGaId}
    >
      {children}
    </div>
  );
};

export default Container;
