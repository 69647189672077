export const enum ETabSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const enum ETabTheme {
  Contained = 'contained',
  Outline = 'outline',
  Text = 'text',
}
