import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { TQuoteSelectedProps } from './Quote1';
import { ReactComponent as IconAvatarDefault } from '../../../../public/icons/avatar.svg';
import toLinkProps from '../../../utils/toLinkProps';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';
import { RichText } from '../RichText';

export const QuoteNewsWebsite4: React.FC<TQuoteSelectedProps> = props => {
  const {
    slogan,
    quote,
    sourceText,
    sourcePrefix,
    link,
    quoteLinkParam,
    isOpenInNewTab,
    authorIcon,
  } = props;

  return (
    <div
      className={clsx(
        'relative flex flex-col gap-3 overflow-y-hidden rounded-[1.25rem] p-6',
      )}
    >
      <div className='absolute left-auto right-0 top-0 h-full w-1 bg-brand-500' />
      <div className='absolute left-0 right-auto top-0 h-full w-1 bg-brand-500' />
      {slogan && (
        <h4 className={clsx('text-sm font-medium text-brand-500')}>{slogan}</h4>
      )}
      {sourceText && (
        <div className='flex items-center gap-1 text-xs text-interface-1000/80'>
          <div className='mr-1'>
            {authorIcon ? (
              <ImageWithPlaceholder
                {...authorIcon}
                itemProp='image'
                width={24}
                height={24}
                className='size-6 overflow-hidden rounded-3xl object-cover object-center'
              />
            ) : (
              <IconAvatarDefault />
            )}
          </div>
          {sourcePrefix && <span>{sourcePrefix}</span>}
          <h5>{sourceText}</h5>
        </div>
      )}
      <div
        className={clsx(
          'break-words',
          'prose-a:text-brand-500 prose-a:transition',
          'prose-p:text-base prose-p:font-semibold prose-p:italic prose-p:leading-extra-loose prose-p:text-interface-1000',
        )}
      >
        <RichText isOpenInNewTab={isOpenInNewTab} content={quote} />
      </div>

      {link?.map(linkItem => {
        const linkWithProps = toLinkProps(quoteLinkParam, linkItem);

        return (
          <div key={linkItem.id}>
            <Link
              className={clsx(
                'pt-1 text-xs text-control-950 underline transition hover:text-brand-550',
              )}
              {...linkWithProps}
            >
              {linkItem.text}
            </Link>
          </div>
        );
      })}
    </div>
  );
};
