import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import * as querystring from 'qs';

import { ADMIN_API_MARKER } from '../consts/ADMIN_API_MARKER';
import { isSSR } from '../utils';

import type {
  TAppDispatch,
  TAppStartListening,
  TRootState,
} from './store.types';

let baseUrl = isSSR() ? `${process.env.NEXT_ADMIN_HOST}/api` : ADMIN_API_MARKER;

// @see https://mswjs.io/docs/runbook#rtk-query-requests-are-not-intercepted
baseUrl =
  process?.env?.NODE_ENV === 'test'
    ? new URL(ADMIN_API_MARKER, location.origin).href
    : baseUrl;

const baseQuery = fetchBaseQuery({
  baseUrl,
  paramsSerializer: params => {
    const nextParams = { ...params };
    return querystring.stringify(nextParams);
  },
  credentials: 'include',
  prepareHeaders: (headers, { extra }) => {
    const { cookies } = extra as { cookies: Record<string, string> };
    if (cookies) {
      if (cookies['payload-token']) {
        headers.set('cookie', `payload-token=${cookies['payload-token']}`);
      }

      if (cookies['weblate-token']) {
        headers.set('cookie', `weblate-token=${cookies['weblate-token']}`);
      }
    }

    return headers;
  },
});

export const baseApi = createApi({
  baseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return (action as PayloadAction<never>).payload[reducerPath];
    }
  },
  endpoints: () => ({}),
  tagTypes: [],
});

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening =
  listenerMiddleware.startListening as TAppStartListening;

export const addAppListener = addListener as TypedAddListener<
  TRootState,
  TAppDispatch
>;
