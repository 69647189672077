export enum ETitlePartsSize {
  XxxxxxL = '6XL',
  XxxxxL = '5XL',
  XxxxL = '4XL',
  XxxL = '3XL',
  XxL = '2XL',
  Xl = 'XL',
  L = 'L',
  Ml = 'ML',
  M = 'M',
  S = 'S',
  Xs = 'XS',
}

export enum ETitlePartsPosition {
  Center = 'center',
  Left = 'left',
}

export enum ETitlePartsTheme {
  Dark = 'dark',
  Light = 'light',
  Primary = 'primary',
}
