import { toImageProps, ImageWithPlaceholder } from '@front/shared/ds';
import { Hero } from '@shared/master-types';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

type TUseHeroImagesProps = {
  hero: Hero;
  customImages?: ReactNode[];
  isDarkPreloader?: boolean;
};

type TUseHeroImagesResult = {
  images: ReactNode[];
};

export const useHeroImages = ({
  customImages,
  hero,
  isDarkPreloader = false,
}: TUseHeroImagesProps): TUseHeroImagesResult => {
  const {
    variant,
    imageLeftDesktop,
    imageRightDesktop,
    imageMobile,
    coverImage,
  } = hero;

  let images = customImages || [];
  if (variant === 'ThreeImages') {
    images = [];
    const mobileImageProps = toImageProps(imageMobile);
    const leftImageProps = toImageProps(imageLeftDesktop);
    const rightImageProps = toImageProps(imageRightDesktop);

    if (mobileImageProps) {
      images.push(
        <ImageWithPlaceholder
          key='mobile'
          className='absolute left-1/2 top-0 h-35 max-w-none -translate-x-1/2 object-contain object-center xl:hidden'
          priority
          {...mobileImageProps}
          alt={mobileImageProps.alt}
          src={mobileImageProps.src}
        />,
      );
    }

    if (leftImageProps) {
      images.push(
        <ImageWithPlaceholder
          key='left'
          className={clsx(
            'absolute right-auto top-0 hidden w-full max-w-[31.25rem] object-contain object-left xl:block',
          )}
          isDark={isDarkPreloader}
          priority
          {...leftImageProps}
          alt={leftImageProps.alt}
          src={leftImageProps.src}
        />,
      );
    }

    if (rightImageProps) {
      images.push(
        <ImageWithPlaceholder
          key='right'
          className={clsx(
            'absolute left-auto right-0 top-0 hidden max-w-[31.25rem] object-contain object-right xl:block',
          )}
          priority
          isDark={isDarkPreloader}
          {...rightImageProps}
          alt={rightImageProps.alt}
          src={rightImageProps.src}
        />,
      );
    }
  }
  if (variant === 'CoverImage') {
    const coverImageProps = toImageProps(coverImage);
    if (coverImageProps) {
      images.push(
        <ImageWithPlaceholder
          key='Cover Image'
          className='absolute left-0 top-0 size-full object-cover object-center'
          priority
          fill={true}
          alt={coverImageProps.alt}
          src={coverImageProps.src}
        />,
      );
    }
  }

  return {
    images,
  };
};
