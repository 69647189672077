import { getGaId } from '@front/shared';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  descriptionCVA,
  EHeroSectionContentWidth,
  EHeroSectionPadding,
  EHeroSectionTitlePosition,
  EHeroSectionTitleTheme,
  eyebrowsCVA,
  heroTitleCVA,
} from './HeroSectionTitle.constants';
import { EActionType, TActionWithText } from '../../../../src/types';
import { Button, EButtonSize, EButtonTheme } from '../../atoms/Button';
import {
  EEyebrowSize,
  EEyebrowTheme,
  EEyebrowVariant,
  Eyebrow,
} from '../../atoms/Eyebrow';
import { ESimpleIconSize, Icon } from '../../atoms/Icon';

export type THeroSectionTitleProps = {
  topImage?: ImageProps | null;
  title: React.JSX.Element | string;
  description?: React.JSX.Element | string;
  actions?: TActionWithText[];
  eyebrowsBelow?: string[];
  eyebrowsAbove?: string[];
  position?: EHeroSectionTitlePosition;
  theme?: EHeroSectionTitleTheme;
  contentWidth?: EHeroSectionContentWidth;
  className?: string;
  actionsClassName?: string;
  padding?: EHeroSectionPadding;
};

export const HeroSectionTitle: React.FC<THeroSectionTitleProps> = ({
  topImage,
  title,
  description,
  eyebrowsBelow,
  eyebrowsAbove,
  actions = [],
  position = EHeroSectionTitlePosition.Center,
  theme = EHeroSectionTitleTheme.Theme1,
  contentWidth = EHeroSectionContentWidth.Md,
  className,
  actionsClassName,
  padding = EHeroSectionPadding.None,
}) => {
  let eyebrowTheme = EEyebrowTheme.Primary;

  if (theme === EHeroSectionTitleTheme.Theme5) {
    eyebrowTheme = EEyebrowTheme.Light;
  }

  const isTopPadding = [
    EHeroSectionPadding.Top,
    EHeroSectionPadding.TopAndBottom,
  ].includes(padding);

  const isBottomPadding = [
    EHeroSectionPadding.Bottom,
    EHeroSectionPadding.TopAndBottom,
  ].includes(padding);

  return (
    <div
      className={clsx('grid w-full gap-8 lg:mt-0 xl:gap-12', className, {
        'xl:max-w-[38rem]': contentWidth === EHeroSectionContentWidth.Sm,
        'xl:max-w-[38.75rem]': contentWidth === EHeroSectionContentWidth.Md,
        'xl:max-w-[52rem]': contentWidth === EHeroSectionContentWidth.Lg,
        'xl:max-w-[64rem]': contentWidth === EHeroSectionContentWidth.Xl,
        'mx-auto': position === EHeroSectionTitlePosition.Center,
        'pt-12': isTopPadding,
        'pb-12': isBottomPadding,
      })}
    >
      <div
        className={clsx(
          'flex flex-col gap-6',
          position === EHeroSectionTitlePosition.Center
            ? 'mx-auto text-center'
            : 'text-start',
        )}
      >
        {topImage && (
          <Icon
            size={ESimpleIconSize.XxL}
            icon={topImage}
            className={clsx({
              'mx-auto': position === EHeroSectionTitlePosition.Center,
            })}
          />
        )}
        {!!eyebrowsAbove?.length && (
          <div
            className={clsx('flex flex-row flex-wrap gap-6', {
              'justify-center': position === EHeroSectionTitlePosition.Center,
            })}
          >
            {eyebrowsAbove.map((eyebrow, index) => (
              <Eyebrow
                key={eyebrow + index}
                variant={EEyebrowVariant.TextTracking}
                size={EEyebrowSize.Medium}
                theme={eyebrowTheme}
                className={eyebrowsCVA({ theme: theme })}
              >
                {eyebrow}
              </Eyebrow>
            ))}
          </div>
        )}
        <div className={clsx('flex flex-col gap-3 md:gap-4')}>
          <h1
            className={twMerge(
              clsx(
                'whitespace-pre-line text-7xl leading-snug xl:text-10xl xl:leading-normal',
                {
                  'text-interface-1000':
                    theme === EHeroSectionTitleTheme.Theme1,
                  'text-interface-50':
                    theme === EHeroSectionTitleTheme.Theme4 ||
                    theme === EHeroSectionTitleTheme.Theme5 ||
                    theme === EHeroSectionTitleTheme.HeroSectionB3,
                },
              ),
              heroTitleCVA({ theme: theme }),
            )}
          >
            {title}
          </h1>

          {!!eyebrowsBelow?.length && (
            <div
              className={clsx('flex flex-row flex-wrap gap-6', {
                'justify-center': position === EHeroSectionTitlePosition.Center,
              })}
            >
              {eyebrowsBelow.map((eyebrow, index) => (
                <Eyebrow
                  key={eyebrow + index}
                  variant={EEyebrowVariant.TextTracking}
                  size={EEyebrowSize.Medium}
                  theme={eyebrowTheme}
                  className={eyebrowsCVA({ theme: theme })}
                >
                  {eyebrow}
                </Eyebrow>
              ))}
            </div>
          )}

          {description && (
            <h2
              className={twMerge(
                clsx(
                  'whitespace-pre-line text-base font-normal leading-extra-loose xl:max-w-[38.75rem]',
                  {
                    'text-interface-800':
                      theme === EHeroSectionTitleTheme.Theme1,
                    'text-interface-50/80':
                      theme === EHeroSectionTitleTheme.Theme4 ||
                      theme === EHeroSectionTitleTheme.Theme5 ||
                      theme === EHeroSectionTitleTheme.HeroSectionB3,
                    'xl:mx-auto': position === EHeroSectionTitlePosition.Center,
                  },
                ),
                descriptionCVA({ theme: theme }),
              )}
            >
              {description}
            </h2>
          )}
        </div>
      </div>

      {actions.length ? (
        <div
          className={clsx(
            'flex w-full flex-row flex-wrap items-center gap-4 2xl:max-w-[50rem]',
            actionsClassName,
            {
              'mx-auto justify-center':
                position === EHeroSectionTitlePosition.Center,
            },
          )}
        >
          {actions.map((action, index) => (
            <React.Fragment key={index}>
              {'props' in action && (
                <Button
                  theme={EButtonTheme.Tertiary}
                  size={EButtonSize.Large}
                  {...action.props}
                  data-ga-id={getGaId(action.dataGaId || action.text, 'hero')}
                />
              )}
              {action.type === EActionType.Text && (
                <div className='px-4 text-lg font-medium leading-normal text-interface-500'>
                  {action.text}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export * from './HeroSectionTitle.constants';
