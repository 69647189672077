import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import getSocialIcon from '../../../utils/getSocialIcon';
import { Container } from '../../sections/Container';

export interface IContactsProps {
  image: ImageProps | null;
  description?: React.JSX.Element | null;
  socials?: {
    type:
      | 'telegram'
      | 'linkedIn'
      | 'facebook'
      | 'youtube'
      | 'instagram'
      | 'twitter'
      | string;
    link: string;
  }[];
  contacts?: { label: string; value: string; type: 'email' | 'telephone' }[];
}

const Contacts: React.FC<IContactsProps> = props => {
  const { image, contacts = [], description, socials = [] } = props;

  return (
    <Container>
      <section className='grid gap-4 text-sm leading-loose 2xl:grid-cols-12'>
        <div className='empty:hidden prose-p:text-control-950 prose-a:text-brand-500 prose-a:transition 2xl:col-span-12'>
          {description}
        </div>
        {image && (
          <Image
            {...image}
            sizes='(max-width: 768px) 100vw, 75vw'
            className={twMerge(
              clsx(
                'size-full min-h-48 rounded-3xl object-cover 2xl:col-start-1 2xl:col-end-10 2xl:min-h-[21.625rem]',
                contacts.length === 0 &&
                  Object.keys(contacts).length === 0 &&
                  '2xl:col-end-13',
              ),
            )}
          />
        )}
        <div className='flex flex-col items-center justify-center gap-12 rounded-3xl bg-surface-100 px-8 py-9 text-center empty:hidden 2xl:col-start-10 2xl:col-end-13 2xl:items-start 2xl:gap-10 2xl:text-start'>
          {contacts.length !== 0 && (
            <div className='grid gap-8'>
              {contacts.map(contact => (
                <div key={contact.value} className='grid gap-2'>
                  <span className='text-sm leading-loose text-brand-500'>
                    {contact.label}
                  </span>
                  <a
                    href={
                      contact.type === 'email'
                        ? `mailto:${contact.value}`
                        : `tel:${contact.value.replace(/ /g, '')}`
                    }
                    target='_blank'
                    rel='noreferrer noopener'
                    className='text-lg font-medium leading-normal text-control-950 2xl:text-base 2xl:leading-relaxed'
                  >
                    {contact.value}
                  </a>
                </div>
              ))}
            </div>
          )}
          {socials.length !== 0 && (
            <div className='flex flex-wrap justify-center gap-6 text-control-600 empty:hidden 2xl:justify-start 2xl:gap-5'>
              {socials.map(s => (
                <a
                  key={s.type}
                  href={s.link}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {getSocialIcon(s.type)}
                </a>
              ))}
            </div>
          )}
        </div>
      </section>
    </Container>
  );
};

export default Contacts;
