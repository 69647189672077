import { BlogAuthor } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogAuthor: build.query<
      BlogAuthor,
      {
        id: string;
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ id, ...params }) => ({
        url: `/blog-authors/${id}`,
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogAuthor },
  useGetBlogAuthorQuery,
} = blogPostsApi;
