export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'en';
export const LANG_MAP: Record<string, string> = {
  'zh-hant': 'zh-TC',
  'zh-hans': 'zh-CN',
};

export const DEFAULT_JURISDICTION =
  process.env.NEXT_PUBLIC_DEFAULT_JURISDICTION || 'default';

export enum EWebsiteType {
  News = 'news',
}
