import { Header as THeader } from '@shared/master-types';
import escapeHTML from 'escape-html';
import Link from 'next/link';
import React from 'react';

import { TLeaf } from './types';
import { toLinkProps } from '../../../utils';

type TLinkProps = {
  linkParam: string;
  node: TLeaf;
  children: (React.JSX.Element | null)[];
  onOpenModal?: (modalId: string) => void;
};

export const LinkParser = ({
  linkParam,
  node,
  children,
  onOpenModal,
}: TLinkProps): React.JSX.Element => {
  const linkClass = 'text-brand-500 hover:text-brand-500/80';

  if (node.linkType === 'interactive') {
    return (
      <button
        type={'button'}
        className={linkClass}
        onClick={() => {
          if (onOpenModal && node.fields?.reference?.value) {
            onOpenModal(String(node.fields.reference.value));
          }
        }}
      >
        {children}
      </button>
    );
  }

  if (node.linkType === 'custom') {
    return (
      <a
        href={escapeHTML(node.url)}
        onClick={e => {
          e.stopPropagation();
        }}
        target={node.newTab ? '_blank' : '_self'}
        className={linkClass}
        rel={
          Array.isArray(node?.fields?.rel) ? node?.fields?.rel.join(' ') : ''
        }
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      className={linkClass}
      onClick={e => {
        e.stopPropagation();
      }}
      {...toLinkProps(
        linkParam,
        node as unknown as NonNullable<
          NonNullable<
            Extract<
              Required<THeader>['header'][number],
              { blockType: 'header-simple' }
            >
          >['menuItems'][number]['action']
        >['link'],
      )}
    >
      {children}
    </Link>
  );
};
