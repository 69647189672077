export const enum EDefaultCardsAlign {
  Start = 'Start',
  Center = 'Center',
  End = 'End',
}
export const enum EDefaultCardsColumns {
  Two = '2',
  Three = '3',
  Four = '4',
}
export const enum EDefaultCardsHorizontalScroll {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Mobile = 'Mobile',
  Desktop = 'Desktop',
}
