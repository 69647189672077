import { useEffect } from 'react';

declare const window: Window &
  typeof globalThis & {
    NEXT_PUBLIC_SENTRY_DSN_URL: string | undefined;
  };

const DEFER_TIMEOUT = 5000; // 5 seconds

export const useInitializeSentry = (): void => {
  useEffect(() => {
    const SENTRY_DSN_URL = window.NEXT_PUBLIC_SENTRY_DSN_URL;

    if (!SENTRY_DSN_URL) {
      console.warn('Sentry DSN not provided');
      return;
    }

    const timeout = setTimeout(() => {
      import('@sentry/nextjs')
        .then(Sentry => {
          return Sentry.init({
            dsn: SENTRY_DSN_URL,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [new Sentry.Replay()],
            release: process.env.NEXT_PUBLIC_RELEASE,
          });
        })
        .catch(err => {
          console.error('Failed to initialize Sentry', err);
        });
    }, DEFER_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
};
