import {
  BlogArticle,
  BlogAuthor,
  BlogEvent,
  Media,
  Page,
} from '@shared/master-types';
import React from 'react';

import { EQuoteVariant } from './Quote.constants';
import { Quote1 } from './Quote1';
import { Quote2 } from './Quote2';
import { Quote3 } from './Quote3';
import { QuoteNewsWebsite1 } from './QuoteNewsWebsite1';
import { QuoteNewsWebsite2 } from './QuoteNewsWebsite2';
import { QuoteNewsWebsite3 } from './QuoteNewsWebsite3';
import { QuoteNewsWebsite4 } from './QuoteNewsWebsite4';
import toImageProps from '../../../utils/toImageProps';
import toLinkParam from '../../../utils/toLinkParam';
import { TRichTextContent } from '../RichText';

export type TQuoteDocProps =
  | {
      value: string | Page;
      relationTo: 'pages';
    }
  | {
      value: string | BlogArticle;
      relationTo: 'blog-articles';
    }
  | {
      value: string | BlogAuthor;
      relationTo: 'blog-authors';
    }
  | {
      value: string | BlogEvent;
      relationTo: 'blog-events';
    };

export type TQuoteLinkProps = {
  text: string;
  linkType: 'internal' | 'custom' | 'interactive';
  url: string;
  doc: TQuoteDocProps;
  hash?: string;
  newTab?: boolean;
  disabled?: boolean;
  rel: ('nofollow' | 'noopener' | 'noreferrer' | 'external')[];
  id?: string;
}[];

export type TQuoteProps = {
  variant: EQuoteVariant;
  slogan?: string;
  quote?: TRichTextContent;
  authorAvatar?: string | Media;
  sourceText?: string;
  sourcePrefix?: string;
  link?: TQuoteLinkProps;
  locale: string;
  jurisdiction: string;
  isOpenInNewTab?: boolean;
};

export const Quote: React.FC<TQuoteProps> = props => {
  const {
    variant = EQuoteVariant.Quote1,
    locale,
    jurisdiction,
    authorAvatar,
  } = props;

  const quoteLinkParams = toLinkParam({
    locale: locale?.toString(),
    jurisdiction: jurisdiction?.toString(),
  });

  const authorIcon = toImageProps(authorAvatar);

  let quoteComponentToRender;

  switch (variant) {
    case EQuoteVariant.Quote2:
      quoteComponentToRender = (
        <Quote2
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
      break;
    case EQuoteVariant.Quote3:
      quoteComponentToRender = (
        <Quote3
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
      break;
    case EQuoteVariant.QuoteNewsWebsite1:
      quoteComponentToRender = (
        <QuoteNewsWebsite1
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
      break;
    case EQuoteVariant.QuoteNewsWebsite2:
      quoteComponentToRender = (
        <QuoteNewsWebsite2
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
      break;
    case EQuoteVariant.QuoteNewsWebsite3:
      quoteComponentToRender = (
        <QuoteNewsWebsite3
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
      break;
    case EQuoteVariant.QuoteNewsWebsite4:
      quoteComponentToRender = (
        <QuoteNewsWebsite4
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
      break;
    default:
      quoteComponentToRender = (
        <Quote1
          authorIcon={authorIcon}
          quoteLinkParam={quoteLinkParams}
          {...props}
        />
      );
  }

  return <blockquote className='py-4'>{quoteComponentToRender}</blockquote>;
};
