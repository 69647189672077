import { NewsBlock, buildArticlesList } from '@front/shared/ds';
import { Jurisdiction, Locale, Section } from '@shared/master-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { useGetBlogArticlesQuery } from '../../../features/blogArticles/getBlogArticles.query';
import { useGetBlogTagsQuery } from '../../../features/blogTags/getBlogTags.query';

export type TNewsBlockConnectedProps = Extract<
  Required<Section>['rows'][number],
  {
    blockType: 'news-block';
  }
> & {
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
  authorId?: string;
};

const NewsAuthorsBlockConnected: React.FC<TNewsBlockConnectedProps> = ({
  perPage,
  emptyText,
  locales = [],
  jurisdictionList,
  jurisdictionItem,
  authorId,
}) => {
  const router = useRouter();
  const currentLocale = String(router.query.locale);
  const numberPerPage = Number(perPage);
  const [page, setPage] = useState(1);

  const handlePageChange = (e: { selected: number }): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(e.selected + 1);
  };

  const articlesQuery = useMemo(() => {
    const query: Parameters<typeof useGetBlogTagsQuery>[0] = {
      locale: currentLocale,
      draft: false,
      page: page,
      limit: numberPerPage,
      ...(authorId
        ? {
            where: {
              'authors.value': {
                in: [authorId],
              },
            },
          }
        : null),
    };

    return query;
  }, [currentLocale, page, numberPerPage, authorId]);

  const { data: articleDocs, isLoading } =
    useGetBlogArticlesQuery(articlesQuery);

  // todo: move filtering to query
  const docsArray = authorId
    ? articleDocs?.docs.filter(article =>
        article.authors
          .map(author =>
            typeof author?.value === 'string'
              ? author?.value
              : author?.value?.id,
          )
          .includes(authorId),
      )
    : articleDocs?.docs || [];
  const totalPages = articleDocs?.totalPages;

  return (
    <NewsBlock
      isLoading={isLoading}
      tagId={'all'}
      newsList={buildArticlesList(
        docsArray,
        currentLocale,
        locales,
        jurisdictionList,
        jurisdictionItem?.slug,
      )}
      emptyText={emptyText}
      pagination={
        Number(totalPages) >= 2 && (
          <ReactPaginate
            pageCount={totalPages || 0}
            breakLabel='...'
            previousLabel={null}
            nextLabel={null}
            forcePage={page - 1}
            activeLinkClassName='bg-brand-500 hover:bg-brand-550 text-brand-50'
            containerClassName='flex justify-center gap-2 mt-16 text-sm font-medium leading-normal'
            pageLinkClassName='flex items-center justify-center h-8 w-10'
            pageClassName='bg-control-100 text-control-950 hover:bg-brand-550 hover:text-brand-50 overflow-hidden rounded-xl transition'
            breakLinkClassName={clsx(
              'flex h-8 w-10 items-center justify-center',
              page === 1 &&
                'rounded-xl bg-control-100 transition hover:bg-brand-550',
            )}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        )
      }
    />
  );
};
export default NewsAuthorsBlockConnected;
