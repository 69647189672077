import { ESectionVariant, ContentSection } from '@front/shared/ds';
import React from 'react';

import { BlockWithRows } from './BlockWithRows';
import { SectionProvider } from '../SectionContext';

import type { TSectionWrapperProps } from '../types';

export const SectionWrapper: React.FC<TSectionWrapperProps> = props => {
  const { sectionVariant, children } = props;

  let content;

  if (sectionVariant === ESectionVariant.BlockWithRows) {
    content = <BlockWithRows {...props}>{children}</BlockWithRows>;
  } else {
    content = <ContentSection {...props}>{children}</ContentSection>;
  }

  return <SectionProvider sectionProps={props}>{content}</SectionProvider>;
};
