import { BlogArticle } from '@shared/master-types';

import buildHashId from './buildHashId';
import buildStringFromRichTextRow from './buildStringFromRichTextRow';
import { TRichTextContent } from '../components/atoms/RichText';

const makeUniqueText = (text: string, texts: string[]): string => {
  let uniqueText = text;
  let index = 1;
  while (texts.indexOf(uniqueText) !== -1) {
    uniqueText = `${text}-${index}`;
    index++;
  }
  return uniqueText;
};
const populateUniqueTitleIds = (
  content: TRichTextContent | undefined,
  texts: string[] = [],
): TRichTextContent => {
  if (typeof content === 'undefined') {
    return [];
  }
  return content.map(item => {
    if ('type' in item && typeof item.type === 'string') {
      const preparedText =
        'children' in item && Array.isArray(item.children)
          ? buildStringFromRichTextRow(item.children)
          : '';
      const uniqueText = makeUniqueText(preparedText, texts);
      texts.push(uniqueText);
      if (['h2', 'h3', 'h4', 'h5'].includes(item.type)) {
        return {
          ...item,
          id: buildHashId(uniqueText),
        };
      }
    }
    return item;
  });
};

export const populateUniqueTitleIdsForRows = (
  rows: BlogArticle['rows'] | undefined,
  texts: string[] = [],
): BlogArticle['rows'] | undefined => {
  if (typeof rows === 'undefined') {
    return;
  }
  return rows.map(row => {
    if (
      'blockType' in row &&
      row.blockType === 'title' &&
      'title' in row &&
      Array.isArray(row.title)
    ) {
      const updatedTitle = row.title.map(item => {
        if ('type' in item && typeof item.type === 'string') {
          const preparedText =
            'children' in item && Array.isArray(item.children)
              ? buildStringFromRichTextRow(item.children)
              : '';
          const uniqueText = makeUniqueText(preparedText, texts);
          texts.push(uniqueText);
          if (['h2', 'h3', 'h4', 'h5'].includes(item.type)) {
            return {
              ...item,
              id: buildHashId(uniqueText),
            };
          }
        }
        return item;
      });

      return {
        ...row,
        title: updatedTitle,
      };
    }
    return row;
  });
};
export default populateUniqueTitleIds;
