import axios, { AxiosError } from 'axios';
import { NextApiRequest, NextApiResponse } from 'next';
import pino from 'pino';

const logger = pino({
  name: '[CROP MANAGER PROXY]',
  level: process.env.LOG_LEVEL || 'info',
});
const CROP_MANAGER_HOST = process.env.CROP_MANAGER_HOST;

const SUPPORTED_VIDEO_FORMATS = ['mp4'];

export async function cropManagerHandler(
  req: NextApiRequest,
  res: NextApiResponse,
): Promise<void> {
  if (req.method !== 'POST') {
    logger.warn('Invalid request method: ', req.method);
    res.status(404).send('Not Found');
    return;
  }

  if (!CROP_MANAGER_HOST) {
    logger.warn('env CROP_MANAGER_HOST not available');
    res.status(404).send('Not Found');
    return;
  }

  const { path } = req.body;
  const isVideo = SUPPORTED_VIDEO_FORMATS.some(format => path.endsWith(format));

  const targetUrl = isVideo
    ? `${CROP_MANAGER_HOST}/api/video`
    : `${CROP_MANAGER_HOST}/api/image`;

  logger.debug('Proxy [POST]: ', req.body);

  try {
    const response = await axios.post(targetUrl, req.body, {
      params: req.query,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'stream',
    });

    for (const [key, value] of Object.entries(response.headers)) {
      res.setHeader(key, value);
    }

    res.status(response.status);
    response.data.pipe(res);
  } catch (err) {
    logger.error(err, 'Failed to crop');
    res.status(500).send((err as AxiosError)?.message);
  }
}
