import { EActionType, TAction } from '@front/shared/ds';
import { ICheckActionProps, checkTAction } from '@front/shared/utils';

export interface IAddJurisdictionSupportItemProps
  extends Omit<ICheckActionProps, 'item'> {
  item: TAction;
}
export interface IAddJurisdictionSupportProps
  extends Omit<ICheckActionProps, 'item'> {
  actionList: TAction[];
}

export function addJurisdictionSupportItem(
  props: IAddJurisdictionSupportItemProps,
): TAction {
  const { item, jurisdictionList, localeList, slug } = props;
  return checkTAction({ item, jurisdictionList, localeList, slug }) ?? item;
}

export function addJurisdictionSupport(
  props: IAddJurisdictionSupportProps,
): TAction[] {
  const { actionList, jurisdictionList, localeList, slug } = props;

  return actionList.map(item => {
    if (item?.type === EActionType.LinksGroupType) {
      const newitem = {
        ...item,
        actions: item.actions?.map(subItem => {
          return (
            checkTAction({
              item: subItem,
              jurisdictionList,
              localeList,
              slug,
            }) ?? subItem
          );
        }),
      };
      return newitem ?? item;
    }
    return checkTAction({ item, jurisdictionList, localeList, slug }) ?? item;
  });
}
