import { baseApi } from '../../store/store.constants';

import type { Locale } from '@shared/master-types';
import type { PaginatedDocs } from 'payload/dist/mongoose/types';
import type { Where } from 'payload/types';

const localesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getLocales: build.query<
      PaginatedDocs<Locale>,
      {
        depth?: number;
        locale?: string;
        page?: number;
        limit?: number;
        draft?: boolean;
        where?: Where;
      } | void
    >({
      query: params => ({
        url: '/locales',
        params: { ...(params || {}), limit: params?.limit || 0 },
      }),
    }),
  }),
});

export const {
  endpoints: { getLocales },
  useGetLocalesQuery,
} = localesApi;
