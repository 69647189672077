import { SectionError } from '@front/shared/ds/SectionError';
import { NextPageContext } from 'next/types';
import React from 'react';

import { usePublicRuntimeConfig } from '../../../config';
import { useGetSettingsQuery } from '../../../features';
import { useLocale } from '../../../hooks';
import { PageLayout } from '../../layout/PageLayout';

export type TCustomErrorPageProps = {
  statusCode: number;
};

export const CustomErrorPage = (
  props: TCustomErrorPageProps,
): React.JSX.Element => {
  const { statusCode } = props;

  const { locale, isLocaleLoading } = useLocale();

  const { data: settings } = useGetSettingsQuery(
    { locale },
    { skip: isLocaleLoading },
  );

  const { errorPage } = settings ?? {};

  usePublicRuntimeConfig();

  return (
    <PageLayout pageId={`${statusCode}`} locale={locale}>
      <SectionError
        statusCode={statusCode}
        darkTheme={errorPage?.isDarkErrorPage}
        errorText={statusCode === 404 ? errorPage?.errorText : undefined}
        buttonText={errorPage?.buttonText}
      />
    </PageLayout>
  );
};

CustomErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};
