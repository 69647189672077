import { BlogArchive } from '@shared/master-types';

import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Where } from 'payload/types';

import { baseApi } from '../../store/store.constants';

export const blogArchivesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogArchives: build.query<
      PaginatedDocs<BlogArchive>,
      {
        locale?: string;
        depth?: number;
        draft?: boolean;
        page?: number;
        limit?: number;
        where?: Where;
      } | void
    >({
      query: params => ({
        url: '/blog-archives',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogArchives },
  useGetBlogArchivesQuery,
} = blogArchivesApi;
