import {
  EButtonIconPosition,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  TBannerCardProps,
  toImageProps,
  toLinkProps,
} from '@front/shared/ds';

import { setCurrentModalId } from '../features/modals/modals.state';

import type { THorizontalCardsV3ConnectedProps } from '../components/blocks/HorizontalCardsV3Connected/HorizontalCardsV3Connected';
import type { TAppDispatch } from '../store/store.types';

export default function toCardButtons(
  buttons: NonNullable<
    THorizontalCardsV3ConnectedProps['cards']
  >[number]['buttons'],
  locale: string,
  dispatch: TAppDispatch,
): TBannerCardProps['buttons'] {
  return (buttons || []).map(button => {
    if (button.type === 'button') {
      return {
        children: button.button?.text ?? '',
        ...(button.iconPosition === EButtonIconPosition.Left
          ? { leadingIcon: toImageProps(button?.icon) || undefined }
          : { trailingIcon: toImageProps(button?.icon) || undefined }),
        onClick: () => {
          if (
            button.button?.action === 'modal' &&
            button.button?.modal?.reference.value
          ) {
            return dispatch(
              setCurrentModalId({
                modalId: String(button.button?.modal?.reference.value),
              }),
            );
          }
          return undefined;
        },
        size:
          button?.variant === EButtonVariant.Image
            ? undefined
            : (button?.size as EButtonSize) || EButtonSize.Small,
        variant:
          (button?.variant as EButtonVariant) || EButtonVariant.Contained,
        theme: button?.color as EButtonTheme,
        'data-ga-id': button?.dataGaId,
      };
    } else {
      return {
        ...toLinkProps(String(locale), button.link),
        disabled: button?.link?.disabled,
        children: button?.link?.text,
        size: (button?.size as EButtonSize) || EButtonSize.Small,
        variant: (button?.variant as EButtonVariant) || EButtonVariant.Text,
        theme: button?.color as EButtonTheme,
        onClick: undefined,
        ...(button.iconPosition === EButtonIconPosition.Left
          ? { leadingIcon: toImageProps(button?.icon) || undefined }
          : { trailingIcon: toImageProps(button?.icon) || undefined }),
        'data-ga-id': button?.dataGaId,
      };
    }
  });
}
