import {
  BlogArticle,
  BlogTag,
  Jurisdiction,
  Locale,
  User,
} from '@shared/master-types';
import Link from 'next/link';
import React from 'react';

import { TAction } from '../../../types';
import {
  toImageProps,
  buildTagCategoryLink,
  buildTactionLinkWithJurisdictionSupport,
  EPageTypes,
} from '../../../utils';
import { ArticleThumbnail } from '../../atoms/ArticleThumbnail';
import { Container } from '../../sections/Container';

export interface IBlogArticleExtended extends BlogArticle {
  currentLocale: string;
  jurisdictionList?: Jurisdiction[];
  locales?: Locale[];
  slug?: string;
}

export interface IRecentArticlesProps {
  selectedArticles: IBlogArticleExtended[];
  recentLinks?: TAction[];
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
}

const RecentArticles: React.FC<IRecentArticlesProps> = ({
  selectedArticles,
  recentLinks,
}) => {
  return (
    <Container>
      <div className='grid gap-10 md:grid-cols-2 md:gap-4 xl:grid-cols-4'>
        {selectedArticles?.map(currentArticle => {
          const {
            id,
            cover,
            title,
            url: defaultUrl,
            createdAt,
            publishedAt,
            tags,
            authors,
            currentLocale,
            jurisdictionList,
            locales,
            slug,
          } = currentArticle;

          const currentArticleTag = (
            tags as unknown as {
              value: BlogTag;
            }[]
          ).map(tag => {
            return {
              id: tag.value.id,
              children: tag.value.name,
              href: buildTagCategoryLink(currentLocale, slug, tag.value.slug),
            };
          });

          const currentArticleAuthor = (authors as unknown as User[]).map(
            author => ({
              name: `${author.firstName} ${author.lastName}`,
            }),
          );

          const preparedUrl = buildTactionLinkWithJurisdictionSupport(
            title,
            EPageTypes.BlogArticles,
            currentLocale,
            defaultUrl,
            slug,
            locales,
            jurisdictionList,
          );
          return (
            <ArticleThumbnail
              key={id}
              cover={toImageProps(cover)}
              title={title}
              url={preparedUrl}
              publishedAt={publishedAt || createdAt}
              tags={currentArticleTag}
              authors={currentArticleAuthor}
              aspectRatio
            />
          );
        })}
      </div>
      {recentLinks?.length && (
        <div className='mt-8 w-full text-center 2xl:mt-12'>
          {recentLinks?.map(
            link =>
              link.type === 'link' && (
                <Link
                  key={String(link.props.children)}
                  {...link.props}
                  className='mb-2 flex justify-center rounded-xl bg-surface-100 p-5 text-sm font-medium leading-normal text-black transition first:mb-0 hover:bg-control-150 aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
                />
              ),
          )}
        </div>
      )}
    </Container>
  );
};

export default RecentArticles;
