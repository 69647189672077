import { isSSR } from '../';

declare const window: Window &
  typeof globalThis & {
    NEXT_APP_HOST: string | undefined;
  };

export const getAppHost = (): string | undefined => {
  if (isSSR()) {
    return process.env.PROXY_HOST
      ? process.env.PROXY_HOST
      : process.env.NEXT_APP_HOST;
  }

  return window.NEXT_APP_HOST;
};
