import { TRichTextContent } from '../RichText';
import { TLeaf } from '../types';

export const processRichText = (
  contentRichText: TRichTextContent | TLeaf | TLeaf[] | undefined,
): TLeaf[] => {
  const contentArray = contentRichText
    ? (Array.isArray(contentRichText)
        ? contentRichText
        : [contentRichText]
      ).filter(Boolean)
    : [];

  return contentArray as TLeaf[];
};
