import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { EModalBackground, EModalPadding } from './Modal.constants';
import { ReactComponent as IconClose } from '../../../../public/icons/icon-cross.svg';

export interface IModalProps {
  onClose: () => void;
  title?: string;
  padding?: EModalPadding;
  modalBackground?: EModalBackground;
  children?: ReactNode;
}

const Modal: React.FC<IModalProps> = props => {
  const {
    title,
    onClose,
    children,
    padding = EModalPadding.Large,
    modalBackground = EModalBackground.Light,
  } = props;

  return (
    <div
      className={clsx(
        'fixed inset-x-0 top-full flex max-h-full w-full animate-translate-up flex-col items-center gap-6 overflow-hidden overscroll-contain rounded-t-3xl outline-none 2xl:relative 2xl:top-0 2xl:max-h-none 2xl:animate-none 2xl:rounded-3xl',
        padding === EModalPadding.Large && 'px-4 py-12 2xl:px-11',
        padding === EModalPadding.Medium && 'px-4 pt-11 2xl:px-8 2xl:pb-8',
        modalBackground === EModalBackground.Light && 'bg-surface-100',
        modalBackground === EModalBackground.Dark && 'bg-control-1000',
        modalBackground === EModalBackground.Anchors && 'bg-surface-50',
        modalBackground === EModalBackground.Transparent && 'bg-transparent',
        modalBackground === EModalBackground.White && 'bg-white',
      )}
    >
      <button
        onClick={onClose}
        className='absolute end-8 top-6 z-10 outline-none 2xl:top-8'
      >
        <IconClose className='aspect-square size-4 text-control-600' />
      </button>
      {!!title && (
        <h2 className='text-center text-6xl font-semibold leading-relaxed text-interface-1000'>
          {title}
        </h2>
      )}
      {React.Children.count(children) !== 0 && (
        <div
          className={clsx(
            'scrollbar-hidden w-full overflow-y-auto scroll-smooth',
            padding === EModalPadding.Medium && 'pb-8 2xl:pb-0',
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Modal;
