import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  RichText,
} from '@front/shared/ds';
import { Form } from '@shared/master-types';
import React from 'react';

import { FormInputParserV2 } from '../../..';
import ConfirmationMessage from '../components/ConfirmationMessage/ConfirmationMessage';
import { useModalForm } from '../hooks/useModalForm.hook';

export type TModalFormProps = {
  form: Form;
  onSubmit?: () => void;
};

const ModalFormV2: React.FC<TModalFormProps> = props => {
  const { form, onSubmit } = props;
  const { register, formState, control, handleSubmit, clearErrors, watch } =
    useModalForm(form);

  return (
    <>
      <form className='flex flex-col' onSubmit={handleSubmit}>
        <div className='flex flex-col gap-2 tablet:gap-4'>
          {form.fields?.map(field => (
            <FormInputParserV2
              key={field.name}
              field={field}
              watch={watch}
              control={control}
              register={register}
              error={formState.errors[field.name]?.message?.toString()}
              hasBorder
              clearErrors={clearErrors}
            />
          ))}
        </div>
        <div className='prose-p:text-black/25 prose-a:text-black/50 flex items-center justify-center py-6 text-center text-base leading-mega-loose text-black/25 tablet:text-lg tablet:leading-relaxed'>
          <RichText content={form.description} />
        </div>
        <Button
          theme={EButtonTheme.Primary}
          size={EButtonSize.Large}
          variant={EButtonVariant.Contained}
          type='submit'
          disabled={!formState.isValid || formState.isSubmitting}
          className='h-16 rounded-lg px-10 text-3xl font-semibold leading-loose disabled:opacity-10 tablet:h-18 desktop:h-[5.5rem]'
        >
          {form.submitButtonLabel || 'Submit'}
        </Button>
      </form>
      <ConfirmationMessage
        onSubmit={onSubmit}
        confirmationType={form.confirmationType}
        confirmationMessage={form.confirmationMessage}
        isSubmitSuccessful={formState.isSubmitSuccessful}
      />
    </>
  );
};

export default ModalFormV2;
