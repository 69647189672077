import { BlogArchive } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogArchive: build.query<
      BlogArchive,
      {
        id: string;
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ id, ...params }) => ({
        url: `/blog-archives/${id}`,
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogArchive },
  useGetBlogArchiveQuery,
} = blogPostsApi;
