import { cva } from 'cva';

export enum EHeroSectionTitlePosition {
  Left = 'left',
  Center = 'center',
}

export enum EHeroSectionTitleTheme {
  Theme1 = 'theme1', // theme numbers should matches design system in figma
  Theme4 = 'theme4',
  Theme5 = 'theme5',

  HeroSectionB3 = 'HeroSectionB3',
  HeroSectionTitle1 = 'HeroSectionTitle1',
  HeroSectionTitle2 = 'HeroSectionTitle2',
  HeroSectionTitle3 = 'HeroSectionTitle3',
  HeroSectionTitle4 = 'HeroSectionTitle4',
  HeroSectionTitle5 = 'HeroSectionTitle5',
  HeroSectionTitle6 = 'HeroSectionTitle6',
}

export enum EHeroSectionContentWidth {
  Sm = 'Sm',
  Md = 'Md',
  Lg = 'Lg',
  Xl = 'Xl',
  '2Xl' = '2Xl',
  Theme5 = 'theme5',
}

export enum EHeroSectionPadding {
  None = 'None',
  Top = 'Top',
  Bottom = 'Bottom',
  TopAndBottom = 'TopAndBottom',
}

export const eyebrowsCVA = cva('', {
  variants: {
    theme: {
      [EHeroSectionTitleTheme.HeroSectionTitle1]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle2]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle3]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle4]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle5]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle6]: '',
      [EHeroSectionTitleTheme.Theme1]: '',
      [EHeroSectionTitleTheme.Theme4]: '',
      [EHeroSectionTitleTheme.Theme5]: '',
      [EHeroSectionTitleTheme.HeroSectionB3]: '',
    },
  },
  compoundVariants: [
    {
      theme: [
        EHeroSectionTitleTheme.HeroSectionTitle1,
        EHeroSectionTitleTheme.HeroSectionTitle2,
        EHeroSectionTitleTheme.HeroSectionTitle3,
      ],
      className: 'text-interface-50',
    },
  ],
});

export const heroTitleCVA = cva('', {
  variants: {
    theme: {
      [EHeroSectionTitleTheme.HeroSectionTitle1]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle2]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle3]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle4]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle5]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle6]: '',
      [EHeroSectionTitleTheme.Theme1]: '',
      [EHeroSectionTitleTheme.Theme4]: '',
      [EHeroSectionTitleTheme.Theme5]: '',
      [EHeroSectionTitleTheme.HeroSectionB3]: '',
    },
  },
  compoundVariants: [
    {
      theme: [
        EHeroSectionTitleTheme.HeroSectionTitle1,
        EHeroSectionTitleTheme.HeroSectionTitle2,
      ],
      className: 'text-interface-1000',
    },
    {
      theme: [EHeroSectionTitleTheme.HeroSectionTitle3],
      className: 'text-interface-50',
    },
  ],
});

export const descriptionCVA = cva('', {
  variants: {
    theme: {
      [EHeroSectionTitleTheme.HeroSectionTitle1]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle2]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle3]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle4]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle5]: '',
      [EHeroSectionTitleTheme.HeroSectionTitle6]: '',
      [EHeroSectionTitleTheme.Theme1]: '',
      [EHeroSectionTitleTheme.Theme4]: '',
      [EHeroSectionTitleTheme.Theme5]: '',
      [EHeroSectionTitleTheme.HeroSectionB3]: '',
    },
  },
  compoundVariants: [
    {
      theme: [
        EHeroSectionTitleTheme.HeroSectionTitle1,
        EHeroSectionTitleTheme.HeroSectionTitle2,
      ],
      className: 'text-interface-800',
    },
    {
      theme: [EHeroSectionTitleTheme.HeroSectionTitle3],
      className: 'text-interface-50/80',
    },
  ],
});
