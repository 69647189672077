import React from 'react';

import { ReactComponent as IconClock } from '../../../../public/icons/icon-clock.svg';

export type TReadingTimeProps = {
  readingTime: number;
  readingTimeMinute?: string;
};

const ReadingTime: React.FC<TReadingTimeProps> = props => {
  const { readingTime, readingTimeMinute } = props;
  return (
    <div className='flex items-center gap-1 text-xs font-medium leading-relaxed text-interface-500'>
      <IconClock className='size-3' />
      {readingTime}
      {readingTimeMinute}
    </div>
  );
};

export default ReadingTime;
