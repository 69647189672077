export const getMultiselectValue = (
  prev: string[],
  value: string,
  remove?: boolean,
): string[] => {
  if (prev.includes(value)) {
    // if exists - unselect
    if (remove) {
      return prev.filter(item => item !== value);
    } else {
      return prev;
    }
  }

  return [...prev, value];
};

export const getSelectValue = (
  prev: string[],
  value: string,
  remove?: boolean,
): string[] => {
  if (prev.includes(value) && remove) {
    return [];
  }

  return [value];
};

export const getMultiselectInputValue = (
  options: { value: string }[],
  prev: string[],
  value: string,
): string[] => {
  let nextValues = prev.filter(v => options.map(o => o.value).includes(v));

  if (value === '') {
    return nextValues;
  }

  nextValues = [...nextValues, value];

  return nextValues;
};

export const getInputValue = (
  options: { value: string }[],
  prev: string[],
): string => {
  const values = prev.filter(v => !options.map(o => o.value).includes(v));

  if (values.length === 0) {
    return '';
  }

  return values[0];
};
