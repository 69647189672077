import clsx from 'clsx';
import React from 'react';

import {
  EDefaultCardsAlign,
  EDefaultCardsColumns,
  EDefaultCardsHorizontalScroll,
} from './DefaultCards.constants';
import { useDragScrolling } from '../../../hooks/useDragScrolling';
import { EEyebrowSize } from '../../atoms/Eyebrow';
import Container from '../../sections/Container/Container';
import DefaultCard from '../DefaultCard/DefaultCard';

export interface IDefaultCardsProps {
  banners: React.ComponentProps<typeof DefaultCard>[];
  align: EDefaultCardsAlign | string;
  columns: EDefaultCardsColumns | string;
  horizontalScroll: EDefaultCardsHorizontalScroll | string;
}

const DefaultCards: React.FC<IDefaultCardsProps> = props => {
  const { banners, align, columns, horizontalScroll } = props;

  const { scrollableRef, bind } = useDragScrolling({
    enabled: horizontalScroll !== EDefaultCardsHorizontalScroll.Disabled,
  });

  return (
    <section
      ref={scrollableRef}
      className={clsx(
        'scrollbar-hidden flex',
        horizontalScroll === EDefaultCardsHorizontalScroll.Enabled &&
          'overflow-auto',
        horizontalScroll === EDefaultCardsHorizontalScroll.Mobile &&
          'overflow-auto xl:overflow-hidden',
        horizontalScroll === EDefaultCardsHorizontalScroll.Desktop &&
          'xl:overflow-auto',
      )}
      {...bind()}
    >
      <Container>
        <div
          className={clsx(
            '-mx-2 flex items-stretch gap-y-4',
            align === EDefaultCardsAlign.Start && 'justify-start',
            align === EDefaultCardsAlign.End && 'justify-end',

            align === EDefaultCardsAlign.Center &&
              horizontalScroll === EDefaultCardsHorizontalScroll.Disabled &&
              'justify-center',
            align === EDefaultCardsAlign.Center &&
              horizontalScroll === EDefaultCardsHorizontalScroll.Mobile &&
              'xl:justify-center',
            align === EDefaultCardsAlign.Center &&
              horizontalScroll === EDefaultCardsHorizontalScroll.Desktop &&
              'justify-center xl:justify-start',

            horizontalScroll === EDefaultCardsHorizontalScroll.Disabled &&
              'flex-wrap',
            horizontalScroll === EDefaultCardsHorizontalScroll.Enabled &&
              'flex-nowrap',
            horizontalScroll === EDefaultCardsHorizontalScroll.Mobile &&
              'flex-nowrap xl:flex-wrap',
            horizontalScroll === EDefaultCardsHorizontalScroll.Desktop &&
              'flex-wrap xl:flex-nowrap',
          )}
        >
          {banners.map((banner, i) => (
            <div
              key={i}
              className={clsx(
                'min-w-[95%] basis-full px-2',
                banners.length > 2 ? 'md:min-w-[49%]' : 'md:min-w-[50%]',
                'md:basis-1/2',
                columns === EDefaultCardsColumns.Three &&
                  'xl:min-w-[33.33%] xl:basis-1/3',
                columns === EDefaultCardsColumns.Four &&
                  '2xl:min-w-[25%] 2xl:basis-1/4',
              )}
            >
              <DefaultCard
                eyeBrowTitleSize={EEyebrowSize.ExtraSmall}
                {...banner}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default DefaultCards;
