import { DEFAULT_LOCALE, DEFAULT_JURISDICTION } from '../../../consts';

export type TLinkParam = {
  locale?: string;
  jurisdiction?: string;
};
export default function toLinkParam(props: TLinkParam): string {
  const { locale = DEFAULT_LOCALE, jurisdiction = DEFAULT_JURISDICTION } =
    props;

  const currentLocale = locale !== DEFAULT_LOCALE ? locale : '';
  const currentJurisdiction =
    jurisdiction !== DEFAULT_JURISDICTION ? jurisdiction : '';

  const linkParamArray = [];
  currentLocale && linkParamArray.push(`${currentLocale}`);
  currentJurisdiction && linkParamArray.push(`${currentJurisdiction}`);

  return linkParamArray.join('/');
}
