import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  ComponentType,
} from 'react';

export type TSectionAnimationContext = {
  isSectionAnimationSequenceReady: boolean;
  setSectionAnimationSequenceReady: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export const SectionAnimationContext =
  createContext<TSectionAnimationContext | null>(null);

export const useSectionAnimationContext =
  (): TSectionAnimationContext | null => {
    return useContext(SectionAnimationContext);
  };

type TServerContextDataProviderProps = {
  isSectionAnimationReady: boolean;
  children: ReactNode;
};
export const SectionAnimationProvider: ComponentType<
  TServerContextDataProviderProps
> = ({ isSectionAnimationReady, children }) => {
  const [isSectionAnimationSequenceReady, setSectionAnimationSequenceReady] =
    useState(isSectionAnimationReady);

  const contextValue = {
    isSectionAnimationSequenceReady,
    setSectionAnimationSequenceReady,
  };

  return (
    <SectionAnimationContext.Provider value={contextValue}>
      {children}
    </SectionAnimationContext.Provider>
  );
};
