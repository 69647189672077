import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  RichText,
} from '@front/shared/ds';
import { Form } from '@shared/master-types';
import React from 'react';

import { FormInputParser } from '../../..';
import { EFormInputParserVariant } from '../../../sections/FormInputParser';
import ConfirmationMessage from '../components/ConfirmationMessage/ConfirmationMessage';
import { useModalForm } from '../hooks/useModalForm.hook';

export type TModalFormProps = {
  form: Form;
  additionalText?: {
    [k: string]: unknown;
  }[];
  onSubmit?: () => void;
  inputTheme?: EFormInputParserVariant;
};

const ModalForm: React.FC<TModalFormProps> = props => {
  const { form, inputTheme, additionalText, onSubmit } = props;
  const { register, formState, control, handleSubmit } = useModalForm(form);

  return (
    <>
      <form className='flex flex-col gap-3' onSubmit={handleSubmit}>
        {form.fields?.map(field => (
          <FormInputParser
            key={field.name}
            field={field}
            control={control}
            variant={inputTheme}
            register={register}
            error={formState.errors[field.name]?.message?.toString()}
          />
        ))}
        <Button
          theme={EButtonTheme.Primary}
          size={EButtonSize.Large}
          variant={EButtonVariant.Contained}
          type='submit'
          disabled={!formState.isValid || formState.isSubmitting}
        >
          {form.submitButtonLabel || 'Submit'}
        </Button>
      </form>
      <ConfirmationMessage
        onSubmit={onSubmit}
        confirmationType={form.confirmationType}
        confirmationMessage={form.confirmationMessage}
        isSubmitSuccessful={formState.isSubmitSuccessful}
      />

      <div className='prose-a:underline mt-3 text-xs font-normal text-interface-500'>
        <RichText content={additionalText} />
      </div>
    </>
  );
};

export default ModalForm;
