const enumValueChecker =
  <T extends object>(e: T) =>
  (value: unknown): value is T[keyof T] =>
    Object.values(e).includes(value as T[keyof T]);

export const toEnum = <E extends object>(e: E, value?: string) => {
  const isValidEnumValue = enumValueChecker(e);
  const isValid = isValidEnumValue(value);
  return isValid ? value : undefined;
};
