export const enum EContainerVariant {
  Small = 'Small',
  Default = 'Default',
  Lead = 'Lead',
  DoubleLead = 'DoubleLead',
  Full = 'Full',
  Main = 'Main',
}

export const enum EContainerBackground {
  Transparent = 'Transparent',
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
}
