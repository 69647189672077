import React, { ComponentProps } from 'react';

import RichText, { TRichTextContent } from '../RichText';
import isRichTextContentEmpty from './checkEmptyRichText';

export const toRichText = (
  value?: TRichTextContent,
  extraProps?: Partial<ComponentProps<typeof RichText>>,
) => {
  return value && !isRichTextContentEmpty(value) ? (
    <RichText content={value} {...extraProps} />
  ) : undefined;
};
