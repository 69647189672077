import { Footer } from '@shared/master-types';

import { useGetFooterQuery } from '../features/layout/index';

const useActiveFooter = (
  locale: string,
  jurisdictionFooter?: Footer | string,
): Footer | undefined | string => {
  const { data: footerDefaultObject } = useGetFooterQuery({ locale });

  if (jurisdictionFooter) {
    return jurisdictionFooter;
  }
  const footerDocs = footerDefaultObject?.docs || [];
  const activeFooter = footerDocs.find(footer => footer.isActive);
  return activeFooter ?? footerDocs[footerDocs.length - 1];
};

export default useActiveFooter;
