import { FormSubmission } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const formsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    postFormSubmission: build.mutation<
      { doc: FormSubmission; message: string },
      Pick<FormSubmission, 'form' | 'submissionData'>
    >({
      query: body => ({
        url: '/form-submissions',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  endpoints: { postFormSubmission },
  usePostFormSubmissionMutation,
} = formsApi;
