import { NextApiRequest, NextApiResponse } from 'next';

// noinspection JSUnusedGlobalSymbols
export const apiDraftHandler = (
  req: NextApiRequest,
  res: NextApiResponse<string>,
): void => {
  // Check the secret and next parameters
  // This secret should only be known to this API route and the CMS
  if (req.query.secret !== process.env.NEXT_PREVIEW_TOKEN || !req.query.url) {
    return res.status(401).json(JSON.stringify({ message: 'Invalid token' }));
  }

  // Enable Preview Mode by setting the cookies
  res.setPreviewData(
    {
      cookies: {
        'payload-token': req.cookies['payload-token'] || req.query.token,
      },
    },
    {
      // reset Preview cookies ASAP, so user will see a real page after reloading
      maxAge: 30, // seconds
    },
  );

  // Redirect to the path from the fetched post
  // We don't redirect to req.query.slug as that might lead to open redirect vulnerabilities
  res.redirect(String(req.query.url));
};
