import { cva, cx } from 'cva';
import React from 'react';

import { ETabSize, ETabTheme } from './Tab.constants';

export type TTabProps = {
  value: string;
  label: string;
  prefix?: React.JSX.Element | null;
  suffix?: React.JSX.Element | null;
  size: ETabSize;
  theme: ETabTheme;
  onClick: (value: string) => void;
  isActive?: boolean;
  className?: string;
};

export const tabOptionCVA = cva(
  'flex snap-center items-center gap-2.5 transition',
  {
    variants: {
      size: {
        [ETabSize.Small]: '',
        [ETabSize.Medium]: '',
        [ETabSize.Large]: '',
      },
      theme: {
        [ETabTheme.Contained]: '',
        [ETabTheme.Outline]: '',
        [ETabTheme.Text]: '',
      },
      isActive: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        theme: [ETabTheme.Contained, ETabTheme.Outline],
        size: ETabSize.Small,
        className: 'rounded-xl px-4 py-2 text-sm font-medium leading-normal',
      },
      {
        theme: [ETabTheme.Contained, ETabTheme.Outline],
        size: ETabSize.Medium,
        className:
          'rounded-xl px-6 py-3.5 text-base font-medium leading-relaxed',
      },
      {
        theme: [ETabTheme.Contained, ETabTheme.Outline],
        size: ETabSize.Large,
        className: 'rounded-xl px-8 py-4 text-base font-medium leading-relaxed',
      },
      {
        theme: [ETabTheme.Text],
        size: ETabSize.Small,
        className: 'text-sm font-medium leading-normal',
      },
      {
        theme: [ETabTheme.Text],
        size: ETabSize.Medium,
        className: 'text-base font-medium leading-relaxed',
      },
      {
        theme: [ETabTheme.Text],
        size: ETabSize.Large,
        className: 'text-lg font-medium leading-normal',
      },
      {
        isActive: false,
        theme: ETabTheme.Contained,
        className: 'bg-control-100 text-interface-1000 hover:bg-control-150',
      },
      {
        isActive: true,
        theme: ETabTheme.Contained,
        className: 'bg-brand-500 text-brand-50',
      },
      {
        isActive: false,
        theme: ETabTheme.Outline,
        className: 'border border-brand-500 text-brand-500',
      },
      {
        isActive: true,
        theme: ETabTheme.Outline,
        className: 'bg-brand-500 text-brand-50',
      },
      {
        isActive: false,
        theme: ETabTheme.Text,
        className: 'text-control-600 hover:text-brand-500',
      },
      {
        isActive: true,
        theme: ETabTheme.Text,
        className: 'text-brand-500 hover:text-brand-550',
      },
    ],
    defaultVariants: {
      isActive: false,
    },
  },
);

const Tab: React.FC<TTabProps> = props => {
  const {
    value,
    label,
    isActive,
    onClick,
    theme,
    size,
    prefix,
    suffix,
    className,
  } = props;

  return (
    <button
      key={value}
      className={cx(tabOptionCVA({ theme, size, isActive }), className)}
      role='tab'
      type='button'
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        (e.target as HTMLElement).scrollIntoView({
          block: 'nearest',
          inline: 'center',
        });
        onClick(value);
      }}
    >
      {prefix}
      {label}
      {suffix}
    </button>
  );
};

export default Tab;
