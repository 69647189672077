import { getGaId } from '@front/shared';
import clsx from 'clsx';
import React from 'react';

import { EActionType } from '../../../../types/actions';
import { Button } from '../../../atoms/Button';
import { TSectionTitleProps } from '../types';

type TButtons = Pick<TSectionTitleProps, 'buttons'>;
type TButtonsProps = TButtons & {
  className: string;
};
const ButtonGroup: React.FC<TButtonsProps> = props => {
  const { buttons, className } = props;

  if (!buttons?.length) {
    return null;
  }

  return (
    <div className={clsx('flex flex-wrap items-center', className)}>
      {buttons.map((action, index) => {
        if (
          action.type === EActionType.ButtonType ||
          action.type === EActionType.LinkType
        ) {
          return (
            <Button
              key={index}
              data-ga-id={getGaId(action.dataGaId, 'action')}
              {...action.props}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default ButtonGroup;
