import { FinanceTable, toImageProps } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TFinanceTableConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'finance-table' }
>;

const FinanceTableConnected: React.FC<TFinanceTableConnectedProps> = props => {
  const {
    tableContent,
    isAnchors,
    sidebarTitle,
    id,
    isLegalTemplate,
    blockImage,
  } = props;

  return (
    <FinanceTable
      sidebarTitle={sidebarTitle}
      id={id}
      blockImage={toImageProps(blockImage, {
        useFill: true,
        desktopVW: '50vw',
      })}
      isLegalTemplate={!!isLegalTemplate}
      isAnchors={!!isAnchors}
      // We can't know content type beforehand because it comes from third party library.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      richText={tableContent}
    />
  );
};
export default FinanceTableConnected;
