import Script, { ScriptProps } from 'next/script';
import React from 'react';

type TGtmTagManagerProps = {
  gtmId: string;
  strategy?: ScriptProps['strategy'];
};

const GtmTagManager: React.FC<TGtmTagManagerProps> = ({
  gtmId,
  strategy = 'lazyOnload',
}) => {
  return (
    <Script id='google-tag-manager' strategy={strategy}>
      {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-${gtmId}');
        `}
    </Script>
  );
};

export default GtmTagManager;
