import clsx from 'clsx';
import React from 'react';

import ButtonGroup from './ButtonGroup';
import { EButtonVariant } from '../../../atoms/Button';
import { TSectionTitleProps } from '../types';

type TButtons = Pick<TSectionTitleProps, 'buttons'>;
type TButtonsProps = TButtons & {
  className: string;
  alignCenter?: boolean;
};
const Buttons: React.FC<TButtonsProps> = props => {
  const { buttons, className, alignCenter = false } = props;

  if (!buttons?.length) {
    return null;
  }
  const textIndex = buttons.findIndex(
    action =>
      'props' in action && action.props?.variant === EButtonVariant.Text,
  );
  const mainButtons = textIndex !== -1 ? buttons.slice(0, textIndex) : buttons;
  const textButtons = textIndex !== -1 ? buttons.slice(textIndex) : [];
  return (
    <div className={clsx(className, alignCenter && 'justify-center')}>
      <ButtonGroup
        buttons={mainButtons}
        className={clsx('gap-4', alignCenter && 'justify-center')}
      />
      <ButtonGroup
        buttons={textButtons}
        className={clsx('gap-6', alignCenter && 'justify-center')}
      />
    </div>
  );
};

export default Buttons;
