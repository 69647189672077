import { BaseApp, reportWebVitals, TBaseAppProps } from '@front/master';
import clsx from 'clsx';
import { Inter_Tight, Playfair } from 'next/font/google';
import React from 'react';
import '../styles/globals.css';

const interTightFont = Inter_Tight({ subsets: ['latin'] });

const playFairFont = Playfair({
  weight: ['400'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-playfair',
});

const App: React.FC<TBaseAppProps> = props => {
  return (
    <BaseApp
      className={clsx(interTightFont.className, playFairFont.variable)}
      {...props}
    />
  );
};

export { reportWebVitals };

export default App;
