import { UrlObject } from 'url';

export const getGaId = (
  key: UrlObject | string = '', // key SHOULD be unique across the namespace
  ...namespaceParts: (string | undefined)[] // namespace SHOULD be unique across the HTML page
): string => {
  const namespace = namespaceParts.filter(Boolean).join('_');

  let value;

  if (typeof key === 'string') {
    value = key;
  } else {
    value = key?.href || '';
  }

  if (!value) {
    return '';
  }

  return (
    ((namespace ? namespace + '_' : '') + value)
      ?.replace(/^.*?:\/\//i, '')
      .replace(/[^\p{L}\p{N}_-]+/giu, '_')
      .replace(/_{2,}/g, '_')
      .toUpperCase() || ''
  );
};
