import Cookies from 'js-cookie';
import { useEffect } from 'react';

export const PUBLIC_RUNTIME_CONFIG_KEY = 'PUBLIC_RUNTIME_CONFIG';

export const usePublicRuntimeConfig = (): void => {
  useEffect(() => {
    const publicRuntimeConfigString = Cookies.get(PUBLIC_RUNTIME_CONFIG_KEY);
    if (!publicRuntimeConfigString) {
      return;
    }
    try {
      const publicRuntimeConfig = JSON.parse(publicRuntimeConfigString);
      Object.entries(publicRuntimeConfig).forEach(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([key, value]) => ((window as any)[key] = value),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);
};
