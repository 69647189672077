import { cva } from 'cva';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  EEyebrowSize,
  EEyebrowVariant,
  EEyebrowTheme,
} from './Eyebrow.constants';

export const eyebrowCVA = cva(
  'inline-flex items-center justify-center text-center font-medium ',
  {
    variants: {
      variant: {
        [EEyebrowVariant.Contained]: '',
        [EEyebrowVariant.Outlined]: 'border border-solid',
        [EEyebrowVariant.Text]: '',
        [EEyebrowVariant.TextTracking]: 'uppercase tracking-[0.24rem]',
      },
      size: {
        [EEyebrowSize.Large]: 'rounded-xl text-lg leading-normal',
        [EEyebrowSize.Medium]: 'rounded-lg text-base leading-relaxed',
        [EEyebrowSize.Small]: 'rounded-lg text-sm leading-normal',
        [EEyebrowSize.ExtraSmall]: 'rounded-lg text-xs leading-relaxed',
      },
      theme: {
        [EEyebrowTheme.Primary]: '',
        [EEyebrowTheme.Light]: '',
        [EEyebrowTheme.Dark]: '',
      },
    },
    compoundVariants: [
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.Large],
        className: 'h-[32px] px-3 py-[5px]',
      },
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.Medium],
        className: 'h-[28px] px-3 py-[4.5px]',
      },
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.Small],
        className: 'h-[26px] px-3 py-[4.5px]',
      },
      {
        variant: [EEyebrowVariant.Contained, EEyebrowVariant.Outlined],
        size: [EEyebrowSize.ExtraSmall],
        className: 'h-[24px] px-3 py-[4.5px]',
      },
      {
        variant: [EEyebrowVariant.Contained],
        theme: [EEyebrowTheme.Primary],
        className: 'bg-brand-500 text-brand-50',
      },
      {
        variant: [EEyebrowVariant.TextTracking],
        theme: [EEyebrowTheme.Primary, EEyebrowTheme.Dark],
        className: 'text-interface-500',
      },
      {
        variant: [EEyebrowVariant.Outlined, EEyebrowVariant.Text],
        theme: [EEyebrowTheme.Primary],
        className: 'border-brand-500 text-brand-500',
      },
      {
        variant: [EEyebrowVariant.Contained],
        theme: [EEyebrowTheme.Light],
        className: 'bg-brand-50 text-brand-500',
      },
      {
        variant: [EEyebrowVariant.Outlined, EEyebrowVariant.Text],
        theme: [EEyebrowTheme.Light],
        className: 'border-brand-50 text-brand-50',
      },
      {
        variant: [EEyebrowVariant.TextTracking],
        theme: [EEyebrowTheme.Light],
        className: 'text-brand-50',
      },
      {
        variant: [EEyebrowVariant.Contained],
        theme: [EEyebrowTheme.Dark],
        className: 'bg-brand-1000 text-brand-50',
      },
      {
        variant: [EEyebrowVariant.Outlined],
        theme: [EEyebrowTheme.Dark],
        className: 'border-brand-1000 text-brand-1000',
      },
      {
        variant: [EEyebrowVariant.Text],
        theme: [EEyebrowTheme.Dark],
        className: 'text-brand-1000',
      },
    ],
  },
);

type TEyebrowProps = {
  children: ReactNode;
  size?: EEyebrowSize;
  variant?: EEyebrowVariant;
  theme?: EEyebrowTheme;
  className?: string;
};

export const Eyebrow: React.FC<TEyebrowProps> = ({
  children,
  size = EEyebrowSize.Large,
  variant = EEyebrowVariant.Contained,
  theme = EEyebrowTheme.Primary,
  className,
}) => {
  return (
    <span className={twMerge(eyebrowCVA({ variant, size, theme }), className)}>
      {children}
    </span>
  );
};
