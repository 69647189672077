import { Locale } from '@shared/master-types';

export interface ICheckLocaleExistProps {
  localeList?: Locale[];
  locale?: string;
}

export default function checkLocaleExist(
  props: ICheckLocaleExistProps,
): boolean {
  const { localeList, locale } = props;

  return (
    (!!locale &&
      !!localeList?.find(localeItem => localeItem.locale === locale)) ??
    false
  );
}
