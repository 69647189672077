import clsx from 'clsx';
import React from 'react';

import { RichText, TRichTextContent } from '../RichText';

export type TTitleProps = {
  title?: TRichTextContent;
  isOpenInNewTab?: boolean;
};

export const Title: React.FC<TTitleProps> = props => {
  const { title, isOpenInNewTab } = props;

  return (
    <div
      className={clsx(
        'break-words font-semibold leading-normal text-interface-1000',
        'prose-h2:pb-4 prose-h2:pt-10 prose-h2:text-5xl',
        'prose-h3:pb-1 prose-h3:pt-5 prose-h3:text-xl',
        'prose-h4:pb-1 prose-h4:pt-5 prose-h4:text-base',
        'prose-h5:pb-1 prose-h5:pt-5 prose-h5:text-base',
        'prose-a:text-brand-500 prose-a:transition',
        'prose-p:py-2 prose-p:text-base prose-p:leading-extra-loose prose-p:text-interface-800',
      )}
    >
      <RichText isOpenInNewTab={isOpenInNewTab} content={title} />
    </div>
  );
};
