import { BlogArticle } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getBlogArticle: build.query<
      BlogArticle,
      {
        id: string;
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ id, ...params }) => ({
        url: `/blog-articles/${id}`,
        params,
      }),
    }),
    updateArticleRating: build.mutation({
      query: ({ id, body }) => ({
        url: `/blog-articles/${id}/rating`,
        method: 'PATCH',
        body: body,
      }),
    }),
  }),
});

export const {
  endpoints: { getBlogArticle },
  useGetBlogArticleQuery,
  useUpdateArticleRatingMutation,
} = blogPostsApi;
