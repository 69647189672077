import {
  BrandCard,
  BrandCards,
  RichText,
  toImageProps,
  ECardWrapperLayout,
  ETagVariant,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TBrandCardsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'brand-cards' }
>;

const BrandCardsConnected: React.FC<TBrandCardsConnectedProps> = props => {
  const { cards = [], ...rest } = props;
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const banners: React.ComponentProps<typeof BrandCard>[] = useMemo(() => {
    return cards.map(card => ({
      ...card,
      icon: toImageProps(card.icon),
      description: <RichText content={card.description} />,
      image: toImageProps(card?.banner?.media),
      tags: card.tags?.map(tag => ({
        children: tag.text,
      })),
      tagsStyle: card.tagsStyle as ETagVariant.Contained | ETagVariant.Text,
      badges: card.badges?.map(badge => ({
        children: badge.text,
      })),
      badgesStyle: card.badgesStyle as ETagVariant.Contained | ETagVariant.Text,
      buttons:
        card.buttons && toCardButtons(card.buttons, currentLocale, appDispatch),
      size: ECardWrapperLayout.BrandCard,
      addons: {
        downloads: {
          appStoreUrl: card.downloads?.appStore,
          googlePlayUrl: card.downloads?.googlePlay,
        },
      },
    }));
  }, [appDispatch, cards, currentLocale]);

  return <BrandCards banners={banners} {...rest} />;
};

export default BrandCardsConnected;
