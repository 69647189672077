import clsx from 'clsx';
import React from 'react';

import {
  descriptionCVA,
  ESectionTitleAlignContent,
  ESectionTitleContentWidth,
  ESectionTitleTheme,
  eyebrowsCVA,
  sectionTitleCVA,
} from './SectionTitle.constants';
import Buttons from './components/Buttons';
import { ContentSectionTitle } from './components/ContentSectionTitle';
import Eyebrows from './components/Eyebrows';
import { TSectionTitleProps } from './types';
import baseTailwindConfig from '../../../../tailwind.config.base';
import { useBetterMediaQuery } from '../../../hooks/useBetterMediaQuery';
import { EEyebrowSize, EEyebrowVariant } from '../Eyebrow';
import { ERichTextDefaultTag } from '../RichText';
import RichText from '../RichText/RichText';
import isRichTextContentEmpty from '../RichText/utils/checkEmptyRichText';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../TitlePart/TitlePart.constants';

export const SectionTitle: React.FC<TSectionTitleProps> = props => {
  const {
    title,
    richTitle,
    animationClasses,
    titleSpacingY,
    topDescription,
    hasTitleMargin = false,
    description,
    buttons,
    eyebrowsTop,
    eyebrowsBottom,
    titleAlignContent,
    titleContentWidth = ESectionTitleContentWidth.Md,
    themeTitle,
  } = props;

  const hasRichTitle = richTitle && !isRichTextContentEmpty(richTitle);
  const isXl = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
  );
  const getTitlePartSize = (): ETitlePartsSize => {
    return isXl ? ETitlePartsSize.XxxxL : ETitlePartsSize.Xl;
  };

  const eyebrowsClassName = eyebrowsCVA({
    theme: themeTitle,
  });

  let titlePartTheme = ETitlePartsTheme.Dark;

  switch (themeTitle) {
    case ESectionTitleTheme.SectionTitle1:
      titlePartTheme = ETitlePartsTheme.Dark;
      break;
    case ESectionTitleTheme.SectionTitle2:
      titlePartTheme = ETitlePartsTheme.Dark;
      break;
    case ESectionTitleTheme.SectionTitle3:
      titlePartTheme = ETitlePartsTheme.Light;
      break;
    case ESectionTitleTheme.SectionTitle4:
      titlePartTheme = ETitlePartsTheme.Light;
      break;
    case ESectionTitleTheme.SectionTitle5:
      titlePartTheme = ETitlePartsTheme.Primary;
      break;
    default:
      break;
  }
  const hasContent =
    title ||
    !!eyebrowsTop?.length ||
    !!eyebrowsBottom?.length ||
    description ||
    hasRichTitle;

  if (!hasContent && !buttons?.length) {
    return null;
  }

  return (
    <div
      className={sectionTitleCVA({
        hasTitleMargin,
        titleContentWidth,
        titleSpacingY,
        titleAlignContent,
        className: animationClasses,
      })}
    >
      <Eyebrows
        eyebrows={eyebrowsTop}
        variant={EEyebrowVariant.TextTracking}
        className={eyebrowsClassName}
        hasMarginBottom={true}
        size={EEyebrowSize.Medium}
        alignCenter={titleAlignContent === ESectionTitleAlignContent.Center}
      />
      {title || hasRichTitle ? (
        <ContentSectionTitle
          theme={titlePartTheme}
          size={getTitlePartSize()}
          position={
            titleAlignContent === ESectionTitleAlignContent.Center
              ? ETitlePartsPosition.Center
              : ETitlePartsPosition.Left
          }
        >
          {hasRichTitle ? (
            <RichText
              content={richTitle}
              hasWrapper={false}
              defaultTag={ERichTextDefaultTag.Span}
            />
          ) : (
            title
          )}
        </ContentSectionTitle>
      ) : null}
      <Eyebrows
        eyebrows={eyebrowsBottom}
        variant={EEyebrowVariant.TextTracking}
        className={eyebrowsClassName}
        size={EEyebrowSize.Medium}
        alignCenter={titleAlignContent === ESectionTitleAlignContent.Center}
      />
      {topDescription && !isRichTextContentEmpty(topDescription) ? (
        <RichText
          className={descriptionCVA({
            alignContent: titleAlignContent,
            theme: themeTitle,
          })}
          content={topDescription}
        />
      ) : (
        description && (
          <span
            className={descriptionCVA({
              alignContent: titleAlignContent,
              theme: themeTitle,
            })}
          >
            {description}
          </span>
        )
      )}
      <Buttons
        className={clsx(
          'flex flex-wrap items-center gap-x-8 gap-y-6 empty:hidden',
          {
            'mt-4 md:mt-8': hasContent,
          },
        )}
        alignCenter={titleAlignContent === ESectionTitleAlignContent.Center}
        buttons={buttons}
      />
    </div>
  );
};
