export const CTraderServicesConstants = [
  { colorName: '--color-brand-250', color: '255 168 168' },
  { colorName: '--color-brand-300', color: '255 92 92' },
  { colorName: '--color-brand-500', color: '255 9 0' },
  { colorName: '--color-brand-550', color: '142 0 0 ' },
  { colorName: '--color-accent-500', color: '247 237 237' },
  { colorName: '--color-accent-550', color: '237 247 255' },
  { colorName: '--color-accent-800', color: '250 243 243' },
  { colorName: '--color-accent-850', color: '230 230 230' },
];
