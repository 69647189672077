import clsx from 'clsx';
import React from 'react';

import { RichText, TRichTextContent } from '../RichText';

export type TParagraphProps = {
  paragraph?: TRichTextContent;
  isOpenInNewTab?: boolean;
};

export const Paragraph: React.FC<TParagraphProps> = props => {
  const { paragraph, isOpenInNewTab } = props;

  return (
    <div
      className={clsx(
        'break-words',
        'prose-a:text-brand-500 prose-a:transition',
        'prose-p:py-2 prose-p:text-base prose-p:leading-extra-loose prose-p:text-interface-800',
      )}
    >
      <RichText isOpenInNewTab={isOpenInNewTab} content={paragraph} />
    </div>
  );
};
