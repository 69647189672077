import { Contacts, RichText, toImageProps } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TContactsConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'contacts' }
>;

const ContactsConnected: React.FC<TContactsConnectedProps> = props => {
  const { content } = props;
  const { image, description, contacts = [], socials = {} } = content;

  return (
    <Contacts
      image={toImageProps(image)}
      description={<RichText content={description} />}
      contacts={contacts.map(c => ({
        label: c.label,
        type: c.type,
        value: c.email ?? c.telephone ?? '',
      }))}
      socials={Object.keys(socials).map(k => ({
        type: k,
        link: String(socials[k as keyof typeof socials]),
      }))}
    />
  );
};

export default ContactsConnected;
