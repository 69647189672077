import { Page } from '@shared/master-types';

import { baseApi } from '../../store/store.constants';

export const pageApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getPage: build.query<
      Page,
      {
        id: string;
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ id, depth, ...params }) => ({
        url: `/pages/${id}?depth=${depth || 2}`,
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getPage },
  useGetPageQuery,
} = pageApi;
