import clsx from 'clsx';
import Link from 'next/link';
import Script from 'next/script';
import React, { ComponentProps } from 'react';

import { Container } from '../../sections/Container';

export type TBreadcrumbsProps = {
  items: { url: string; label?: string }[];
  locale: string;
  containerProps?: Partial<ComponentProps<typeof Container>>;
};

const Breadcrumbs: React.FC<TBreadcrumbsProps> = props => {
  const { items, locale, containerProps } = props;

  if (!items.length) {
    return null;
  }

  return (
    <Container {...containerProps}>
      <section className='overflow-hidden pb-4 text-sm font-medium leading-normal text-control-600'>
        <nav aria-label='Breadcrumb' role='navigation'>
          <ol className='flex flex-wrap gap-2'>
            {items.map((item, index) => (
              <React.Fragment key={item.url}>
                <li
                  className={clsx(
                    index === items.length - 1
                      ? 'text-brand-500 transition hover:text-brand-550'
                      : 'hover:text-control-650',
                  )}
                >
                  <Link
                    href={`${locale ? `/${locale}` : ''}${item.url}`}
                    aria-current={index === items.length - 1 ? 'page' : 'false'}
                  >
                    {item.label}
                  </Link>
                </li>
                {index !== items.length - 1 && <span>/</span>}
              </React.Fragment>
            ))}
          </ol>
        </nav>
        <Script
          id='breadcrumbs'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [${JSON.stringify(
            items.map((item, index) => ({
              '@type': 'ListItem',
              position: index + 1,
              name: item.label,
              item: `${process.env.NEXT_PUBLIC_APP_HOST}/${locale}${item.url}`,
            })),
          )}]
        }`,
          }}
        />
      </section>
    </Container>
  );
};

export default Breadcrumbs;
