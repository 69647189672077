import clsx from 'clsx';
import React from 'react';

import { EBrandCardsHorizontalScroll } from './BrandCards.constants';
import Container from '../../sections/Container/Container';
import BrandCard from '../BrandCard/BrandCard';

export interface IBrandCardsProps {
  banners: React.ComponentProps<typeof BrandCard>[];
  horizontalScroll: EBrandCardsHorizontalScroll | string;
}

const BrandCards: React.FC<IBrandCardsProps> = props => {
  const { banners, horizontalScroll } = props;

  return (
    <section
      className={clsx(
        'scrollbar-hidden flex scroll-smooth',
        horizontalScroll === EBrandCardsHorizontalScroll.Enabled &&
          'overflow-auto',
        horizontalScroll === EBrandCardsHorizontalScroll.Mobile &&
          'overflow-auto 2xl:overflow-hidden',
        horizontalScroll === EBrandCardsHorizontalScroll.Desktop &&
          '2xl:overflow-auto',
      )}
    >
      <Container>
        <div
          className={clsx(
            '-mx-2 flex items-stretch gap-y-4',
            horizontalScroll === EBrandCardsHorizontalScroll.Disabled &&
              'flex-wrap',
            horizontalScroll === EBrandCardsHorizontalScroll.Enabled &&
              'flex-nowrap',
            horizontalScroll === EBrandCardsHorizontalScroll.Mobile &&
              'flex-nowrap 2xl:flex-wrap',
            horizontalScroll === EBrandCardsHorizontalScroll.Desktop &&
              'flex-wrap 2xl:flex-nowrap',
          )}
        >
          {banners.map((banner, i) => (
            <div
              key={i}
              className={clsx('mx-2 min-w-[95%] flex-1 2xl:min-w-0')}
            >
              <BrandCard {...banner} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default BrandCards;
