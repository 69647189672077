import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  RichText,
  StepBar,
} from '@front/shared/ds';
import { Form } from '@shared/master-types';
import clsx from 'clsx';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';

import { FormInputParser } from '../../..';
import { EFormInputParserVariant } from '../../../sections/FormInputParser';
import ConfirmationMessage from '../components/ConfirmationMessage/ConfirmationMessage';
import { useModalForm } from '../hooks/useModalForm.hook';

export type TModalMultistepFormProps = {
  form: Form;
  additionalText?: {
    [k: string]: unknown;
  }[];
  onSubmit?: () => void;
  inputTheme?: EFormInputParserVariant;
};

const ModalMultistepForm: React.FC<TModalMultistepFormProps> = props => {
  const { form, inputTheme, additionalText, onSubmit } = props;

  const [currentStepIdx, setCurrentStepIdx] = useState(0);

  const { register, handleSubmit, trigger, clearErrors, formState, control } =
    useModalForm(form);

  const steps: Array<Form['fields']> = useMemo(() => {
    const result = [...new Array(form.steps.length).fill([])];

    form.fields?.forEach(field => {
      if (field?.step) {
        result[field.step - 1] = [...result[field.step - 1], field];
      }
    });

    return result;
  }, [form.fields, form.steps]);

  const stepsOptions = useMemo(() => {
    return form.steps.map((_, i) => ({
      label: `Step ${i + 1}`,
      value: `step-${i + 1}`,
    }));
  }, [form.steps]);

  const currentStep = form.steps[currentStepIdx];
  const currentFields = steps[currentStepIdx];
  const isLastStep = currentStepIdx === steps.length - 1;
  const isValidStep = currentFields?.every(
    field => !formState.errors[field.name]?.message,
  );

  const handleFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      if (!isLastStep) {
        e.preventDefault();
      } else {
        await handleSubmit(e);
      }
    },
    [handleSubmit, isLastStep],
  );

  const handleNextStep = useCallback(async () => {
    const isValid = await trigger(currentFields?.map(field => field.name));

    if (isValid) {
      clearErrors();
      setCurrentStepIdx(currentStepIdx + 1);
    }
  }, [clearErrors, currentFields, currentStepIdx, trigger]);

  const handleTabChange = useCallback(
    (newIndex: number) => {
      // if it's a previous step, we can go back
      if (newIndex < currentStepIdx) {
        setCurrentStepIdx(newIndex);
      }
    },
    [currentStepIdx],
  );

  return (
    <form className='flex flex-col gap-3' onSubmit={handleFormSubmit}>
      <div className='mb-6'>
        <StepBar
          onChange={handleTabChange}
          currentStepIdx={currentStepIdx}
          steps={stepsOptions}
        />
      </div>
      <div
        className={clsx('flex flex-col', {
          'gap-3 xl:min-h-[41.125rem]':
            !currentStep.gap || currentStep.gap === 'sm',
          'gap-6': currentStep.gap === 'md',
          'gap-8 xl:min-h-[41.125rem]': currentStep.gap === 'lg',
        })}
      >
        {currentStep.title && (
          <h2 className='text-lg font-semibold text-interface-1000'>
            {currentStep.title}
          </h2>
        )}

        {currentFields?.map(field => {
          return (
            <FormInputParser
              key={field?.id}
              field={field}
              control={control}
              register={register}
              variant={inputTheme}
              error={formState.errors[field.name]?.message?.toString()}
            />
          );
        })}

        {!isLastStep && (
          <Button
            type='button'
            onClick={handleNextStep}
            theme={EButtonTheme.Secondary}
            size={EButtonSize.Large}
            variant={EButtonVariant.Contained}
            disabled={!isValidStep}
            className='xl:mt-auto'
          >
            {form.nextButtonLabel || 'Next step'}
          </Button>
        )}

        {isLastStep && (
          <>
            <Button
              type='submit'
              theme={EButtonTheme.Primary}
              size={EButtonSize.Large}
              variant={EButtonVariant.Contained}
              disabled={!formState.isValid || formState.isSubmitting}
              className='xl:mt-auto'
            >
              {form.submitButtonLabel || 'Submit'}
            </Button>

            <div className='prose-a:underline mt-3 text-xs font-normal text-interface-500'>
              <RichText content={additionalText} />
            </div>
          </>
        )}

        <ConfirmationMessage
          onSubmit={onSubmit}
          confirmationType={form.confirmationType}
          confirmationMessage={form.confirmationMessage}
          isSubmitSuccessful={formState.isSubmitSuccessful}
        />
      </div>
    </form>
  );
};

export default ModalMultistepForm;
