import { processRichText } from './processRichText';
import { TRichTextContent } from '../RichText';
import { TLeaf } from '../types';

export default function isRichTextContentEmpty(
  content: TLeaf | TLeaf[] | TRichTextContent,
): boolean {
  const preparedContent = processRichText(content);
  return (
    preparedContent?.length === 1 &&
    preparedContent[0]?.children?.length === 1 &&
    preparedContent[0].children[0]?.text === ''
  );
}
