import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { UTM_COOKIE_PARAMS } from '../../../../consts/UTM_COOKIE_PARAMS';

export const useUtmManager = (): void => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    UTM_COOKIE_PARAMS.forEach(utmParam => {
      const paramValue = searchParams.get(utmParam.cookieName);
      if (paramValue) {
        Cookies.set(utmParam.cookieName, paramValue, { expires: 90 });
      }
    });
  }, []);
};
