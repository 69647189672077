import { BlogArticle, Jurisdiction, Locale } from '@shared/master-types';

export interface IBlogArticleExtended extends BlogArticle {
  currentLocale: string;
  jurisdictionList?: Jurisdiction[];
  locales?: Locale[];
  slug?: string;
}

export default function buildArticlesList(
  articlesList: (string | IBlogArticleExtended)[] | BlogArticle[] | undefined,
  currentLocale: string,
  locales?: Locale[],
  jurisdictionList?: Jurisdiction[],
  jurisdiction?: string,
): Array<IBlogArticleExtended> {
  if (!articlesList) {
    console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
    console.warn(
      '%cNo articles provided, check Recent Articles Block selected Articles or Articles Configs in your page',
      'color: red; font-style: italic',
    );
    return [];
  }

  const processedArticles: Array<IBlogArticleExtended> = [];

  for (const article of articlesList) {
    if (typeof article === 'string') {
      console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
      console.warn(
        '%cWrong articles provided, check Recent Articles Block selected Articles or Articles Configs in your page',
        'color: red; font-style: italic',
      );
      continue;
    }

    const {
      id,
      cover,
      title,
      url,
      createdAt,
      publishedAt,
      tags,
      authors,
      proofreaders,
      readingTime,
      parent,
      updatedAt,
      rating,
    } = article;

    if (typeof tags[0] === 'string' || typeof authors[0] === 'string') {
      console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
      console.warn(
        '%cWrong tags or authors provided, check Recent Articles Block or Articles Configs in your page',
        'color: red; font-style: italic',
      );
      continue;
    }

    processedArticles.push({
      id,
      cover,
      title,
      url,
      createdAt,
      publishedAt,
      tags,
      proofreaders,
      authors,
      readingTime,
      parent,
      updatedAt,
      rating,
      currentLocale,
      jurisdictionList,
      locales,
      slug: jurisdiction,
    });
  }

  return processedArticles;
}
