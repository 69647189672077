import { getMediaSrc } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import React, { forwardRef } from 'react';

import { getResponsiveVideoSrc, isVideoCropEnabled } from './utils';

export type TVideoResponsiveProps = {
  video?: Media | string;
} & React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

const SIZES = [640, 1024, 1280];

const VideoResponsive = forwardRef<HTMLVideoElement, TVideoResponsiveProps>(
  ({ video, ...rest }, ref) => {
    const src = getMediaSrc(video);

    if (!src) {
      return null;
    }

    const isCropEnabled = isVideoCropEnabled();
    const mimeType = typeof video === 'string' ? undefined : video?.mimeType;

    return (
      <video key={src} ref={ref} {...rest}>
        {!!isCropEnabled &&
          SIZES.map(size => (
            <source
              key={size}
              src={getResponsiveVideoSrc(src, size)}
              type={mimeType}
              media={`screen and (max-width:${size}px)`}
            />
          ))}
        <source src={src} type={mimeType} />
      </video>
    );
  },
);

VideoResponsive.displayName = 'VideoResponsive';

export default VideoResponsive;
