import { baseApi } from '../../store/store.constants';

import type { Footer } from '@shared/master-types';
import type { PaginatedDocs } from 'payload/dist/mongoose/types';

const layoutApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getFooter: build.query<PaginatedDocs<Footer>, { locale?: string } | void>({
      query: params => ({
        url: '/footer/?depth=1',
        params: params ?? undefined,
      }),
    }),
  }),
});

export const {
  endpoints: { getFooter },
  useGetFooterQuery,
} = layoutApi;
