import { Jurisdiction } from '@shared/master-types';

export interface ICheckJurisdictionExistProps {
  jurisdictionList?: Jurisdiction[];
  jurisdiction?: string;
}

export default function checkJurisdictionExist(
  props: ICheckJurisdictionExistProps,
): boolean {
  const { jurisdictionList, jurisdiction } = props;

  return (
    (!!jurisdiction &&
      !!jurisdictionList?.find(
        jurisdictionItem => jurisdictionItem.slug === jurisdiction,
      )) ??
    false
  );
}
