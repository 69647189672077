import { getGaId } from '@front/shared';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../../atoms/Button';
import { Container, EContainerVariant } from '../../sections/Container';

const IconSomethingWentWrongDark = dynamic(() =>
  import(
    /* webpackChunkName: "IconSomethingWentWrongDark" */ '../../../../public/icons/components/sectionError/something-went-wrong-dark.svg'
  ).then(r => r.ReactComponent),
) as FC<React.SVGProps<SVGSVGElement>>;

const IconSomethingWentWrong = dynamic(() =>
  import(
    /* webpackChunkName: "IconSomethingWentWrong" */ '../../../../public/icons/components/sectionError/something-went-wrong.svg'
  ).then(r => r.ReactComponent),
) as FC<React.SVGProps<SVGSVGElement>>;

const IconSearchLoopDark = dynamic(() =>
  import(
    /* webpackChunkName: "IconSearchLoopDark" */ '../../../../public/icons/components/sectionError/search_loop_dark.svg'
  ).then(r => r.ReactComponent),
) as FC<React.SVGProps<SVGSVGElement>>;

const IconSearchLoop = dynamic(() =>
  import(
    /* webpackChunkName: "IconSearchLoop" */ '../../../../public/icons/components/sectionError/search_loop.svg'
  ).then(r => r.ReactComponent),
) as FC<React.SVGProps<SVGSVGElement>>;

const IconErrorSquare = dynamic(() =>
  import(
    /* webpackChunkName: "IconErrorSquare" */ '../../../../public/icons/components/sectionError/error_square.svg'
  ).then(r => r.ReactComponent),
) as FC<React.SVGProps<SVGSVGElement>>;

export interface ISectionError {
  errorText?: string;
  buttonText?: string;
  statusCode: number;
  darkTheme?: boolean;
}

export const SectionError: React.FC<ISectionError> = props => {
  const { errorText, buttonText, statusCode, darkTheme = false } = props;

  const router = useRouter();

  return (
    <Container
      dataGaId={getGaId(`error_page_${statusCode}`)}
      variant={EContainerVariant.Lead}
      isPadding={false}
    >
      <div
        className={clsx(
          'relative mx-0 mb-12 flex min-h-[21.938rem] flex-col justify-between rounded-4xl px-4 pb-4 pt-[9.75rem] xl:min-h-[31.5rem] xl:py-[8.125rem]',
          {
            'bg-surface-100': !darkTheme,
            'bg-surface-1000': darkTheme,
          },
        )}
      >
        <h1
          className={clsx(
            'mb-8 w-full text-center text-6xl font-semibold leading-relaxed lg:mb-8 xl:text-9xl 2xl:mb-12 2xl:leading-tight',
            {
              'text-interface-1000': !darkTheme,
              'text-interface-50': darkTheme,
            },
          )}
        >
          {statusCode === 404 && <span>Oops... </span>}
          {statusCode}
          <br />
          {errorText}
        </h1>
        {buttonText && (
          <div className={'mx-auto w-full max-w-full xl:max-w-fit'}>
            <Button
              className={'w-full'}
              onClick={() => router.push('/')}
              size={EButtonSize.Large}
              variant={EButtonVariant.Contained}
              theme={darkTheme ? EButtonTheme.Secondary : EButtonTheme.Primary}
            >
              {buttonText}
            </Button>
          </div>
        )}
        {IconErrorSquare && (
          <div
            className={
              'absolute hidden xl:left-10 xl:top-9 xl:block xl:size-[13.1rem]'
            }
          >
            <IconErrorSquare className={'size-full text-brand-500'} />
          </div>
        )}
        <div
          className={
            'absolute right-8 top-[0.438rem] h-[8.125rem] w-40 md:right-40 lg:right-[14.3rem] xl:bottom-10 xl:right-[3.25rem] xl:top-auto xl:h-[12.5rem] xl:w-[15.1rem]'
          }
        >
          {!darkTheme && IconSomethingWentWrong ? (
            <IconSomethingWentWrong className={'size-full text-brand-500'} />
          ) : (
            IconSomethingWentWrongDark && (
              <IconSomethingWentWrongDark
                className={'size-full text-brand-500'}
              />
            )
          )}
        </div>

        <div
          className={
            'absolute left-10 top-[1.688rem] size-[4.375rem] md:left-44 md:h-[5.563.rem] md:w-[5.563rem] lg:left-60 xl:left-auto xl:right-[5.938rem] xl:top-6 xl:size-[9.5rem]'
          }
        >
          {!darkTheme && IconSearchLoop ? (
            <IconSearchLoop className={'size-full text-brand-500'} />
          ) : (
            IconSearchLoopDark && (
              <IconSearchLoopDark className={'size-full text-brand-500'} />
            )
          )}
        </div>
      </div>
    </Container>
  );
};
