import Image, { ImageProps } from 'next/image';
import React from 'react';

export type TCardImageRawProps = {
  text?: string;
  iconPosition?: string;
  icon?: ImageProps | null;
  images: (ImageProps | null)[];
};

const CardImageRaw: React.FC<TCardImageRawProps> = props => {
  const { text, images } = props;

  return (
    <div className='flex flex-row flex-wrap items-center gap-5 pt-7'>
      <span className='text-sm font-medium leading-normal'>{text}</span>
      {!!images?.length && (
        <div className='flex flex-row flex-wrap gap-2 xl:gap-5'>
          {images.map(
            (img, index) =>
              img && (
                <Image
                  key={`${index}-${img?.src}`}
                  {...img}
                  itemProp='image'
                  className='h-9 rounded-xl object-cover'
                />
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default CardImageRaw;
