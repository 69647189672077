import { Author, toImageProps } from '@front/shared/ds';
import { BlogAuthor } from '@shared/master-types';
import React from 'react';

import { useGetSettingsQuery } from '../../../features/settings/getSettings.query';

export type TAuthorConnectedProps = {
  author: BlogAuthor;
};
const AuthorConnected: React.FC<TAuthorConnectedProps> = props => {
  const { author } = props;
  const { data: settings } = useGetSettingsQuery();
  const viewMore = settings?.texts?.viewMore || 'View more';

  const authorSocials = author.socials
    ? Object.keys(author.socials).map(k => ({
        type: k as keyof typeof author.socials,
        link: String(author.socials?.[k as keyof typeof author.socials]),
      }))
    : [];

  return (
    <Author
      key={author.id}
      name={`${author.firstName} ${author.lastName}`}
      href={author.url}
      excerpt={author.excerpt}
      viewMore={viewMore}
      socials={authorSocials}
      avatar={toImageProps(author?.icon)}
    />
  );
};

export default AuthorConnected;
