import { TLeaf } from '../types';

export default function updateNewTabInContentRichText(
  content: TLeaf[],
): TLeaf[] {
  return content.map(node => {
    if (
      node.type === 'link' &&
      node.linkType === 'custom' &&
      node.newTab !== true
    ) {
      return {
        ...node,
        newTab: true,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateNewTabInContentRichText(node.children),
      };
    }
    return node;
  });
}
