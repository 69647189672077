export const enum ETagSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const enum ETagSlug {
  All = 'all',
  News = 'news',
}

export const enum ETagTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  PrimaryOnColour = 'primaryOnColour',
  SecondaryOnColour = 'secondaryOnColour',
}

export const enum ETagVariant {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}
