import React, { ReactNode } from 'react';

import { DefaultPageLayout } from './DefaultPageLayout';
import { SalesforceFormLayout } from './SalesforceFormLayout';

import type { TMetaProps } from '../../../components';
import type { Page } from '@shared/master-types';

export enum EPageLayoutVariant {
  Default = 'default',
  SalesforceForm = 'salesforceForm',
}

type TPageLayoutProps = {
  locale: string;
  draft?: boolean;
  headerDepth?: number;
  pageQueryDepth?: number;
  children: ReactNode;
  meta?: Partial<TMetaProps>;
  breadcrumbs?: Page['breadcrumbs'];
  pageId: string;
  variant?: EPageLayoutVariant;
};

export const PageLayout: React.FC<TPageLayoutProps> = props => {
  const { variant, children } = props;

  if (variant === EPageLayoutVariant.SalesforceForm) {
    return <SalesforceFormLayout {...props}>{children}</SalesforceFormLayout>;
  }

  return <DefaultPageLayout {...props}>{children}</DefaultPageLayout>;
};
