import buildHashId from '../../../../utils/buildHashId';
import buildStringFromRichTextRow from '../../../../utils/buildStringFromRichTextRow';
import { TRichTextContent } from '../../RichText';

export type TTableOfContentsAccum = {
  [key in string]: {
    text: string;
    id: string;
    subItems: { text: string; id: string }[];
    index?: number;
  };
};

const prepareTableOfContentsStructure = (
  items: TRichTextContent,
): TTableOfContentsAccum => {
  let currentParentId = '';
  return items.reduce((accum: TTableOfContentsAccum, currentItem) => {
    if ('type' in currentItem && typeof currentItem.type === 'string') {
      const preparedText =
        'children' in currentItem && Array.isArray(currentItem.children)
          ? buildStringFromRichTextRow(currentItem.children)
          : '';

      const currentItemId =
        'id' in currentItem &&
        typeof currentItem.id === 'string' &&
        currentItem.id !== ''
          ? currentItem.id
          : buildHashId(preparedText);

      if (currentItem.type === 'h2' && currentItem.id !== '') {
        currentParentId = currentItemId;
        return {
          ...accum,
          [currentParentId]: {
            text: preparedText,
            id: currentParentId,
            subItems: [],
          },
        };
      }
      if (['h3', 'h4', 'h5'].includes(currentItem.type)) {
        const text = preparedText;
        if (Array.isArray(accum[currentParentId]?.subItems)) {
          accum[currentParentId].subItems.push({
            text,
            id: currentItemId,
          });
        }
      }
    }

    return accum;
  }, {});
};

export default prepareTableOfContentsStructure;
