declare const window: {
  NEXT_VIDEO_CROP_ENABLED: string;
};

export function isVideoCropEnabled(): boolean {
  const normalizeValue = (value?: string): boolean => {
    return !!value && (+value === 1 || value?.toLowerCase() === 'true');
  };

  if (typeof window !== 'undefined') {
    return normalizeValue(window.NEXT_VIDEO_CROP_ENABLED);
  }
  return normalizeValue(process.env.NEXT_VIDEO_CROP_ENABLED);
}

export const getResponsiveVideoSrc = (
  src: string,
  width: number,
  quality = 100,
): string => {
  if (!isVideoCropEnabled()) {
    return src;
  }

  const parts = src.split('.');
  if (parts.length < 2) {
    return '';
  }
  const extension = parts.pop();

  return `${parts.join('.')}__${width}__${quality}.${extension}`;
};
