import {
  ContentSection,
  ESectionVariant,
  useSequentialAnimations,
} from '@front/shared/ds';
import { getGaId } from '@front/shared/utils';
import { Jurisdiction, Locale, Section } from '@shared/master-types';
import React, { useEffect, useState } from 'react';

import { useSectionAnimationContext } from './AnimationContext';
import { useActions } from '../../../features/actions/useActions.hook';
import { ErrorBoundary } from '../../blocks/ErrorBoundary';
import { SectionRow } from '../SectionRow';
import { SectionWrapper } from '../SectionWrapper';
import { useMedia } from 'react-use';
import { baseTailwindConfig } from '@front/shared';

type TBackgroundStyle = Omit<
  Section['backgroundStyle'],
  | 'background'
  | 'backgroundPosition'
  | 'containerVariant'
  | 'containerBackground'
> &
  Pick<
    React.ComponentProps<typeof ContentSection>,
    | 'background'
    | 'backgroundPosition'
    | 'containerVariant'
    | 'containerBackground'
  >;

export interface ILayoutParserProps {
  section: Section;
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
  sectionVariant?: ESectionVariant;
  customSectionRowRenderer?: (row: any, section?: Section) => React.ReactNode; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const SectionParser: React.FC<ILayoutParserProps> = props => {
  const {
    section,
    sectionVariant,
    locales,
    jurisdictionList,
    jurisdictionItem,
    customSectionRowRenderer,
  } = props;

  const {
    rows = [],
    name,
    title,
    richTitle,
    slug,
    description,
    bottomDescription,
    topDescription,
    isSubtitleTop,
    subtitle,
    actions,
    eyebrowsTop,
    eyebrowsBottom,
  } = section;

  const ctaActions = useActions(actions);

  const style = section.style as Omit<
    React.ComponentProps<typeof ContentSection>,
    | 'title'
    | 'children'
    | 'background'
    | 'containerVariant'
    | 'containerBackground'
  >;

  const backgroundStyle = section.backgroundStyle as TBackgroundStyle;

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const handleEmptyContent = (): void => {
    if (rows.length === 1) {
      setIsEmptyContent(true);
    }
  };

  const isDesktop = useMedia(
    `(min-width: ${baseTailwindConfig.screens.desktop})`,
    true,
  );

  const animationContext = useSectionAnimationContext();
  const isAnimated = Boolean(
    isDesktop && section.style?.animationThreshold && animationContext,
  );

  const { ref, transitionClasses } = useSequentialAnimations({
    count: 2,
    delay: 200,
    startClasses: 'opacity-0 translate-y-12',
    finishClasses: 'opacity-100',
    threshold: section.style?.animationThreshold, // whole section block threshold
    enabled: isAnimated,
  });

  useEffect(() => {
    if (isAnimated) {
      animationContext!.setSectionAnimationSequenceReady(
        transitionClasses[1] === 'opacity-100',
      );
    }
  }, [transitionClasses, animationContext, isAnimated]);

  if (isEmptyContent) {
    return null;
  }

  const finalSectionVariant =
    backgroundStyle?.variant &&
    backgroundStyle.variant !== ESectionVariant.Default
      ? (backgroundStyle.variant as ESectionVariant)
      : sectionVariant;

  return (
    <ErrorBoundary>
      <SectionWrapper
        {...style}
        {...backgroundStyle}
        title={title}
        richTitle={richTitle}
        id={slug}
        description={description}
        bottomDescription={bottomDescription}
        isSubtitleTop={isSubtitleTop}
        sectionVariant={finalSectionVariant}
        subtitle={subtitle}
        eyebrowsTop={eyebrowsTop}
        eyebrowsBottom={eyebrowsBottom}
        topDescription={topDescription}
        actions={ctaActions}
        dataGaId={getGaId(name || title, 'section')}
        animationRef={ref}
        animationClasses={
          isAnimated
            ? `${transitionClasses[0]} transition-all duration-400 ease-in`
            : ''
        }
      >
        {rows.map(row => (
          <SectionRow
            key={row.id}
            row={row}
            section={section}
            sectionVariant={finalSectionVariant}
            locales={locales}
            jurisdictionList={jurisdictionList}
            jurisdictionItem={jurisdictionItem}
            customSectionRowRenderer={customSectionRowRenderer}
            handleEmptyContent={handleEmptyContent}
          />
        ))}
      </SectionWrapper>
    </ErrorBoundary>
  );
};

export default SectionParser;
