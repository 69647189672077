import { TChildren } from '../components/atoms/RichText/types';

export default function buildStringFromRichTextRow(
  children: TChildren | undefined,
): string {
  const string = children
    ?.map(childrenPart => {
      if (childrenPart.text) {
        return childrenPart.text;
      }

      const subChildren = childrenPart?.children
        ? buildStringFromRichTextRow(childrenPart.children)
        : '';

      return subChildren;
    })
    .join('');
  return string || '';
}
