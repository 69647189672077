import { Media } from '@shared/master-types';

declare global {
  interface Window {
    CDN_BASE_URL: string;
  }
}

const hasValidProtocol = (urlString: string): boolean => {
  const protocolPattern = new RegExp('^(https?:\\/\\/)', 'i');
  return protocolPattern.test(urlString);
};

const getCDNHost = (): string | undefined => {
  if (typeof window !== 'undefined') {
    return window.CDN_BASE_URL;
  }
  return process.env.CDN_BASE_URL;
};

const getMediaSrc = (
  payload: string | Media | undefined,
): string | undefined => {
  if (!payload) {
    return;
  }

  const baseUrl = getCDNHost();

  let path = typeof payload === 'string' ? payload : payload.url;

  if (!path) {
    return;
  }

  if (hasValidProtocol(path)) {
    path = new URL(path).pathname;
  }

  return `${baseUrl}${path}`;
};

export default getMediaSrc;
