export enum EIconSize {
  XxxL = '3XL',
  XxL = '2XL',
  OneXl = '1XL',
  Xl = 'XL',
  L = 'L',
  M = 'M',
  S = 'S',
  Xs = 'XS',
}

export enum EIconBottomPadding {
  Yes = 'yes',
  No = 'no',
}
