import escapeHTML from 'escape-html';

export type TMultiLineTextProps = { rowText: string };

const MultiLineText: React.FC<TMultiLineTextProps> = props => {
  const { rowText } = props;

  return (
    <>
      {rowText.split(/\r?\n/g).map((line, index, lineList) => {
        if (!lineList.length) {
          return null;
        }

        if (lineList.length === 1) {
          return (
            <span
              key={index}
              dangerouslySetInnerHTML={{ __html: escapeHTML(line) }}
            />
          );
        }

        return (
          <span key={index}>
            <span dangerouslySetInnerHTML={{ __html: escapeHTML(line) }} />
            {index < lineList.length - 1 && <br />}
          </span>
        );
      })}
    </>
  );
};

export default MultiLineText;
