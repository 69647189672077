import { Article, toImageProps } from '@front/shared/ds';
import { BlogArticle } from '@shared/master-types';
import { TArticleTagsProps } from 'front/shared/ds/src/components/atoms/Article/Article';
import toLinkParam from 'front/shared/ds/src/utils/toLinkParam';
import { useRouter } from 'next/router';
import React from 'react';

export type TArticleConnectedProps = BlogArticle & {
  readingTimeMinute?: string;
};

const ArticleConnected: React.FC<TArticleConnectedProps> = props => {
  const { createdAt, publishedAt, cover, tags, ...restProps } = props;

  const router = useRouter();
  const linkParam = toLinkParam({
    locale: router.query.locale?.toString(),
    jurisdiction: router.query.jurisdiction?.toString(),
  });

  const tagsArray: TArticleTagsProps = [];
  if (tags) {
    for (const item of tags) {
      if (typeof item.value !== 'string') {
        tagsArray.push({
          href: `${linkParam && '/'}${linkParam}/${item.value.slug}`,
          id: item.value.id,
          children: item.value.name,
        });
      }
    }
  }

  return (
    <Article
      {...restProps}
      updatedAt={publishedAt}
      publishedAt={publishedAt || createdAt}
      tags={tagsArray}
      cover={toImageProps(cover, { useFill: true })}
    />
  );
};

export default ArticleConnected;
