import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import {
  EDefaultCardLayout,
  EDefaultCardBackground,
} from './DefaultCard.constants';
import { Card, ECardTextColor, ICardProps } from '../../atoms/Card';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';

export type TDefaultCardProps = ICardProps & {
  size?: EDefaultCardLayout;
  image?: ImageProps | null;
  swapImageSide?: boolean;
  background?: EDefaultCardBackground;
  wrapperClassName?: string;
};

const DefaultCard: React.FC<TDefaultCardProps> = props => {
  const {
    size = EDefaultCardLayout.DefaultCardWithBg,
    image = null,
    swapImageSide = false,
    background = EDefaultCardBackground.Light,
    wrapperClassName,
    ...rest
  } = props;

  let cardStyles = '';
  let cardTextColor: ECardTextColor | null = ECardTextColor.Dark;
  let cardBackground = 'bg-surface-100';
  if (background === EDefaultCardBackground.Dark) {
    cardTextColor = ECardTextColor.Light;
    cardBackground = 'bg-control-1000';
  } else if (background === EDefaultCardBackground.White) {
    cardBackground = 'bg-surface-50';
  } else if (background === EDefaultCardBackground.Brand) {
    cardBackground = 'bg-brand-500';
    cardTextColor = ECardTextColor.Light;
  } else if (background === EDefaultCardBackground.Theme5) {
    cardBackground = '';
    cardTextColor = null;
    cardStyles =
      'bg-surface-800 text-interface-50/80 prose-h3:text-interface-50';
  }

  return (
    <div className='h-full flex-1'>
      {size === EDefaultCardLayout.DefaultCard && (
        <div
          className={clsx(
            'flex flex-1 gap-5',
            swapImageSide ? 'flex-col-reverse' : 'flex-col',
            wrapperClassName,
          )}
        >
          {image && (
            <ImageWithPlaceholder
              {...image}
              className='h-44 w-auto rounded-[1.25rem] object-cover'
            />
          )}
          <div>
            <Card {...rest} />
          </div>
        </div>
      )}
      {size === EDefaultCardLayout.DefaultCardWithBg && (
        <div
          className={clsx(
            'h-full flex-1 rounded-3xl',
            cardStyles,
            cardBackground,
            swapImageSide ? 'flex flex-col-reverse justify-between' : '',
            wrapperClassName,
          )}
        >
          {image && (
            <ImageWithPlaceholder
              {...image}
              className='h-auto w-full rounded-3xl object-cover'
            />
          )}
          <div className='p-8'>
            <Card cardTextColor={cardTextColor} {...rest} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultCard;
