import React from 'react';

import type { Sidebar } from '@shared/master-types';

type TSidebarConnectedProps = {
  sidebar?: Sidebar;
};

export const SidebarConnected: React.FC<TSidebarConnectedProps> = props => {
  const { sidebar } = props;

  const content = sidebar?.rows?.map(row => {
    switch (row.blockType) {
      /*
            add sidebar blocks here, e.g. 
             case 'title':
              return <Title key={row.id} title={row.title} />;
             */
      default:
        return <></>;
    }
  });

  return <>{content}</>;
};
