export const enum EDefaultCardLayout {
  DefaultCard = 'DefaultCard',
  DefaultCardWithBg = 'DefaultCardWithBg',
}

export const enum EDefaultCardBackground {
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
  Brand = 'Brand',
  Theme1 = 'Theme1', // light bg
  Theme2 = 'Theme2', // white bg
  Theme3 = 'Theme3', // brand bg
  Theme4 = 'Theme4', // accent bg
  Theme5 = 'Theme5', // dark on black bg
  Theme6 = 'Theme6', // black bg
}
