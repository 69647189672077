import { format } from 'date-fns/format';
import { ImageProps } from 'next/image';
import React from 'react';

import { ReactComponent as IconClock } from '../../../../public/icons/icon-clock.svg';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { ETagSize, ETagTheme, ETagVariant, Tag } from '../Tag';

export type TEventProps = {
  title: string;
  publishedAt?: string;
  updatedAt?: string;
  readingTime?: number;
  cover?: ImageProps | null;
  isOpenInNewTab?: boolean;
  readingTimeMinute?: string;
  eventTagTitle?: string;
  eventTagPath?: string;
};

const Event: React.FC<TEventProps> = props => {
  const {
    cover,
    publishedAt,
    readingTime,
    updatedAt,
    title,
    readingTimeMinute,
    eventTagTitle,
    eventTagPath,
  } = props;

  return (
    <article itemScope itemType='http://schema.org/Event'>
      <h1
        itemProp='headline'
        className='text-5xl font-semibold leading-normal text-interface-1000 lg:text-7xl lg:leading-snug'
      >
        {title}
      </h1>
      <div className='mt-4 grid w-full grid-cols-[repeat(2,_max-content)] gap-x-5 gap-y-4 lg:mt-5 lg:grid-cols-[repeat(3,_max-content)]'>
        {eventTagTitle && (
          <div className='col-start-1 col-end-3 flex gap-2 lg:col-end-2'>
            <Tag
              variant={ETagVariant.Contained}
              theme={ETagTheme.Secondary}
              size={ETagSize.Small}
              href={eventTagPath}
              itemProp='about'
              itemScope
              itemType='http://schema.org/Thing'
            >
              <span itemProp='name'>{eventTagTitle}</span>
            </Tag>
          </div>
        )}
        <div className='flex items-center gap-3 empty:hidden'>
          {publishedAt && (
            <time
              dateTime={publishedAt}
              className='text-xs font-medium leading-relaxed text-interface-800'
              itemProp='datePublished'
            >
              {format(new Date(publishedAt), 'dd.MM.yyyy')}
            </time>
          )}
          {updatedAt && (
            <time
              dateTime={updatedAt}
              className='inline-block text-xs font-medium leading-relaxed text-interface-500'
              itemProp='dateModified'
            >
              Updated {format(new Date(updatedAt), 'dd.MM.yyyy')}
            </time>
          )}
        </div>
        <div className='col-start-2 col-end-3 flex gap-3 text-xs font-medium leading-relaxed text-interface-500 lg:col-start-auto lg:col-end-auto'>
          {readingTime && (
            <div className='flex items-center gap-1'>
              <IconClock className='size-3' />
              {readingTime}
              {readingTimeMinute || 'm'}
            </div>
          )}
          {/*<div className='flex items-center gap-1'>*/}
          {/*  <IconEyes className='h-3 w-3' />*/}
          {/*  {451}*/}
          {/*</div>*/}
        </div>
      </div>
      {cover ? (
        <div className='relative mt-5 h-[12.063rem] overflow-hidden rounded-3xl lg:mt-6 xl:h-72 2xl:h-[21.813rem]'>
          <ImageWithPlaceholder
            {...cover}
            itemProp='image'
            className='object-cover'
          />
        </div>
      ) : null}
    </article>
  );
};

export default Event;
