export const enum EButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
  Float = 'Float',
}

export const enum EButtonTheme {
  Primary = 'Primary',
  Secondary = 'Secondary',
  PrimaryOnColour = 'Primary-on-colour',
  SecondaryOnColour = 'Secondary-on-colour',
  Tertiary = 'Tertiary',
  Control = 'Control',
}

export const enum EButtonVariant {
  Contained = 'Contained',
  Outlined = 'Outlined',
  Text = 'Text',
  Image = 'Image',
  OutlineRounded = 'Outline rounded',
  ContainedRounded = 'Contained rounded',
}

export const enum EButtonIconPosition {
  Left = 'left',
  Right = 'right',
}
