export const ForexBrokerTurnkeyConstants = [
  { colorName: '--color-brand-250', color: '202 250 201' },
  { colorName: '--color-brand-300', color: '58 186 79' },
  { colorName: '--color-brand-500', color: '0 147 69' },
  { colorName: '--color-brand-550', color: '1 111 53' },
  { colorName: '--color-accent-500', color: '229 248 237' },
  { colorName: '--color-accent-550', color: '237 247 255' },
  { colorName: '--color-accent-800', color: '255 255 255' },
  { colorName: '--color-accent-850', color: '230 230 230' },
];
