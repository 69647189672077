import { ImageProps } from 'next/image';
import Link from 'next/link';
import { ComponentProps } from 'react';

import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../components/atoms/Button';

export enum EActionType {
  ButtonType = 'button',
  LinkType = 'link',
  LinksGroupType = 'links-group',
  Text = 'text',
}

export type TAction =
  | {
      type: EActionType.ButtonType;
      text?: string;
      props: ComponentProps<typeof Button> & { description?: string };
      dataGaId?: string;
    }
  | TActionLink
  | {
      type: EActionType.LinksGroupType;
      label?: string;
      actions?: TAction[];
      dataGaId?: string;
    };

export type TActionWithText =
  | TAction
  | { type: EActionType.Text; text: string };

export type TActionLink = {
  type: EActionType.LinkType;
  text?: string;
  props: ComponentProps<typeof Link> & {
    description?: string;
    variant?: EButtonVariant;
    size?: EButtonSize;
    theme?: EButtonTheme;
    trailingIcon?: ImageProps;
    disabled?: boolean;
  };
  dataGaId?: string;
};
