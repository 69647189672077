import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  EFormSlotBackground,
  FormSlot,
  IconCheck,
  RichText,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React, { useEffect, useState } from 'react';

import { useFormSubmission } from '../../../features/forms/useFormSubmission.hook';
import {
  EFormInputParserVariant,
  FormInputParser,
} from '../../sections/FormInputParser';

export type TFormSlotConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'form-slot' }
>;

const FormSlotConnected: React.FC<TFormSlotConnectedProps> = props => {
  const { form, title, description, background } = props;
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const { register, formState, control, handleSubmit } = useFormSubmission({
    form,
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setShowConfirmationMessage(true);

      const timeout = setTimeout(() => {
        setShowConfirmationMessage(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => ({});
  }, [formState.isSubmitSuccessful]);

  return (
    <FormSlot
      title={title}
      background={EFormSlotBackground[background]}
      description={<RichText content={description} />}
    >
      {form && typeof form !== 'string' && (
        <>
          <form className='grid gap-3 lg:grid-cols-2' onSubmit={handleSubmit}>
            {form.fields?.map(field => (
              <FormInputParser
                key={field.name}
                field={field}
                control={control}
                register={register}
                error={formState.errors[field.name]?.message?.toString()}
                variant={
                  background === EFormSlotBackground.White
                    ? EFormInputParserVariant.Primary
                    : EFormInputParserVariant.Secondary
                }
              />
            ))}
            <div className='grid min-h-[3.75rem] lg:col-start-1 lg:col-end-3'>
              <Button
                theme={EButtonTheme.Primary}
                size={EButtonSize.Large}
                variant={EButtonVariant.Contained}
                type='submit'
                disabled={!formState.isValid || formState.isSubmitting}
              >
                {form.submitButtonLabel || 'Submit'}
              </Button>
            </div>
            <div className='prose-p:text-interface-500 prose-a:text-brand-500 text-center text-xs leading-loose lg:text-left'>
              <RichText content={form.description} />
            </div>
          </form>
          {form.confirmationType === 'message' && showConfirmationMessage && (
            <div className='fixed inset-x-0 bottom-10 z-10 mx-auto flex max-w-md animate-translate-up items-center gap-4 rounded-2xl bg-surface-50/30 px-6 py-5 text-sm leading-loose backdrop-blur-md'>
              <IconCheck className='aspect-square size-5 text-success' />
              <RichText content={form.confirmationMessage} />
            </div>
          )}
        </>
      )}
    </FormSlot>
  );
};

export default FormSlotConnected;
