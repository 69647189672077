import { getMediaSrc } from '@front/shared/ds';
import { Locale, Media, Meta as IMeta } from '@shared/master-types';
import Script from 'next/script';
import React from 'react';

import type { Meta as PayloadMeta } from '@payloadcms/plugin-seo/dist/types';

type TApplicationSchemaProps = Omit<PayloadMeta, 'image'> & {
  image?: string | Media;
  isPageCanonical?: boolean;
  url?: string;
  appHost?: string;
  locales?: Locale[];
  linkParam?: string;
} & Omit<IMeta, 'id'>;

export const ApplicationSchema: React.FC<TApplicationSchemaProps> = ({
  title = '',
  description = '',
  image,
  createdAt,
  updatedAt,
  url,
  streetAddress,
  addressLocality,
  addressRegion,
  postalCode,
  addressCountry,
  appHost,
  sameAsLinks = [],
  name,
  email,
  telephone,
  logo,
  linkParam,
}) => {
  const hasAddress =
    streetAddress &&
    addressLocality &&
    addressRegion &&
    postalCode &&
    addressCountry;
  const hostWithLocale = `${appHost}${linkParam && '/'}${linkParam}`;
  const address = hasAddress
    ? {
        address: {
          '@type': 'PostalAddress',
          streetAddress: streetAddress,
          addressLocality: addressLocality,
          addressRegion: addressRegion,
          postalCode: postalCode,
          addressCountry: addressCountry,
        },
      }
    : {};
  const sameAsLinksPrepared = sameAsLinks.map(({ url: link }) => link);
  const schemaData = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        headline: title,
        description: description,
        '@type': 'Article',
        datePublished: createdAt,
        dateModified: updatedAt,
        ...(image && {
          image: {
            '@type': 'ImageObject',
            url: getMediaSrc(image),
          },
        }),
        name: title,
        mainEntityOfPage: {
          '@id': `${hostWithLocale}${url}#webpage`,
        },
      },
      ...(hasAddress
        ? [
            {
              '@type': 'Place',
              '@id': `${hostWithLocale}/#place`,
              ...address,
            },
          ]
        : []),
      {
        '@type': 'Organization',
        '@id': `${hostWithLocale}/#organization`,
        name: name,
        url: `${hostWithLocale}/`,
        ...(sameAsLinksPrepared.length && { sameAs: sameAsLinksPrepared }),
        email: email,
        ...address,
        logo: {
          '@type': 'ImageObject',
          '@id': `${hostWithLocale}/#logo`,
          url: getMediaSrc(logo),
          contentUrl: getMediaSrc(logo),
          caption: name,
          // inLanguage: 'en-US',
          ...(typeof logo !== 'string' && logo?.width && { width: logo.width }),
          ...(typeof logo !== 'string' &&
            logo?.height && { height: logo.height }),
        },
        ...(telephone && {
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: telephone,
              contactType: 'customer support',
            },
          ],
        }),
        location: {
          '@id': `${hostWithLocale}/#place`,
        },
      },
      {
        '@type': 'WebSite',
        '@id': `${hostWithLocale}/#website`,
        url: `${hostWithLocale}/`,
        name: name,
        publisher: {
          '@id': `${hostWithLocale}/#organization`,
        },
        // inLanguage: 'en-US',
      },
      ...(image
        ? [
            {
              '@type': 'ImageObject',
              '@id': getMediaSrc(image),
              url: getMediaSrc(image),
              ...(typeof image !== 'string' &&
                image?.width && { width: image.width }),
              ...(typeof image !== 'string' &&
                image?.height && { height: image.height }),
              ...(typeof image !== 'string' &&
                image?.alt && { caption: image.alt }),
            },
          ]
        : []),
      {
        '@type': 'WebPage',
        '@id': `${hostWithLocale}${url}#webpage`,
        url: `${hostWithLocale}${url}`,
        name: title,
        datePublished: createdAt,
        dateModified: updatedAt,
        about: {
          '@id': `${hostWithLocale}/#organization`,
        },
        isPartOf: {
          '@id': `${hostWithLocale}/#website`,
        },
        ...(image && {
          primaryImageOfPage: {
            '@id': getMediaSrc(image),
          },
        }),
        // inLanguage: 'en-US',
      },
    ],
  };

  return (
    <Script
      type='application/ld+json'
      id='applicationSchema'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
    />
  );
};
