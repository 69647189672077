import { Form } from '@shared/master-types';

import { useFormSubmission } from '../../../../features/forms/useFormSubmission.hook';

export const useModalForm = (
  form: Form,
): Pick<
  ReturnType<typeof useFormSubmission>,
  | 'register'
  | 'handleSubmit'
  | 'trigger'
  | 'formState'
  | 'control'
  | 'clearErrors'
  | 'watch'
> => {
  const {
    register,
    formState,
    control,
    clearErrors,
    handleSubmit,
    trigger,
    watch,
  } = useFormSubmission({
    form,
  });

  return {
    register,
    watch,
    handleSubmit,
    trigger,
    clearErrors,
    formState,
    control,
  };
};
