import {
  EActionType,
  RecentArticles,
  TAction,
  toLinkProps,
  buildArticlesList,
} from '@front/shared/ds';
import { addJurisdictionSupport } from '@front/shared/utils';
import {
  BlogArticle,
  Jurisdiction,
  Locale,
  Section,
} from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useGetBlogArticlesQuery } from '../../../features/blogArticles/getBlogArticles.query';
import { useGetBlogTagsQuery } from '../../../features/blogTags/getBlogTags.query';

export interface IBlogArticleExtended extends BlogArticle {
  currentLocale: string;
  jurisdictionList?: Jurisdiction[];
  locales?: Locale[];
  slug?: string;
}

export type TRecentArticlesConnectedProps = Extract<
  Required<Section>['rows'][number],
  {
    blockType: 'recent-articles';
  }
> & {
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
};
const RecentArticleConnected: React.FC<TRecentArticlesConnectedProps> = ({
  selectedArticles,
  recentLinks,
  locales = [],
  jurisdictionList,
  jurisdictionItem,
}) => {
  const router = useRouter();

  const currentLocale = String(router.query.locale);

  const articlesQuery = useMemo(() => {
    const query: Parameters<typeof useGetBlogTagsQuery>[0] = {
      locale: currentLocale,
      draft: false,
      limit: 4,
    };

    return query;
  }, [currentLocale]);

  const recentLinksWithActions = addJurisdictionSupport({
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
    actionList: recentLinks?.map(
      (link): TAction => ({
        type: EActionType.LinkType,
        props: {
          children: link?.text,
          ...toLinkProps(String(router.query.locale), link),
        },
      }),
    ),
  });

  const { data: articleDocs } = useGetBlogArticlesQuery(articlesQuery);

  const docsArray = articleDocs?.docs || [];

  return (
    <RecentArticles
      recentLinks={recentLinksWithActions}
      selectedArticles={
        selectedArticles && selectedArticles.length > 0
          ? buildArticlesList(
              selectedArticles as (string | IBlogArticleExtended)[],
              currentLocale,
              locales,
              jurisdictionList,
              jurisdictionItem?.slug,
            )
          : buildArticlesList(
              docsArray as (string | IBlogArticleExtended)[],
              currentLocale,
              locales,
              jurisdictionList,
              jurisdictionItem?.slug,
            )
      }
    />
  );
};

export default RecentArticleConnected;
