import { BlogEvent, Jurisdiction, Locale } from '@shared/master-types';

import { IBlogEventExtended } from '../components/rows/EventCardRow/EventCardRow';

export default function buildEventsList(
  docsArray: BlogEvent[],
  currentLocale: string,
  locales?: Locale[],
  jurisdictionList?: Jurisdiction[],
  jurisdiction?: string,
): Array<IBlogEventExtended> {
  if (!docsArray) {
    console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
    console.warn('%cNo articles provided');
    return [];
  }

  return docsArray.map((event: BlogEvent) => {
    const {
      id,
      cover,
      title,
      url,
      createdAt,
      location,
      authors,
      registerLink,
      readingTime,
      parent,
      updatedAt,
      excerpt,
      rating,
      proofreaders,
      startDate,
      endDate,
    } = event;

    return {
      id,
      cover,
      title,
      url,
      createdAt,
      excerpt,
      location,
      authors,
      readingTime,
      proofreaders,
      parent,
      updatedAt,
      rating,
      registerLink,
      startDate,
      endDate,
      currentLocale,
      jurisdictionList,
      locales,
      slug: jurisdiction,
    };
  });
}
