import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React, { useRef } from 'react';

import { populateUniqueTitleIds } from '../../../utils';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { RichText } from '../../atoms/RichText';
import {
  prepareTableOfContentsStructure,
  TableOfContents,
} from '../../atoms/TableOfContents';
import { Container } from '../../sections/Container';

import type { TChildren } from '../../atoms/RichText/types';

export type TFinanceTableProps = {
  richText: TChildren;
  sidebarTitle?: string;
  id?: string;
  blockImage?: ImageProps | null;
  isAnchors: boolean;
  isLegalTemplate: boolean;
};

const FinanceTable: React.FC<TFinanceTableProps> = props => {
  const {
    richText,
    sidebarTitle,
    id,
    isAnchors = true,
    isLegalTemplate,
    blockImage,
  } = props;
  const richTextWithIds = populateUniqueTitleIds(richText);
  const tableOfContentsStructure =
    richText && isAnchors
      ? prepareTableOfContentsStructure(richTextWithIds)
      : null;

  const contentBlockRef = useRef<HTMLDivElement | null>(null);
  const tocBlockRef = useRef<HTMLDivElement | null>(null);

  const h2Styles = clsx(
    isLegalTemplate
      ? 'prose-h2:pb-1 prose-h2:pt-5 prose-h2:text-2xl prose-h2:leading-relaxed'
      : 'prose-h2:pb-4 prose-h2:pt-10 prose-h2:text-5xl prose-h2:leading-normal',
  );

  const pStyles =
    'prose-p:text-interface-800 prose-p:py-2 prose-p:text-base prose-p:leading-extra-loose';

  const listStyles = clsx(isLegalTemplate ? 'prose-ol:py-4 prose-ul:py-4' : '');

  const contentBlockClasses = clsx(
    h2Styles,
    'prose-a:text-brand-500 prose-a:transition ',
    'prose-h1:break-normal prose-h1:pb-4 prose-h1:pt-10 prose-h1:text-5xl prose-h1:font-semibold prose-h1:leading-normal prose-h1:text-interface-1000 first:prose-h1:pt-0 2xl:prose-h1:text-7xl 2xl:prose-h1:leading-snug',
    'prose-h2:break-normal prose-h2:font-semibold prose-h2:text-interface-1000 first:prose-h2:pt-0',
    'prose-h3:break-normal prose-h3:pb-1 prose-h3:pt-5 prose-h3:text-base prose-h3:font-semibold prose-h3:leading-normal prose-h3:text-interface-1000',
    'prose-h4:break-normal prose-h4:pb-1 prose-h4:pt-5 prose-h4:text-base prose-h4:font-semibold prose-h4:leading-normal prose-h4:text-interface-1000',
    pStyles,
    'prose-strong:font-semibold',
    listStyles,
    'prose-ul:grid prose-ul:gap-2 prose-ul:ps-[1.5rem] before:prose-li:size-[0.45rem]',
    'prose-ol:grid prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-4',
    'prose-li:text-base prose-li:text-interface-800',
    'prose-img:h-auto prose-img:w-full',
    '2xl:max-w-[38.75rem]',
  );

  return (
    <>
      <Container isPadding={false}>
        {blockImage && (
          <div className='relative mb-4 h-40 w-full 2xl:mb-0'>
            <ImageWithPlaceholder
              {...blockImage}
              className={'size-full object-cover object-center'}
            />
          </div>
        )}
      </Container>

      <Container>
        <div className='flex flex-row gap-16'>
          {tableOfContentsStructure && (
            <div className='hidden w-full min-w-[15.75rem] max-w-[15.75rem] 2xl:flex 2xl:flex-col'>
              <div
                ref={tocBlockRef}
                className='sticky top-32 w-full max-w-[15.75rem]'
              >
                <TableOfContents
                  tableOfContentsStructure={tableOfContentsStructure}
                  title={sidebarTitle}
                />
              </div>
            </div>
          )}

          <div ref={contentBlockRef} className={contentBlockClasses}>
            <RichText key={id} content={richTextWithIds} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default FinanceTable;
