import {
  B2copyTradingPlatformConstants,
  B2CoreConstants,
  LiquidityConstants,
  MainConstants,
  PropTradingConstants,
  CTraderConstants,
  ForexLiquidityConstants,
  B2TraderConstants,
  CTraderServicesConstants,
  ForexBrokerTurnkeyConstants,
  PrimeXMServicesConstants,
} from './ThemeColors';

export enum EThemeTemplates {
  MainPage = 'mainPage',
  Liquidity = 'liquidity',
  PropTrading = 'propTrading',
  B2Core = 'b2core',
  B2copyTradingPlatform = 'b2copyTradingPlatform',
  CurrencyTrader = 'cTrader',
  ForexLiquidity = 'forexLiquidity',
  B2TraderConstants = 'b2trader',
  CTraderServicesConstants = 'cTraderServices',
  ForexBrokerTurnkey = 'forexBrokerTurnkey',
  PrimeXMServices = 'primeXmServices',
}

export const themeColorsMap: Record<
  EThemeTemplates,
  { colorName: string; color: string }[]
> = {
  [EThemeTemplates.MainPage]: MainConstants,
  [EThemeTemplates.Liquidity]: LiquidityConstants,
  [EThemeTemplates.PropTrading]: PropTradingConstants,
  [EThemeTemplates.B2Core]: B2CoreConstants,
  [EThemeTemplates.B2copyTradingPlatform]: B2copyTradingPlatformConstants,
  [EThemeTemplates.CurrencyTrader]: CTraderConstants,
  [EThemeTemplates.ForexLiquidity]: ForexLiquidityConstants,
  [EThemeTemplates.B2TraderConstants]: B2TraderConstants,
  [EThemeTemplates.CTraderServicesConstants]: CTraderServicesConstants,
  [EThemeTemplates.ForexBrokerTurnkey]: ForexBrokerTurnkeyConstants,
  [EThemeTemplates.PrimeXMServices]: PrimeXMServicesConstants,
};
